import { useCallback, useEffect, useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "../IpStatistic.scss";
import Loading from "../../loading/Loading";
import Pagination from "../../common/Pagination";
import PatentAPI from "../../../API/patent";
import DistributionPop from "../DistributionPop";

const DistributionStatus = ({ loading = true, defaultData = [] }) => {
  const compName =
    sessionStorage.getItem("company_name")?.replace(/\s+/g, "") || "";
  const companyCode = sessionStorage.getItem("company_code") || "";
  const companyIdx = sessionStorage.getItem("company_idx") || "";
  const labels = ["AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC", "C"];
  const years = [
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
  ];
  const countries = ["한국", "일본", "중국", "타이완", "미국", "유럽"];
  const [distribution, setDistribution] = useState([]); // 키워드
  const [keywordArr, setKeywordArr] = useState([]);
  const [keywordParam, setKeywordParam] = useState(""); // 키워드 파라미터
  const [selectedKeywords, setSelectedKeywords] = useState({}); // 선택된 키워드 상태
  const [category, setCategory] = useState("product"); // 카테고리 파라미터
  const [keywordResult, setKeywordResult] = useState([]);
  const [countArr, setCountArr] = useState([]);
  const [total, setTotal] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [count, setCount] = useState(30);
  const [last, setLast] = useState(1);
  const [datas, setDatas] = useState({});
  const [smGradeCnt, setSmGradeCnt] = useState([]); // 스마트5(종합)
  const [smGradeRCnt, setSmGradeRCnt] = useState([]); // 스마트5(권리성)
  const [smGradeTCnt, setSmGradeTCnt] = useState([]); // 스마트5(기술)
  const [smGradeUCnt, setSmGradeUCnt] = useState([]); // 스마트5(활용성)
  const [patentAnnualApplyCnt, setPatentAnnualApplyCnt] = useState([]); // 연도별 특허 현황 데이터(출원)
  const [patentAnnualRegisterCnt, setPatentAnnualRegisterCnt] = useState([]); // 연도별 특허 현황 데이터(등록)
  const [nationCnt, setNationCnt] = useState([]); // 국가별 특허 보유 데이터
  const [inventor, setInventor] = useState([]);
  const borderColors = ["#f2c80f", "#374649", "#01b8aa", "#fe615e"];
  const [schKeyword, setSchKeyword] = useState("");
  const [distributionChart, setDistributionChart] = useState({
    labels: years,
    datasets: [],
  });
  const [fieldData, setFieldData] = useState({
    it: [40, 25, 10, 17],
    bt: [30, 20, 6, 20],
    et: [20, 40, 14, 9],
    nt: [10, 28, 20, 12],
  });
  const [isLoding, setIsLoading] = useState(false);
  const [isOpenPop, setIsOpenPop] = useState(false);
  const [isOpenPopReset, setIsOpenPopReset] = useState(false);
  const [callback, setCallback] = useState(null);
  const [patentInfo, setPatentInfo] = useState({});
  const [patentPage, setPatentPage] = useState(1);
  const [staticParams, setStaticParams] = useState({
    strValue: "",
    company_idx: companyIdx,
    flag: category,
    company_code: companyCode,
    year: "",
    subFlag: "",
    page: 1,
    count: 10,
  });
  const isFirstRender = useRef(true);
  const lineRef = useRef();
  const smartRef = useRef();
  const yearRef = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [viewData, setViewData] = useState({
    nation_string: "",
    apply_number: "",
    document_number: "",
  });

  const closePatentPop = useCallback(() => {
    setIsOpenPopReset(true);
    setPatentPage(1); // 페이지를 1로 초기화합니다.
    setIsOpenPop(false);
  }, []);

  // start SMART5 분포 차트
  const m_data = {
    labels: labels,
    datasets: [
      {
        data: smGradeCnt,
        label: "종합",
        backgroundColor: "#eee",
        hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
      {
        data: smGradeRCnt,
        label: "권리성",
        backgroundColor: "#eee",
        hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
      {
        data: smGradeTCnt,
        label: "기술",
        backgroundColor: "#eee",
        hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
      {
        data: smGradeUCnt,
        label: "활용성",
        backgroundColor: "#eee",
        hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
    ],
  };
  const m_annualFee_options = {
    legend: {
      display: false,
    },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      xAxes: [
        {
          // ticks: {
          //   display: false,
          //   beginAtZero: true,
          //   max: 100,
          //   stepSize: 100 / 4,
          //   maxTicksLimit: 5,
          // },
          gridLines: {
            display: true,
            borderDash: [4],
            color: "#d8d8d8",
            zeroLineColor: "#aaaaaa",
            zeroLineWidth: 2,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#959595",
            fontSize: 10,
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end SMART5 분포 차트

  // start 연도별 특허 현황 차트
  const yearData = {
    labels: years,
    datasets: [
      {
        data: patentAnnualApplyCnt,
        label: "출원",
        backgroundColor: "#ffab82",
        //hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
      {
        data: patentAnnualRegisterCnt,
        label: "등록",
        backgroundColor: "#ffd859",
        //hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
    ],
  };
  const yearOptions = {
    // legend: {
    //   display: false,
    // },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            fontColor: "#959595",
            fontSize: 10,
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end 연도별 특허 현황 차트

  // start 국가별 특허 보유 현황 차트
  const countriesData = {
    labels: countries,
    datasets: [
      {
        data: nationCnt,
        //label: "출원",
        backgroundColor: "#0078f1",
        //hoverBackgroundColor: "#0078f1",
        borderWidth: 0,
      },
    ],
  };
  const countriesOptions = {
    legend: {
      display: false,
    },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            fontColor: "#959595",
            fontSize: 10,
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end 국가별 특허 보유 현황 차트

  // start 분야별 특허 보유 현황 차트
  const fieldChartData = {
    labels: years,
    datasets: [
      {
        data: fieldData.it,
        label: "IT",
        backgroundColor: "transparent",
        borderColor: "#f2c80f",
        borderWidth: 0,
      },
      {
        data: fieldData.bt,
        label: "BT",
        backgroundColor: "transparent",
        borderColor: "#374649",
        borderWidth: 0,
      },
      {
        data: fieldData.et,
        label: "ET",
        backgroundColor: "transparent",
        borderColor: "#01b8aa",
        borderWidth: 0,
      },
      {
        data: fieldData.nt,
        label: "NT",
        backgroundColor: "transparent",
        borderColor: "#fe615e",
        borderWidth: 0,
      },
    ],
  };
  const fieldChartOptions = {
    legend: {
      display: false,
    },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: false,
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            fontColor: "#959595",
            fontSize: 10,
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end 분야별 특허 보유 현황 차트

  // start 분포 현황 차트
  const distributionChartData = {
    labels: keywordArr,
    datasets: [
      {
        data: countArr,
        label: "분포 현황",
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(101, 143, 241, 0.2)",
          "rgba(255, 99, 182, 0.2)",
          "rgba(120, 200, 80, 0.2)",
          "rgba(249, 168, 37, 0.2)",
        ],
        borderWidth: 0,
      },
    ],
  };
  const distributionChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   angleLines: {
    //     lineWidth: 0,
    //   },
    // },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end 분포 현황 차트

  // start 분포 현황 차트
  const disChartData = {
    labels: years,
    datasets: [
      {
        data: fieldData.it,
        label: "IT",
        backgroundColor: "transparent",
        borderColor: "#f2c80f",
        borderWidth: 0,
      },
      {
        data: fieldData.bt,
        label: "BT",
        backgroundColor: "transparent",
        borderColor: "#374649",
        borderWidth: 0,
      },
      {
        data: fieldData.et,
        label: "ET",
        backgroundColor: "transparent",
        borderColor: "#01b8aa",
        borderWidth: 0,
      },
      {
        data: fieldData.nt,
        label: "NT",
        backgroundColor: "transparent",
        borderColor: "#fe615e",
        borderWidth: 0,
      },
    ],
  };
  const disChartOptions = {
    legend: {
      display: false,
    },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: false,
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            fontColor: "#959595",
            fontSize: 10,
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  // end 분포 현황 차트

  useEffect(() => {
    //setKeywordParam("");
    //setSelectedKeywords({});
    getData();
  }, [category, curPage]);

  useEffect(() => {
    //console.log("keywordParam:", keywordParam);
    getDataBySearch();
  }, [keywordParam]);

  useEffect(() => {
    if (distribution.length > 0) {
      const keywords = distribution.map((item) => getCategoryValue(item));
      const counts = distribution.map((item) => item.count);
      setKeywordArr(keywords);
      setCountArr(counts);
    } else {
      setKeywordArr([]);
      setCountArr([]);
    }
  }, [distribution]);

  useEffect(() => {
    if (!compName) {
      alert("데이터가 없습니다.");
    }
  }, [compName]);

  useEffect(() => {
    if (callback) {
      callback(staticParams);
      setCallback(null); // callback이 한번만 실행되도록 초기화
    }
  }, [staticParams, callback]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (isOpenPopReset) {
      setIsOpenPopReset(false);
    } else {
      const updatedParams = { ...staticParams, page: patentPage };
      setStaticParams(updatedParams);
      getPatentList(updatedParams);
    }
  }, [patentPage]);

  useEffect(() => {
    setStaticParams((prevParams) => ({
      ...prevParams,
      flag: category,
    }));
  }, [category]);

  useEffect(() => {
    //console.log("defaultData:", defaultData);

    if (defaultData.data?.length > 0) {
      setDistributionChart({
        labels:
          defaultData.data?.length > 0
            ? defaultData.data?.map((item) => item.year)
            : years,
        datasets: [
          {
            label: "전체 특허 수",
            data:
              defaultData.data?.length > 0
                ? defaultData.data?.map((item) => item.cnt)
                : [],
            backgroundColor: "transparent",
            borderColor: borderColors[0],
            borderWidth: 2,
          },
        ],
      });
    }
  }, [defaultData]);

  if (!compName) {
    return null;
  }

  const updateStrValueAndYear = (newStrValue, newYear, cb) => {
    setStaticParams((prevParams) => ({
      ...prevParams,
      strValue: newStrValue,
      year: newYear,
    }));
    setCallback(() => cb);
  };

  const getData = (num) => {
    setIsLoading(true);
    let params = {
      company_name: compName,
      category: category,
      page: curPage,
      count: count,
      name: schKeyword,
    };
    PatentAPI.getData(params)
      .then(function (res) {
        //console.log("res:", res);
        if (res.data?.success) {
          setTotal(res.data.data.patentDistributionCnt);
          setDatas(res.data.data);
          setSmGradeCnt(
            sortObj(
              res.data?.data?.patentSmart5GradeDistribution?.sm_grade_cnt || {},
              labels
            )
          );
          setSmGradeRCnt(
            sortObj(
              res.data?.data?.patentSmart5GradeDistribution?.sm_grade_r_cnt ||
                {},
              labels
            )
          );
          setSmGradeTCnt(
            sortObj(
              res.data?.data?.patentSmart5GradeDistribution?.sm_grade_t_cnt ||
                {},
              labels
            )
          );
          setSmGradeUCnt(
            sortObj(
              res.data?.data?.patentSmart5GradeDistribution?.sm_grade_u_cnt ||
                {},
              labels
            )
          );
          setPatentAnnualApplyCnt(
            sortObj(res.data?.data?.patentAnnualApplyCnt || {}, years)
          );
          setPatentAnnualRegisterCnt(
            sortObj(res.data?.data?.patentAnnualRegisterCnt || {}, years)
          );
          setNationCnt(sortObj(res.data?.data?.nationCnt || {}, countries));
          setDistribution(res.data?.data?.patentDistribution || []);
          setKeywordResult(res.data?.data?.patentDistribution || []);
          setInventor(res.data?.data?.inventorInfo || []);
          setLast(res.data.last);
          setCurPage(res.data.page);
        }

        setIsLoading(false);
      })
      .catch(function (err) {
        // 에러 핸들링
        console.error("err:", err);
        setIsLoading(false);
      });
  };

  const getDataBySearch = () => {
    setIsLoading(true);
    let params = {
      company_name: compName,
      name: keywordParam,
      category: category,
    };
    if (keywordParam === "") {
      getData(curPage - 1);
      setDistributionChart({
        labels:
          defaultData.data?.length > 0
            ? defaultData.data?.map((item) => item.year)
            : years,
        datasets: [
          {
            label: "전체 특허 수",
            data:
              defaultData.data?.length > 0
                ? defaultData.data?.map((item) => item.cnt)
                : [],
            backgroundColor: "transparent",
            borderColor: borderColors[0],
            borderWidth: 2,
          },
        ],
      });
    } else {
      PatentAPI.getSelectedData(params)
        .then(function (res) {
          //console.log("검색어 res:", res);
          setKeywordResult(res?.data?.data?.selectedKeyword || []);
          const newDistributionChart =
            res.data.data.selectedKeyword.length > 0
              ? {
                  labels: years,
                  datasets: res.data.data.selectedKeyword.map(
                    (entry, index) => ({
                      data: years.map((year) => entry[year] || 0),
                      label: getCategoryValue(entry),
                      backgroundColor: "transparent",
                      borderColor: borderColors[index % borderColors.length],
                      borderWidth: 2,
                    })
                  ),
                }
              : {
                  labels:
                    defaultData.data?.length > 0
                      ? defaultData.data?.map((item) => item.year)
                      : years,
                  datasets: [
                    {
                      label: "전체 특허 수",
                      data:
                        defaultData.data?.length > 0
                          ? defaultData.data?.map((item) => item.cnt)
                          : [],
                      backgroundColor: "transparent",
                      borderColor: borderColors[0],
                      borderWidth: 2,
                    },
                  ],
                };

          setDistributionChart(newDistributionChart); // 차트 상태 업데이트
          setIsLoading(false);
        })
        .catch(function (err) {
          console.error("err:", err);
          setIsLoading(false);
        });
    }
  };

  const getKeywords = () => {
    setIsLoading(true);
    setCurPage(1);
    let params = {
      company_name: compName,
      name: schKeyword,
      category: category,
      page: 1,
      count: count,
    };
    PatentAPI.getData(params)
      .then(function (res) {
        //console.log("키워드 검색 res:", res);
        if (res.data?.success) {
          setDistribution(res.data?.data?.patentDistribution || []);
          setKeywordResult(res.data?.data?.patentDistribution || []);
          setLast(res.data?.last || 1);
          //총개수 세팅 필요
        }
        //setSchKeyword("");
        setIsLoading(false);
      })
      .catch(function (err) {
        console.error("err:", err);
        setIsLoading(false);
      });
  };

  const getPatentList = (params) => {
    setIsLoading(true);
    //console.log("params:", params);
    PatentAPI.getPatentStatic(params)
      .then((res) => {
        //console.log("getPatentStatic:", res);
        if (res.status === 200) {
          setPatentInfo(res.data || {});
          //setIsOpenPop(true);
          setIsLoading(false);
        }
      })
      .catch(function (err) {
        console.error("err:", err);
        setIsLoading(false);
      });
  };

  const sortObj = (obj, order) => {
    return order.map((key) => obj[key]);
  };

  const makeKeywordString = (keyword) => {
    const cleanedKeyword = keyword.replace(/\s+/g, ""); // 공백 제거
    // 콤마로 구분된 유효한 키워드들만 배열로 생성하되, 비어 있는 항목은 제외
    let keywords = keywordParam.split(",").filter((k) => k.trim() !== "");

    if (keywords.includes(cleanedKeyword)) {
      // 이미 존재하는 키워드라면 제거
      keywords = keywords.filter((k) => k !== cleanedKeyword);
    } else {
      // 새로운 키워드 추가 전에 키워드 개수 확인
      if (keywords.length < 5) {
        keywords.push(cleanedKeyword);
      } else {
        alert("키워드는 최대 5개까지만 선택할 수 있습니다.");
        return;
      }
    }

    // 키워드 상태 업데이트
    setKeywordParam(keywords.join(","));

    // 선택 상태 토글
    setSelectedKeywords((prevSelectedKeywords) => ({
      ...prevSelectedKeywords,
      [cleanedKeyword]: !prevSelectedKeywords[cleanedKeyword],
    }));
  };

  const switchItem = (item) => {
    switch (category) {
      case "product":
        return item.product;
      case "tech":
        return item.tech;
      case "industry":
        return item.industry;
      default:
        return item.keyword; // Defaults to keyword if none of the above match
    }
  };

  const getCategoryValue = (item) => {
    // 카테고리에 따른 값 매핑
    const valueMap = {
      keyword: item.keyword,
      product: item.product,
      tech: item.tech,
      industry: item.industry,
    };

    // 매핑된 값을 반환하거나, 매핑된 값이 undefined인 경우 빈 문자열 반환
    return valueMap[category] || "";
  };

  const openPatentPop = (params) => {
    setIsOpenPopReset(false);
    setPatentPage(1);
    getPatentList({ ...params, page: 1 });
    setIsOpenPop(true);
  };

  const handleElementClick = (el) => {
    setStaticParams((prevs) => ({ ...prevs, flag: category }));

    if (el.length > 0) {
      const dataIndex = el[0]._index;
      const _datasetIndex = el[0]._datasetIndex;
      const chartInstance = lineRef.current.chartInstance;
      const xLabel = chartInstance.data.labels[dataIndex]; // x축 레이블 값을 가져옴
      let keywordName = chartInstance.data.datasets[_datasetIndex].label;

      if (
        Object.keys(selectedKeywords).length === 0 ||
        Object.values(selectedKeywords).every((value) => value === false)
      ) {
        keywordName = "all"; // 선택된 키워드 없을때 strValue에 all 설정
      }

      updateStrValueAndYear(keywordName, xLabel, (updatedParams) =>
        openPatentPop(updatedParams)
      );
    }
  };

  const handleSmartClick = (el) => {
    setStaticParams((prevs) => ({ ...prevs, flag: "smart" }));

    if (el.length > 0) {
      const dataIndex = el[0]._index;
      const chartInstance = smartRef.current.chartInstance;
      const xLabel = chartInstance.data.labels[dataIndex]; // x축 레이블 값을 가져옴
      const keywordName = el[0].$datalabels[0].$context.dataset.label;
      let subFlagType = "";
      if (keywordName === "기술") {
        subFlagType = "t";
      } else if (keywordName === "권리성") {
        subFlagType = "r";
      } else if (keywordName === "활용성") {
        subFlagType = "u";
      }
      setStaticParams((prevs) => ({ ...prevs, subFlag: subFlagType }));

      //console.log("xLabel:", xLabel);
      //console.log("keywordName:", keywordName);

      updateStrValueAndYear(xLabel, "", (updatedParams) =>
        openPatentPop(updatedParams)
      );
    }
  };

  const handleYearClick = (el) => {
    setStaticParams((prevs) => ({ ...prevs, flag: "patent", subFlag: "" }));

    if (el.length > 0) {
      const dataIndex = el[0]._index;
      const chartInstance = yearRef.current.chartInstance;
      const xLabel = chartInstance.data.labels[dataIndex]; // x축 레이블 값을 가져옴
      let keywordName = el[0].$datalabels[0].$context.dataset.label;
      if (keywordName === "출원") {
        keywordName = "공개";
      }
      setStaticParams((prevs) => ({ ...prevs, year: xLabel }));

      //console.log("xLabelyear:", xLabel);
      //console.log("keywordNameyear:", keywordName);

      updateStrValueAndYear(keywordName, xLabel, (updatedParams) =>
        openPatentPop(updatedParams)
      );
    }
  };

  return (
    <div id="IpStatistic">
      <div className="section">
        <div className="">
          <strong className="sub_tit">
            분포현황
            <span className="desc">* 카테고리 및 키워드를 선택하세요</span>
          </strong>
          <div className="status_box">
            <div className="cate_box">
              <div className="cate">
                <p>카테고리</p>
                <ul>
                  <li>
                    <button
                      className={category === "product" ? "on" : ""}
                      onClick={() => {
                        setCurPage(1);
                        setCategory("product");
                      }}
                    >
                      제품
                    </button>
                  </li>
                  <li>
                    <button
                      className={category === "keyword" ? "on" : ""}
                      onClick={() => {
                        setCurPage(1);
                        setCategory("keyword");
                      }}
                    >
                      키워드
                    </button>
                  </li>
                  <li>
                    <button
                      className={category === "industry" ? "on" : ""}
                      onClick={() => {
                        setCurPage(1);
                        setCategory("industry");
                      }}
                    >
                      업종
                    </button>
                  </li>
                  <li>
                    <button
                      className={category === "tech" ? "on" : ""}
                      onClick={() => {
                        setCurPage(1);
                        setCategory("tech");
                      }}
                    >
                      산업기술분류
                    </button>
                  </li>
                </ul>
              </div>
              <div className="keyword">
                <p>키워드</p>
                <div>
                  <input
                    type="text"
                    id="keyword"
                    name="keyword"
                    value={schKeyword}
                    onChange={(e) => {
                      setSchKeyword(e.target.value);
                    }}
                  />
                  <button className="search" onClick={() => getKeywords()}>
                    검색
                  </button>
                  {/* <button>전체보기</button> */}
                </div>
              </div>
              <div className="keyword">
                <p>선택 키워드</p>
                {Object.keys(selectedKeywords).length === 0
                  ? ""
                  : Object.keys(selectedKeywords)
                      .filter((keyword) => selectedKeywords[keyword] === true)
                      .map((keyword) => (
                        <div
                          key={keyword}
                          className="selected"
                          onClick={() => makeKeywordString(keyword)}
                        >
                          {keyword}
                          <i className="icon_exit_white"></i>
                        </div>
                      ))}
              </div>
              {distribution.length ? (
                <>
                  <ul className="keyword_list">
                    {distribution.map((item) => {
                      const categoryValue = getCategoryValue(item);
                      let isActive =
                        (categoryValue &&
                          selectedKeywords[
                            categoryValue.replace(/\s+/g, "")
                          ]) ||
                        false;
                      return (
                        <li
                          key={item.idx}
                          onClick={() => makeKeywordString(categoryValue)}
                          className={isActive ? "on" : ""}
                        >
                          {categoryValue}
                        </li>
                      );
                    })}
                  </ul>
                  <Pagination
                    curPage={curPage}
                    lastNum={last}
                    onClick={setCurPage}
                    disableMoveToTop={true}
                  />
                </>
              ) : (
                <p className="no_data">데이터가 없습니다.</p>
              )}
            </div>
            <div className="chart">
              {/* <Pie
                data={distributionChartData}
                options={distributionChartOptions}
              /> */}
              <Line
                ref={lineRef}
                data={distributionChart}
                options={fieldChartOptions}
                width={760}
                height={200}
                getElementAtEvent={handleElementClick}
              />
              <div className="tbl_box distribution_tbl">
                <table>
                  <thead>
                    <tr>
                      <th>순위</th>
                      <th>명칭</th>
                      <th>특허 개수</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keywordResult.length ? (
                      keywordResult.map((item, idx) => (
                        <tr
                          key={idx}
                          onClick={() => {
                            setStaticParams((prevs) => ({
                              ...prevs,
                              flag: category,
                            }));
                            updateStrValueAndYear(
                              getCategoryValue(item),
                              "",
                              (updatedParams) => openPatentPop(updatedParams)
                            );
                          }}
                        >
                          <td>{idx + 1}</td>
                          <td>{getCategoryValue(item)}</td>
                          <td>{item.count}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">데이터가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading show={loading && isLoding} />
      {isOpenPop && (
        <DistributionPop
          closePatentPop={closePatentPop}
          patents={patentInfo}
          setPatentPage={setPatentPage}
          dataCondition={staticParams}
          setViewData={setViewData}
          setShowPopup={setShowPopup}
        />
      )}
      {showPopup && (
        <div className="modal">
          <iframe
            src={`https://biznavi.co.kr/researchTech/ipnow/patent/${
              viewData.nation_string === "KR"
                ? viewData.apply_number.replace(/-/g, "")
                : viewData.lt_number
            }/detail?nation=${
              viewData.nation_string
            }&companyName=${compName}&tab=0`}
            width="100%"
            height="100%"
            title="특허상세"
          />
          <button onClick={() => setShowPopup(false)}>
            <i className="icon_exit" />
          </button>
        </div>
      )}
    </div>
  );
};

export default DistributionStatus;
