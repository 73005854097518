/* eslint-disable default-case */
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AccountAPI from "../../API/account";
import CommonAPI from "../../API/common";
import ProductAPI from "../../API/product";
import TradeMarkAPI from "../../API/trademark";
import replyIcon from "../../assets/images/common/icon/reply.svg";
import config from "../../const/config";
import MemberContext from "../../store/Member";
import TradeMarkContext from "../../store/TradeMark";
import TradeMarkFileTypeContext from "../../store/TradeMarkFileType";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import Modal from "../common/Modal";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import DatePickerCustom from "../datepicker/DatePickerCustom";
import { krAndUsCategory } from "./categoryList";
import HistoryPopup from "./HistoryPopup";
import NameSearchPopup from "./NameSearchPopup";
import NationListPopUp from "./NationListPopUp";
import ProductClassificationPopup from "./ProductClassificationPopup";
import ProductPopUp from "./ProductPopUp";
import ProductSelectPopup from "./ProductSelectPopup";
import DatePickerAndFileRow from "./row/DatePickerAndFileRow";
import DatePickerRow from "./row/DatePickerRow";
import InputRow from "./row/InputRow";
import ReplyRows from "./row/ReplyRows";
import ToggleButtonRow from "./row/ToggleButtonRow";
import FormApplyKR from "./tradeMarkDetailForm/FormApplyKR";
import FormApplyOverseas from "./tradeMarkDetailForm/FormApplyOverseas";
import FormHistory from "./tradeMarkDetailForm/FormHistory";
import FormInfoKR from "./tradeMarkDetailForm/FormInfoKR";
import FormInfoOverseas from "./tradeMarkDetailForm/FormInfoOverseas";
import FormRejectKR from "./tradeMarkDetailForm/FormRejectKR";
import FormRejectOverseas from "./tradeMarkDetailForm/FormRejectOverseas";
import TradeMarkEventPopup from "./TradeMarkEventPopup";
import TradeMarkNationAddPopup from "./TradeMarkNationAddPopup";

const RenewalTradeMarkDetail = (item) => {
  const img_type = config.imgType;
  let trademarkInfo = useContext(TradeMarkContext);
  let trademarkFileType = useContext(TradeMarkFileTypeContext);
  const { auth, manage_company_idx } = useContext(MemberContext);
  const millisecondsIn10Years = 10 * 365 * 24 * 60 * 60 * 1000;
  const { trademark_idx } = useParams(); // params 가져옴
  const isNewTrademark = trademark_idx === "new"; // params 값이 new이면 true
  const [newTrademarkIdx, setNewTrademarkIdx] = useState(`t_${Date.now()}`);

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const [tradeMark, setTradeMark] = useState(trademarkInfo);
  const [fileType, setFileType] = useState(trademarkFileType);
  const [fileImage, setFileImage] = useState("");
  const [fpFileImage, setFpFileImage] = useState("");
  const [showAddList, setShowAddList] = useState(false);
  const [showSideAddList, setShowSideAddList] = useState(false);
  const [downPopupShow, setDownPopupShow] = useState(false); // 다운로드 팝업 보여주기 여부
  const [upPopupShow, setUpPopupShow] = useState(false); // 업로드 팝업 보여주기 여부
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
  const [popupProp, setPopupProp] = useState({ type: null, oa_idx: null }); // 팝업 창 열때 연결할 항목의 값
  const [managerSelectTag, setManagerSelectTag] = useState([]);
  const [agentListSelectTag, setAgentListSelectTag] = useState([]);
  const [objectionSelectTag, setObjectionSelectTag] = useState([]);
  const [productSelect, setProductSelect] = useState([]); // 관련 제품명
  const [categoryList, setCategoryList] = useState([]); // 항목 추가를 위한 Array
  const [historyTradeMark, setHistoryTradeMark] = useState({});
  const [applicantListTag, setApplicantListTag] = useState([]); // 출원인 Select Box 리스트
  const [rightHolderListTag, setRightHolderListTag] = useState([]); // 최종권리자 Select Box 리스트
  const [judgmentStateListTag, setJudgmentStateListTag] = useState([]); // 판결승소상태 Select Box
  const [brandNameTag, setBrandNameTag] = useState([]); // 상표명칭 Select Box 리스트
  const [downloadable, setDownloadable] = useState(true); // 다운로드 버튼 노출 여부
  const [secondIdx, setSecondIdx] = useState(null);
  // 서지사항 -> 현 상태에서 사용 (secondStateOption 까지)
  const [stateDepth, setStateDepth] = useState({
    first: "",
    second: "",
  });
  const [stateList, setStateList] = useState({});
  const [secondStateOption, setSecondStateOption] = useState([]);
  const [selectTagName, setSelectTagName] = useState({
    applicantName: "",
    rightHolderName: "",
    brandName: "",
    refusalName: "",
    trialPatentName: " ",
    trialSupremeName: "",
    caseReversedName: "",
  });
  const [historyPopupShow, setHistoryPopupShow] = useState(false);
  const [historyNewPopupShow, setHistoryNewPopupShow] = useState(false);
  const [eventPopupShow, setEventPopupShow] = useState(false);
  const [isNationPopupClick, setIsNationPopupClick] = useState(false);
  const [searhPopupClick, setSearchPopupClick] = useState(false);
  const [isProductionPopupClick, setIsProductionPopupClick] = useState(false);
  const [classificationPopupShow, setClassificationPopupShow] = useState(false);
  const [selectPopupShow, setSelectPopupShow] = useState(false);
  const [nationList, setNationList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);
  // 지정국에서 사용하는 상태관리 변수
  const [nationPopupShow, setNationPopupShow] = useState(false);
  const [nationPopupType, setNationPopupType] = useState(null);
  // 상표 취하, 포기 Request Query 값
  const [queryType, setQueryType] = useState(null);
  const [designatedIdx, setDesignatedIdx] = useState(null);
  const [categoryNumber, setCategoryNumber] = useState(null);
  const [declareIdx, setDeclareIdx] = useState({
    declare_idx: "",
    extension_idx: "",
  });
  const [productClassNo, setProductClassNo] = useState(0);
  const [folding, setfolding] = useState(true);
  const [foldingtitle, setFoldingtitle] = useState(false);
  const [foldingMain, setFoldingMain] = useState(false);
  const [foldingMiddle, setFoldingMiddle] = useState(false);
  const [foldingEnd, setFoldingEnd] = useState(false);
  const [foldingMemo, setFoldingMemo] = useState(false);
  const [foldingProductList, setFoldingProductList] = useState(false);
  const [foldingOa, setFoldingOa] = useState(false);
  const [foldingObjection, setFoldingObjection] = useState(false);
  const [foldingWithdraw, setFoldingWithdraw] = useState(false);
  const [foldingGiveup, setFoldingGiveup] = useState(false);
  const [foldingRegist, setFoldingRegist] = useState(false);
  const [foldingErase, setFoldingErase] = useState(false);
  const [foldingHistory, setFoldingHistory] = useState(false);

  const [temporaryData, setTemporaryData] = useState({});

  const [oaEditingStates, setOaEditingStates] = useState(
    Array(tradeMark.oa.length).fill(false)
  );
  const [oaFoldingStates, setOaFoldingStates] = useState(
    new Array(tradeMark.oa.length).fill(false)
  );

  const [inputValue, setInputValue] = useState("");

  const [firstDeadline, setFirstDeadline] = useState({
    desc: "",
    date: 0,
  });

  // 지정상품 Clipboard 복사를 위한 Ref
  const copyText = useRef();

  // 지정상품 텍스트 추출 payload로 사용하기위한 변수
  const [summaryType, setSummaryType] = useState({
    language_type: 0,
    split_type: null,
  });

  // 지정상품 추출된 텍스트 저장 변수
  const [productSummary, setProductSummary] = useState("");

  // history 팝업에 보여질 history index를 저장하기 위한 변수
  const [historyPopupIdx, setHistoryPopupIdx] = useState(null);

  // EventPopup에서 해외상표와 마드리드 국제상표를 구분해서 데이터를 넣기 위한 변수
  const [eventPopupType, setEventPopupType] = useState(null);

  // 마드리드 서지사항에서 기초상표와 서지정보 동일 토글버튼 이벤트 발생시 값을 변경시키기 위한 변수
  const [madridBasicManage, setMadridBasicManage] = useState({
    basic_apply_number: "",
    basic_apply_at: 0,
    basic_regist_number: "",
    basic_regist_at: 0,
  });
  const history = useHistory();
  /* 들여쓰기 */
  const indentedText = productSummary.replace(
    /\[\d+\]/g,
    (match) => `\n  ${match}`
  );
  useEffect(() => {}, [indentedText]);

  useEffect(() => {
    // 상세 페이지 일때
    if (!isNewTrademark) {
      initTradeMarkInfo();
    } else {
      tradeMark.setTradeMark = (obj) => setTradeMark(obj);
      tradeMark.editing = true;
      tradeMark.infoEditing = true;
      tradeMark.memoEditing = true;
      tradeMark.applyEditing = true;
      tradeMark.productEditing = true;
      tradeMark.oaEditing = true;
      tradeMark.objectionEditing = true;
      tradeMark.withdrawEditing = true;
      tradeMark.giveupEditing = true;
      tradeMark.rejectEditing = true;
      tradeMark.registEditing = true;
      tradeMark.eraseEditing = true;
      tradeMark.historyEditing = true;
      tradeMark.t_idx = newTrademarkIdx;
      setTradeMark({
        ...tradeMark,
      });
      setCategoryList([...krAndUsCategory]);
    }
    let file_type = {};
    if (isCompany) {
      AccountAPI.getAccountCompany().then((res) => {
        if (res.data.manager) {
          setManagerSelectTag(
            res.data.manager.map((item) => (
              <option key={item.idx} value={item.idx}>
                {item.name}
              </option>
            ))
          );
        }
      });
      AccountAPI.getAgentCompanyList().then((res) => {
        setAgentListSelectTag(
          res.data.map((item) => (
            <option key={item.idx} value={item.company_idx}>
              {item.name}
            </option>
          ))
        );
      });
      setObjectionSelectTag([
        <option key={1} value={1}>
          계속중(Pending)
        </option>,
        <option key={2} value={2}>
          종결(Ended)
        </option>,
      ]);
      ProductAPI.getProductListCompany().then((res) => {
        setProductSelect(
          res.data.map((item) => (
            <option key={item.idx} value={item.idx}>
              {item.name}
            </option>
          ))
        );
      });
      TradeMarkAPI.getClass().then((res) => {
        setClassificationList(res.data);
      });
      // 현 상태 값 불러오는 API
      TradeMarkAPI.getCurrentStateList().then((res) => {
        setStateList(res.data);
        setStateDepth({
          ...stateDepth,
          first: "active",
        });
      });
      TradeMarkAPI.getNationList().then((res) => {
        let obj = { ...res.data };
        delete obj.designatedCountry;

        let nationListArr = [];
        Object.entries(obj).map((item) => {
          nationListArr.push({
            name: item[0],
            isOpen: false,
            nationList: item[1],
          });
        });
        setNationList(nationListArr);
      });
      // 판결승소상태 불러오는 API
      CommonAPI.getCompanyCustomKeyword("judgmentState").then((res) => {
        setJudgmentStateListTag(
          res.data.items.map((item) => (
            <option key={item.idx} value={item.idx}>
              {item.data}
            </option>
          ))
        );
      });

      file_type.survey = "survey";
      file_type.apply = "apply";
      file_type.apply_file = "apply_file";
      file_type.oa_content = "oa_content";
      file_type.oa_opinion = "oa_opinion";
      file_type.oa_apply = "oa_apply";
      file_type.oa_declare = "oa_declare";
      file_type.oa_declare_extension = "oa_declare_extension";
      file_type.objection_content = "objection_content";
      file_type.objection_opinion = "objection_opinion";
      file_type.regist = "regist";
      file_type.regist_file = "regist_file";
      file_type.expiration = "expiration";
      file_type.renewal = "renewal";
      file_type.renew = "renew";
      file_type.renew_split = "renew_split";
      file_type.image = "image";
      file_type.fp_image = "fp_image";
      file_type.drop = "drop";
      file_type.drop1 = "drop1";
      file_type.drop2 = "drop2";
      file_type.giveup = "giveup";
      file_type.giveup1 = "giveup1";
      file_type.giveup2 = "giveup2";
      file_type.split = "split";
      file_type.history = "history";
      file_type.coexistence = "coexistence";
      file_type.registration = "registration";
      file_type.international = "international";
      file_type.designated = "designated";
      file_type.correction = "correction";
      file_type.judge = "judge";
      file_type.objection = "objection";
      file_type.dispute_giveup = "dispute_giveup";
      file_type.dispute_history = "dispute_history";
      file_type.reject = "reject";
      file_type.reject_exam_oa = "reject_exam_oa";
      file_type.refusal_decision_oa = "refusal_decision_oa";
      file_type.trial_decision_patent_oa = "trial_decision_patent_oa";
      file_type.case_reversed_oa = "case_reversed_oa";
      file_type.reject_deadline = "reject_deadline";
      file_type.reject_exam_file1 = "reject_exam_file1";
      file_type.reject_exam_file2 = "reject_exam_file2";
      file_type.reject_exam_file3 = "reject_exam_file3";
      file_type.refusal_decision_file1 = "refusal_decision_file1";
      file_type.refusal_decision_file2 = "refusal_decision_file2";
      file_type.refusal_decision_file3 = "refusal_decision_file3";
      file_type.trial_decision_patent_file1 = "trial_decision_patent_file1";
      file_type.trial_decision_patent_file2 = "trial_decision_patent_file2";
      file_type.trial_decision_patent_file3 = "trial_decision_patent_file3";
      file_type.trial_decision_supreme_file1 = "trial_decision_supreme_file1";
      file_type.trial_decision_supreme_file2 = "trial_decision_supreme_file2";
      file_type.is_case_file1 = "is_case_file1";
      file_type.case_reversed_file1 = "case_reversed_file1";
      file_type.reject_exam_content_file = "reject_exam_content_file";
      file_type.reject_exam_apply_file = "reject_exam_apply_file";
      file_type.reject_exam_opinion_file = "reject_exam_opinion_file";
      file_type.refusal_decision_content_file = "refusal_decision_content_file";
      file_type.refusal_decision_apply_file = "refusal_decision_apply_file";
      file_type.refusal_decision_opinion_file = "refusal_decision_opinion_file";
      file_type.trial_decision_patent_content_file =
        "trial_decision_patent_content_file";
      file_type.trial_decision_patent_apply_file =
        "trial_decision_patent_apply_file";
      file_type.trial_decision_patent_opinion_file =
        "trial_decision_patent_opinion_file";
      file_type.case_reversed_content_file = "case_reversed_content_file";
      file_type.case_reversed_apply_file = "case_reversed_apply_file";
      file_type.case_reversed_opinion_file = "case_reversed_opinion_file";
    } else if (isAgent) {
      AccountAPI.getAccountAgent().then((res) => {
        if (res.data.manager) {
          setManagerSelectTag(
            res.data.manager.map((item) => (
              <option key={item.idx} value={item.idx}>
                {item.name}
              </option>
            ))
          );
        }
      });
      file_type.survey = "agent_survey";
      file_type.apply = "agent_apply";
      file_type.apply_file = "apply_file";
      file_type.oa_content = "agent_oa_content";
      file_type.oa_opinion = "agent_oa_opinion";
      file_type.oa_apply = "agent_oa_apply";
      file_type.oa_declare = "oa_declare";
      file_type.oa_declare_extension = "oa_declare_extension";
      file_type.objection_content = "agent_objection_content";
      file_type.objection_opinion = "agent_objection_opinion";
      file_type.regist = "agent_regist";
      file_type.expiration = "agent_expiration";
      file_type.renewal = "agent_renewal";
      file_type.renew = "renew";
      file_type.renew_split = "renew_split";
      file_type.image = "image";
      file_type.drop = "drop";
      file_type.drop1 = "drop1";
      file_type.drop2 = "drop2";
      file_type.giveup = "giveup";
      file_type.giveup1 = "giveup1";
      file_type.giveup2 = "giveup2";
      file_type.split = "split";
      file_type.history = "history";
      file_type.coexistence = "coexistence";
      file_type.registration = "registration";
      file_type.international = "international";
      file_type.designated = "designated";
      file_type.correction = "correction";
      file_type.judge = "judge";
      file_type.objection = "objection";
      file_type.dispute_giveup = "dispute_giveup";
      file_type.dispute_history = "dispute_history";
      file_type.reject = "reject";
      file_type.reject_exam_file1 = "reject_exam_file1";
      file_type.reject_exam_file2 = "reject_exam_file2";
      file_type.reject_exam_file3 = "reject_exam_file3";
      file_type.refusal_decision_file1 = "refusal_decision_file1";
      file_type.refusal_decision_file2 = "refusal_decision_file2";
      file_type.refusal_decision_file3 = "refusal_decision_file3";
      file_type.trial_decision_patent_file1 = "trial_decision_patent_file1";
      file_type.trial_decision_patent_file2 = "trial_decision_patent_file2";
      file_type.trial_decision_patent_file3 = "trial_decision_patent_file3";
      file_type.trial_decision_supreme_file1 = "trial_decision_supreme_file1";
      file_type.trial_decision_supreme_file2 = "trial_decision_supreme_file2";
      file_type.is_case_file1 = "is_case_file1";
      file_type.case_reversed_file1 = "case_reversed_file1";
    }
    setFileType(file_type);
  }, [auth, trademark_idx]);

  /* 현 상태 first Select Box 선택 시 호출*/
  useEffect(() => {
    if (stateDepth.first) {
      setSecondOption();
    }
  }, [stateDepth.first]);

  useEffect(() => {}, [productSummary]);

  useEffect(() => {
    if (
      !Number.isInteger(tradeMark.depend_deadline) ||
      tradeMark.depend_deadline === 0
    ) {
      if (tradeMark.regist_at === 0) {
        tradeMark.depend_deadline = 0;
        setTradeMark({ ...tradeMark });
      } else {
        tradeMark.depend_deadline =
          Date.parse(
            window.$Global.convertRegistAtPlusDate(
              tradeMark.regist_at,
              tradeMark.renew_list.length
            )
          ) || null;
        setTradeMark({ ...tradeMark });
      }
    } else {
      setTradeMark({ ...tradeMark });
    }
  }, [tradeMark.regist_at, tradeMark.depend_deadline]);

  useEffect(() => {
    if (tradeMark.product_class.length > 0 && summaryType.split_type !== null)
      productClassSummary();
  }, [summaryType.split_type, tradeMark.nation]);

  // // 뒤로가기 블락하는 코드
  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (
  //       tradeMark.registEditing ||
  //       tradeMark.memoEditing ||
  //       tradeMark.withdrawEditing ||
  //       tradeMark.objectionEditing ||
  //       tradeMark.giveupEditing ||
  //       tradeMark.historyEditing ||
  //       tradeMark.productEditing ||
  //       tradeMark.historyEditing ||
  //       tradeMark.eraseEditing ||
  //       tradeMark.oaEditing ||
  //       tradeMark.infoEditing
  //     ) {
  //       return window.confirm("페이지를 이동하시겠습니까? 변경사항이 저장되지 않을 수 있습니다.");
  //     }
  //   });
  //   return () => unblock();
  // }, [tradeMark, history]);

  // // 새로고침 블락하는 코드
  // useEffect(() => {
  //   if (
  //     tradeMark.registEditing ||
  //     tradeMark.memoEditing ||
  //     tradeMark.withdrawEditing ||
  //     tradeMark.objectionEditing ||
  //     tradeMark.giveupEditing ||
  //     tradeMark.historyEditing ||
  //     tradeMark.productEditing ||
  //     tradeMark.historyEditing ||
  //     tradeMark.eraseEditing ||
  //     tradeMark.oaEditing ||
  //     tradeMark.infoEditing
  //   ) {
  //     (() => {
  //       window.addEventListener("beforeunload", preventClose);
  //     })();
  //   }
  //   return () => {
  //     window.removeEventListener("beforeunload", preventClose);
  //   };
  // }, [tradeMark]);
  const [buttonName, setButtonName] = useState("항목 삭제");
  const fieldCancel = () => {
    setButtonName("항목 저장");
  };
  // 새로고침 막는 이벤트
  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ""; // Chrome에서는 returnValue 설정이 필요함
  };

  /* 상표 첫 등록시 초기화하는 함수 */
  const initTradeMarkInfo = (option) => {
    if (isCompany) {
      TradeMarkAPI.getTradeMarkInfo(trademark_idx).then(async (res) => {
        let data = res.data;
        let arr = [];
        data.history_list.map((item) => {
          item["isEdit"] = false;
        });
        data.editing = false;
        data.setTradeMark = (obj) => setTradeMark(obj);
        setCategoryList([...krAndUsCategory]);

        data.reject_exam_oa = await TradeMarkAPI.getTradeMarkReExamination(
          data.idx
        ).then((res) => res.data);
        data.refusal_decision_oa =
          await TradeMarkAPI.getTradeMarkRefusalDecision(data.idx).then(
            (res) => res.data
          );
        data.trial_decision_patent_oa =
          await TradeMarkAPI.getTradeMarkTrialDecision(data.idx).then(
            (res) => res.data
          );
        data.case_reversed_oa = await TradeMarkAPI.getTradeMarkCaseReversed(
          data.idx
        ).then((res) => res.data);

        if (option === 0) {
          tradeMark.oa.forEach((oa, i) => {
            oa.declareInfo = data.oa[i].declareInfo;
          });

          setTradeMark({ ...tradeMark });
        } else {
          setTradeMark(data);
        }

        // 최선 마감일 구하는 로직
        arr = res.data.deadline_list
          .filter((item) => item.date > new Date().getTime())
          .sort((a, b) => {
            if (a.date > b.date) return 1;
            if (a.date === b.date) return 0;
            if (a.date < b.date) return -1;
          });

        setFirstDeadline(arr[0]);
        setHistoryTradeMark(data);

        CommonAPI.getCompanyCustomKeyword("judgmentState").then((res) => {
          selectTagName.refusalName = res.data.items.find(
            (item) =>
              item.idx ==
              Number(data.refusal_decision_objection_judge_final_trial_state)
          )?.data;
          selectTagName.trialPatentName = res.data.items.find(
            (item) =>
              item.idx == Number(data.trial_decision_patent_judgement_state)
          )?.data;
          selectTagName.trialSupremeName = res.data.items.find(
            (item) =>
              item.idx == Number(data.trial_decision_supreme_judgement_state)
          )?.data;
          selectTagName.caseReversedName = res.data.items.find(
            (item) => item.idx == Number(data.case_reversed_final_trial_state)
          )?.data;
          setSelectTagName({ ...selectTagName });
        });
      });
      ProductAPI.getProductListCompany().then((res) => {
        setProductSelect(
          res.data.map((item) => (
            <option key={item.idx} value={item.idx}>
              {item.name}
            </option>
          ))
        );
      });
    } else if (isAgent) {
      TradeMarkAPI.getTradeMarkInfoAgent(
        manage_company_idx,
        trademark_idx
      ).then(async (res) => {
        let data = res.data;
        if (!res.data.oa.length) {
          await TradeMarkAPI.createOaAgent(
            trademark_idx,
            manage_company_idx
          ).then((res) => {
            data.oa = [
              {
                idx: res.data.oa_idx,
                apply_at: null,
                apply_decision_at: null,
                deadline: null,
                extension_cnt: 0,
                objection_deadline: null,
                opinion_upload_at: null,
                published_at: null,
                submission_at: null,
                declareInfo: [],
              },
            ];
          });
        }
        data.editing = false;
        data.setTradeMark = (obj) => setTradeMark(obj);
        setTradeMark(data);
        setHistoryTradeMark(data);
      });
    }
  };

  /* 상표 저장시 호출하는 함수 */
  const onClickSave = () => {
    console.log(tradeMark.history_list);
    let formData = new FormData();
    for (let key in tradeMark) {
      if (
        key === "product_class" ||
        key === "cancel_class_list" ||
        key === "product_list" ||
        key === "renew_list" ||
        key === "designated_country_list" ||
        key === "product_classification"
      ) {
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "history_list") {
        // Mapping Uploaded Files Indexes
        let cnt = 0;
        tradeMark.history_list.forEach((item, index) => {
          if (!item.idx) {
            item.file.forEach(({ file }) => {
              formData.append(`history_file_${cnt}`, file);
            });
          }
          cnt++;
          if (item.child?.length > 0) {
            item.child.forEach((item2, index2) => {
              if (item2.file) {
                item2.file.forEach(({ file }) => {
                  formData.append(`history_file_${cnt}`, file);
                });
              }
              cnt++;
            });
          }
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "reject_exam_oa") {
        tradeMark.reject_exam_oa.map((item) => {
          TradeMarkAPI.updateTradeMarkReExamination(trademark_idx, item.idx, {
            ...item,
          });
        });
      } else if (key === "refusal_decision_oa") {
        tradeMark.refusal_decision_oa.map((item) => {
          TradeMarkAPI.updateTradeMarkRefusalDecision(trademark_idx, item.idx, {
            ...item,
          });
        });
      } else if (key === "trial_decision_patent_oa") {
        tradeMark.trial_decision_patent_oa.map((item) => {
          TradeMarkAPI.updateTradeMarkTrialDecision(trademark_idx, item.idx, {
            ...item,
          });
        });
      } else if (key === "case_reversed_oa") {
        tradeMark.case_reversed_oa.map((item) => {
          TradeMarkAPI.updateTradeMarkCaseReversed(trademark_idx, item.idx, {
            ...item,
          });
        });
      }
      formData.append(key, tradeMark[key]);
    }
    if (isCompany) {
      TradeMarkAPI.updateTradeMarkCompany(trademark_idx, formData)
        .then(() => {
          if (tradeMark.oa.length) {
            tradeMark.oa.map((item) => {
              TradeMarkAPI.updateOa(trademark_idx, item.idx, item).then(() =>
                initTradeMarkInfo()
              );
            });
          }
        })
        .then(() => {
          // Update tradeMark information
          initTradeMarkInfo();
        });
    } else if (isAgent) {
      TradeMarkAPI.updateTradeMarkAgent(
        trademark_idx,
        manage_company_idx,
        formData
      ).then(async () => {
        await initTradeMarkInfo();
      });
    }
  };

  // Modify your onClickSave function to call saveMemo for the memo area

  const toggleContentVisibility = () => {
    setFoldingProductList((prevFolding) => !prevFolding);
  };

  /* 상표 변경 컨트롤 하는 함수 */
  const handleEditing = (type, name) => {
    const responseData = { ...tradeMark };
    if (type === "edit") {
      tradeMark.editing = true;
    } else if (type === "cancel") {
      tradeMark.editing = false;
    } else if (type === "infoEditing") {
      tradeMark.infoEditing = true;
    } else if (type === "infoCancelEditing") {
      tradeMark.infoEditing = false;
    } else if (type === "memoEditing") {
      tradeMark.memoEditing = true;
    } else if (type === "memoCancelEditing") {
      tradeMark.memoEditing = false;
    } else if (type === "applyEditing") {
      tradeMark.applyEditing = true;
    } else if (type === "applyCancelEditing") {
      tradeMark.applyEditing = false;
    } else if (type === "productEditing") {
      tradeMark.productEditing = true;
    } else if (type === "productCancelEditing") {
      tradeMark.productEditing = false;
    } else if (type === "oaEditing") {
      tradeMark.oaEditing = true;
    } else if (type === "oaCancelEditing") {
      tradeMark.oaEditing = false;
    } else if (type === "objectionEditing") {
      tradeMark.objectionEditing = true;
    } else if (type === "objectionCancelEditing") {
      tradeMark.objectionEditing = false;
    } else if (type === "withdrawEditing") {
      tradeMark.withdrawEditing = true;
    } else if (type === "withdrawCancelEditing") {
      tradeMark.withdrawEditing = false;
    } else if (type === "giveupEditing") {
      tradeMark.giveupEditing = true;
    } else if (type === "giveupCancelEditing") {
      tradeMark.giveupEditing = false;
    } else if (type === "rejectEditing") {
      tradeMark.rejectEditing = true;
    } else if (type === "rejectCancelEditing") {
      tradeMark.rejectEditing = false;
    } else if (type === "registEditing") {
      tradeMark.registEditing = true;
    } else if (type === "registCancelEditing") {
      tradeMark.registEditing = false;
    } else if (type === "eraseEditing") {
      tradeMark.eraseEditing = true;
    } else if (type === "eraseCancelEditing") {
      tradeMark.eraseEditing = false;
    } else if (type === "historyEditing") {
      tradeMark.historyEditing = true;
    } else if (type === "historyCancelEditing") {
      tradeMark.historyEditing = false;
      initTradeMarkInfo();
    }
    setTradeMark({ ...tradeMark });
  };

  const handleOaFolding = (idx) => {
    const updatedOaFoldingStates = [...oaFoldingStates];
    updatedOaFoldingStates[idx] = !updatedOaFoldingStates[idx];
    setOaFoldingStates(updatedOaFoldingStates);
  };

  /* 상표 삭제 컨트롤 하는 함수 */
  const handledelete = () => {
    const confirmDelete = window.confirm("정말로 삭제하시겠습니까?");
    if (confirmDelete) {
      TradeMarkAPI.tradeMarkdelete(trademark_idx).then((res) => {
        alert("삭제 되었습니다");
        history.push("/tradeMark/list");
        window.location.reload();
      });
    }
  };
  /* 상표 등록시 호출하는 함수 */
  const onClickAdd = () => {
    if (tradeMark.name.length == 0) {
      alert("상표 명칭을 입력해주세요");
      return;
    }

    let formData = new FormData();

    for (let key in tradeMark) {
      if (
        key === "product_class" ||
        key === "cancel_class_list" ||
        key === "product_list"
      ) {
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "history_list") {
        tradeMark.history_list.forEach((item, index) => {
          item.file.forEach((el) => {
            formData.append(`history_file_${index}`, el.file);
          });
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "oa") {
        tradeMark.oa.forEach((item, index) => {
          item.oa_content_file.forEach((el) => {
            formData.append(`oa_content_file_${index}`, el.file);
          });
          item.oa_opinion_file.forEach((el) => {
            formData.append(`oa_opinion_file_${index}`, el.file);
          });
          item.oa_apply_file.forEach((el) => {
            formData.append(`oa_apply_file_${index}`, el.file);
          });
          delete item.oa_content_file;
          delete item.oa_opinion_file;
          delete item.oa_apply_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "refusal_decision_oa") {
        tradeMark.refusal_decision_oa.forEach((item, index) => {
          item.refusal_decision_content_file.forEach((el) => {
            formData.append(`refusal_decision_content_file_${index}`, el.file);
          });
          item.refusal_decision_apply_file.forEach((el) => {
            formData.append(`refusal_decision_apply_file_${index}`, el.file);
          });
          item.refusal_decision_opinion_file.forEach((el) => {
            formData.append(`refusal_decision_opinion_file_${index}`, el.file);
          });
          delete item.refusal_decision_content_file;
          delete item.refusal_decision_apply_file;
          delete item.refusal_decision_opinion_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "reject_exam_oa") {
        tradeMark.reject_exam_oa.forEach((item, index) => {
          item.reject_exam_content_file.forEach((el) => {
            formData.append(`reject_exam_content_file_${index}`, el.file);
          });
          item.reject_exam_apply_file.forEach((el) => {
            formData.append(`reject_exam_apply_file_${index}`, el.file);
          });
          item.reject_exam_opinion_file.forEach((el) => {
            formData.append(`reject_exam_opinion_file_${index}`, el.file);
          });
          delete item.reject_exam_content_file;
          delete item.reject_exam_apply_file;
          delete item.reject_exam_opinion_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "trial_decision_patent_oa") {
        tradeMark.trial_decision_patent_oa.forEach((item, index) => {
          item.trial_decision_patent_content_file.forEach((el) => {
            formData.append(
              `trial_decision_patent_content_file_${index}`,
              el.file
            );
          });
          item.trial_decision_patent_apply_file.forEach((el) => {
            formData.append(
              `trial_decision_patent_apply_file_${index}`,
              el.file
            );
          });
          item.trial_decision_patent_opinion_file.forEach((el) => {
            formData.append(
              `trial_decision_patent_opinion_file_${index}`,
              el.file
            );
          });
          delete item.trial_decision_patent_content_file;
          delete item.trial_decision_patent_apply_file;
          delete item.trial_decision_patent_opinion_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "case_reversed_oa") {
        tradeMark.case_reversed_oa.forEach((item, index) => {
          item.case_reversed_content_file.forEach((el) => {
            formData.append(`case_reversed_content_file_${index}`, el.file);
          });
          item.case_reversed_apply_file.forEach((el) => {
            formData.append(`case_reversed_apply_file_${index}`, el.file);
          });
          item.case_reversed_opinion_file.forEach((el) => {
            formData.append(`case_reversed_opinion_file_${index}`, el.file);
          });
          delete item.case_reversed_content_file;
          delete item.case_reversed_apply_file;
          delete item.case_reversed_opinion_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      } else if (key === "renew_list") {
        tradeMark.renew_list.forEach((item, index) => {
          item.renew_file.forEach((el) => {
            formData.append(`renew_file_${index}`, el.file);
          });
          item.renew_split_file.forEach((el) => {
            formData.append(`renew_split_file_${index}`, el.file);
          });
          delete item.renew_file;
          delete item.renew_split_file;
        });
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      }
      formData.append(key, tradeMark[key]);
    }

    tradeMark.fileList.image.forEach((item) => {
      formData.append("file", item.file);
    });

    tradeMark.fileList.fp_image.forEach((item) => {
      formData.append("fp_file", item.file);
    });

    tradeMark.fileList.survey.forEach((item) => {
      formData.append("survey_file", item.file);
    });

    tradeMark.fileList.drop1.forEach((item) => {
      formData.append("drop_file1", item.file);
    });

    tradeMark.fileList.giveup1.forEach((item) => {
      formData.append("giveup_file1", item.file);
    });

    tradeMark.fileList.drop2.forEach((item) => {
      formData.append("drop_file2", item.file);
    });

    tradeMark.fileList.giveup2.forEach((item) => {
      formData.append("giveup_file2", item.file);
    });

    tradeMark.fileList.apply.forEach((item) => {
      formData.append("apply_file", item.file);
    });

    tradeMark.fileList.regist.forEach((item) => {
      formData.append("regist_file", item.file);
    });

    tradeMark.fileList.objection_content.forEach((item) => {
      formData.append("objection_content_file", item.file);
    });

    tradeMark.fileList.objection_opinion.forEach((item) => {
      formData.append("objection_opinion_file", item.file);
    });

    tradeMark.fileList.expiration.forEach((item) => {
      formData.append("first_expiration_file", item.file);
    });

    tradeMark.fileList.renewal.forEach((item) => {
      formData.append("renewal_file", item.file);
    });

    tradeMark.fileList.reject.forEach((item) => {
      formData.append("reject_file", item.file);
    });
    tradeMark.fileList.reject_deadline.forEach((item) => {
      formData.append("reject_deadline_file", item.file);
    });

    tradeMark.fileList.reject_exam_file1.forEach((item) => {
      formData.append("reject_exam_file1", item.file);
    });
    tradeMark.fileList.reject_exam_file2.forEach((item) => {
      formData.append("reject_exam_file2", item.file);
    });
    tradeMark.fileList.reject_exam_file3.forEach((item) => {
      formData.append("reject_exam_file3", item.file);
    });

    tradeMark.fileList.refusal_decision_file1.forEach((item) => {
      formData.append("refusal_decision_file1", item.file);
    });
    tradeMark.fileList.refusal_decision_file2.forEach((item) => {
      formData.append("refusal_decision_file2", item.file);
    });
    tradeMark.fileList.refusal_decision_file3.forEach((item) => {
      formData.append("refusal_decision_file3", item.file);
    });

    tradeMark.fileList.trial_decision_patent_file1.forEach((item) => {
      formData.append("trial_decision_patent_file1", item.file);
    });
    tradeMark.fileList.trial_decision_patent_file2.forEach((item) => {
      formData.append("trial_decision_patent_file2", item.file);
    });
    tradeMark.fileList.trial_decision_patent_file3.forEach((item) => {
      formData.append("trial_decision_patent_file3", item.file);
    });

    tradeMark.fileList.trial_decision_supreme_file1.forEach((item) => {
      formData.append("trial_decision_supreme_file1", item.file);
    });
    tradeMark.fileList.trial_decision_supreme_file2.forEach((item) => {
      formData.append("trial_decision_supreme_file2", item.file);
    });

    tradeMark.fileList.is_case_file1.forEach((item) => {
      formData.append("is_case_file1", item.file);
    });

    tradeMark.fileList.case_reversed_file1.forEach((item) => {
      formData.append("case_reversed_file1", item.file);
    });

    TradeMarkAPI.addTradeMark(formData).then((res) => {
      window.location.href = `/tradeMark/detail/${res.data.trademark_idx}`;
    });
  };

  /* 현 상태 두 번째 Option 세팅 함수 */
  const setSecondOption = () => {
    let arr = [];
    Object.keys(stateList[stateDepth.first]).map((key) => {
      let obj = {
        label: window.$Global.convertStateName(
          stateList[stateDepth.first][key].state,
          stateList[stateDepth.first][key].termination_type
        ),
        state: stateList[stateDepth.first][key].state,
        termination_type:
          stateList[stateDepth.first][key].termination_type || 0,
      };
      arr.push(obj);
    });
    setSecondStateOption(arr);
  };

  /* 서지사항 -> 현 상태 활성화시 자동으로 상태 변경해주는 함수 */
  const setStateAuto = () => {
    let params = {
      apply_number: tradeMark.apply_number ? tradeMark.apply_number : "",
      apply_at: tradeMark.apply_at ? tradeMark.apply_at : "",
      open_number: tradeMark.open_number ? tradeMark.open_number : "",
      open_at: tradeMark.open_at ? tradeMark.open_at : "",
      reject_receipt_at: tradeMark.reject_receipt_at
        ? tradeMark.reject_receipt_at
        : "",
      regist_number: tradeMark.regist_number ? tradeMark.regist_number : "",
      regist_at: tradeMark.regist_at ? tradeMark.regist_at : "",
    };

    TradeMarkAPI.getCurrentStateSearch(params).then((res) => {
      setTradeMark({
        ...tradeMark,
        state: res.data.state,
        termination_type: res.data.termination_type || 0,
      });
    });
  };

  const addCategory = (type) => {
    if (tradeMark[type] === 1) {
      alert("이미 추가된 항목입니다.");
    } else {
      tradeMark[type] = 1;
    }
    setTradeMark({ ...tradeMark });
    setShowAddList(false);
  };

  const onClickDownload = (idx) => {
    switch (popupProp.type) {
      case "survey":
        TradeMarkAPI.downloadCompanySurveyFile(trademark_idx, idx);
        break;
      case "apply":
        isNewTrademark
          ? TradeMarkAPI.downloadCompanyNewApplyFile(newTrademarkIdx, idx)
          : TradeMarkAPI.downloadCompanyApplyFile(trademark_idx, idx);
        break;
      case "apply_file":
        isNewTrademark
          ? TradeMarkAPI.downloadCompanyNewApplyFiles(newTrademarkIdx, idx)
          : TradeMarkAPI.downloadTradeMarkApplyFile(trademark_idx, idx);
        break;
      case "oa_content":
        TradeMarkAPI.downloadCompanyOaContentFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "oa_opinion":
        TradeMarkAPI.downloadCompanyOaOpinionFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "oa_apply":
        TradeMarkAPI.downloadCompanyOaApplyFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "oa_declare":
        TradeMarkAPI.downloadUseDeclareFiles(
          trademark_idx,
          popupProp.oa_idx,
          declareIdx.declare_idx,
          idx
        );
        break;
      case "oa_declare_extension":
        TradeMarkAPI.downloadUseDeclareExtensionFiles(
          trademark_idx,
          popupProp.oa_idx,
          declareIdx.declare_idx,
          declareIdx.extension_idx,
          idx
        );
        break;
      case "objection_content":
        TradeMarkAPI.downloadCompanyObjectionContentFile(trademark_idx, idx);
        break;
      case "objection_opinion":
        TradeMarkAPI.downloadCompanyObjectionOpinionFile(trademark_idx, idx);
        break;
      case "regist":
        isNewTrademark
          ? TradeMarkAPI.downloadCompanyNewRegistFile(newTrademarkIdx, idx)
          : TradeMarkAPI.downloadCompanyRegistFile(trademark_idx, idx);
        break;
      case "regist_file":
        isNewTrademark
          ? TradeMarkAPI.downloadCompanyNewRegistFiles(newTrademarkIdx, idx)
          : TradeMarkAPI.downloadCompanyRegistFiles(trademark_idx, idx);
        break;
      case "reject":
        TradeMarkAPI.downloadTradeMarkRejectFiles(trademark_idx, idx);
        break;
      case "reject_exam_oa":
        TradeMarkAPI.downloadTradeMarkReExaminationFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "refusal_decision_oa":
        TradeMarkAPI.downloadTradeMarkRefusalDecisionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "trial_decision_patent_oa":
        TradeMarkAPI.downloadTradeMarkTrialDecisionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "case_reversed_oa":
        TradeMarkAPI.downloadTradeMarkCaseReversedFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "expiration":
        TradeMarkAPI.downloadCompanyRenewalExpirationFile(trademark_idx, idx);
        break;
      case "renewal":
        TradeMarkAPI.downloadCompanyRenewalFile(trademark_idx, idx);
        break;
      case "renew":
        TradeMarkAPI.downloadRenewalFiles(trademark_idx, secondIdx, idx);
        break;
      case "renew_split":
        TradeMarkAPI.downloadRenewalSplitFiles(trademark_idx, secondIdx, idx);
        break;
      case "registration":
        TradeMarkAPI.downloadRenewRegistrationFiles(
          trademark_idx,
          secondIdx,
          idx
        );
        break;
      case "drop":
        TradeMarkAPI.downloadTradeMarkDropFile(trademark_idx, idx);
        break;
      case "giveup":
        TradeMarkAPI.downloadTradeMarkGiveUpFile(trademark_idx, idx);
        break;
      case "split":
        TradeMarkAPI.downloadTradeMarkSplitFiles(trademark_idx, idx);
        break;
      case "history":
        TradeMarkAPI.downloadTradeMarkHistoryFiles(
          trademark_idx,
          secondIdx,
          idx
        );
        break;
      case "designated":
        TradeMarkAPI.downloadCompanyRegistFile(designatedIdx, idx);
        break;
      case "coexistence":
        TradeMarkAPI.downloadTradeMarkCoexistenceFiles(trademark_idx, idx);
        break;
      case "international":
        TradeMarkAPI.downloadTradeMarkInternationalFiles(trademark_idx, idx);
        break;
      case "image":
        TradeMarkAPI.downloadTradeMarkImage(trademark_idx);
        break;
      case "fp_image":
        TradeMarkAPI.downloadTradeMarkFpImage(trademark_idx);
        break;
      case "agent_survey":
        TradeMarkAPI.downloadAgentSurveyFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_apply":
        TradeMarkAPI.downloadAgentApplyFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_oa_content":
        TradeMarkAPI.downloadAgentOaContentFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_oa_opinion":
        TradeMarkAPI.downloadAgentOaOpinionFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_oa_apply":
        TradeMarkAPI.downloadAgentOaApplynFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_objection_content":
        TradeMarkAPI.downloadAgentObjectionContentFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_objection_opinion":
        TradeMarkAPI.downloadAgentyObjectionOpinionFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_regist":
        TradeMarkAPI.downloadAgentyRegistFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_expiration":
        TradeMarkAPI.downloadAgentRenewalExpirationFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_renewal":
        TradeMarkAPI.downloadAgentRenewalFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_image":
        TradeMarkAPI.downloadTradeMarkImageAgent(
          trademark_idx,
          manage_company_idx
        );
        break;
    }
  };

  const onClickDeleteFileInDownload = async (idx) => {
    switch (popupProp.type) {
      case "survey":
        await TradeMarkAPI.deleteCompanySurveyFile(trademark_idx, idx);
        break;
      case "apply":
        isNewTrademark
          ? await TradeMarkAPI.deleteCompanyNewApplyFile(newTrademarkIdx, idx)
          : await TradeMarkAPI.deleteCompanyApplyFile(trademark_idx, idx);
        break;
      case "apply_file":
        isNewTrademark
          ? await TradeMarkAPI.deleteCompanyNewApplyFiles(newTrademarkIdx, idx)
          : await TradeMarkAPI.deleteTradeMarkApplyFile(trademark_idx, idx);
        break;
      case "oa_content":
        await TradeMarkAPI.deleteCompanyOaContentFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "oa_opinion":
        await TradeMarkAPI.deleteCompanyOaOpinionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "oa_apply":
        await TradeMarkAPI.deleteCompanyOaApplyFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "oa_declare":
        await TradeMarkAPI.deleteUseDeclareFiles(
          trademark_idx,
          popupProp.oa_idx,
          declareIdx.declare_idx,
          idx
        );
        break;
      case "oa_declare_extension":
        await TradeMarkAPI.deleteUseDeclareExtensionFiles(
          trademark_idx,
          popupProp.oa_idx,
          declareIdx.declare_idx,
          declareIdx.extension_idx,
          idx
        );
        break;
      case "objection_content":
        await TradeMarkAPI.deleteCompanyObjectionContentFile(
          trademark_idx,
          idx
        );
        break;
      case "objection_opinion":
        await TradeMarkAPI.deleteCompanyObjectionOpinionFile(
          trademark_idx,
          idx
        );
        break;
      case "regist":
        isNewTrademark
          ? await TradeMarkAPI.deleteCompanyNewRegistFile(newTrademarkIdx, idx)
          : await TradeMarkAPI.deleteCompanyRegistFile(trademark_idx, idx);
        break;
      case "regist_file":
        isNewTrademark
          ? await TradeMarkAPI.deleteCompanyNewRegistFiles(newTrademarkIdx, idx)
          : await TradeMarkAPI.deleteCompanyRegistFiles(trademark_idx, idx);
        break;
      case "reject":
        await TradeMarkAPI.deleteTradeMarkRejectFiles(trademark_idx, idx);
        break;
      case "reject_exam_oa":
        await TradeMarkAPI.deleteTradeMarkReExaminationFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "refusal_decision_oa":
        await TradeMarkAPI.deleteTradeMarkRefusalDecisionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "trial_decision_patent_oa":
        await TradeMarkAPI.deleteTradeMarkTrialDecisionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "case_reversed_oa":
        await TradeMarkAPI.deleteTradeMarkCaseReversedFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "expiration":
        await TradeMarkAPI.deleteCompanyRenewalExpirationFile(
          trademark_idx,
          idx
        );
        break;
      case "renewal":
        await TradeMarkAPI.deleteCompanyRenewalFile(trademark_idx, idx);
        break;
      case "renew":
        await TradeMarkAPI.deleteRenewalFiles(trademark_idx, secondIdx, idx);
        break;
      case "renew_split":
        await TradeMarkAPI.deleteRenewalSplitFiles(
          trademark_idx,
          secondIdx,
          idx
        );
        break;
      case "registration":
        await TradeMarkAPI.deleteRenewRegistrationFiles(
          trademark_idx,
          secondIdx,
          idx
        );
        break;
      case "drop":
        await TradeMarkAPI.deleteTradeMarkDropFile(trademark_idx, idx);
        break;
      case "giveup":
        await TradeMarkAPI.deleteTradeMarkGiveUpFile(trademark_idx, idx);
        break;
      case "split":
        await TradeMarkAPI.deleteTradeMarkSplitFiles(trademark_idx, idx);
        break;
      case "history":
        await TradeMarkAPI.deleteTradeMarkHistoryFiles(
          trademark_idx,
          secondIdx,
          idx
        );
        break;
      case "coexistence":
        await TradeMarkAPI.deleteTradeMarkCoexistenceFiles(trademark_idx, idx);
        break;
      case "international":
        await TradeMarkAPI.deleteTradeMarkInternationalFiles(
          trademark_idx,
          idx
        );
        break;
      case "designated":
        await TradeMarkAPI.deleteCompanyRegistFile(designatedIdx, idx);
        break;
      case "image":
        await TradeMarkAPI.updateTradeMarkCompany(trademark_idx, {
          history_list: tradeMark.history_list,
          delete_image: true,
        });
        setFileImage("");
        tradeMark.image_key = "";
        tradeMark.image_name = "";
        tradeMark.image_type = "";
        setTradeMark({ ...tradeMark });
        break;
      case "fp_image":
        await TradeMarkAPI.updateTradeMarkCompany(trademark_idx, {
          history_list: tradeMark.history_list,
          delete_fp_image: true,
        });
        setFpFileImage("");
        tradeMark.fp_image_key = "";
        tradeMark.fp_image_name = "";
        tradeMark.fp_image_type = "";
        setTradeMark({ ...tradeMark });
        break;
      case "agent_survey":
        await TradeMarkAPI.deleteAgentSurveyFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_apply":
        await TradeMarkAPI.deleteAgentApplyFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_oa_content":
        await TradeMarkAPI.deleteAgentOaContentFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_oa_opinion":
        await TradeMarkAPI.deleteAgentOaOpinionFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_oa_apply":
        await TradeMarkAPI.deleteAgentOaApplyFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_objection_content":
        await TradeMarkAPI.deleteAgentObjectionContentFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_objection_opinion":
        await TradeMarkAPI.deleteAgentObjectionOpinionFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_regist":
        await TradeMarkAPI.deleteAgentRegistFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_expiration":
        await TradeMarkAPI.deleteAgentRenewalExpirationFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_renewal":
        await TradeMarkAPI.deleteAgentRenewalFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_image":
        await TradeMarkAPI.updateTradeMarkAgent(
          trademark_idx,
          manage_company_idx,
          { file: [] }
        );
        setTradeMark({
          ...tradeMark,
          image_name: "",
          image_key: "",
          image_type: "",
        });
        break;
    }
    alert("삭제되었습니다");
    setFileViewList(
      fileViewList.filter((file) => {
        return file.idx !== idx;
      })
    );
    if (!isNewTrademark) {
      updateFileCntTradeMark();
    }
  };

  const onClickUpload = async () => {
    if (isNewTrademark) {
      let copy = _.cloneDeep(tradeMark);

      let formData = new FormData();

      uploadFileViewList.forEach((item) => {
        formData.append("file", item.file);
      });

      if (
        popupProp.type === "apply_file" ||
        popupProp.type === "apply" ||
        popupProp.type === "regist" ||
        popupProp.type === "regist_file"
      ) {
        formData.append("member_idx", window.sessionStorage.getItem("idx"));
        formData.append("type", categoryNumber);
        formData.append("trademark_idx", newTrademarkIdx);
        formData.append("idx", newTrademarkIdx);
      }

      switch (popupProp.type) {
        case "apply_file":
          await TradeMarkAPI.uploadTradeMarkNewApplyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "apply":
          await TradeMarkAPI.uploadCompanyNewApplyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "regist_file":
          await TradeMarkAPI.uploadCompanyNewRegistFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "regist":
          await TradeMarkAPI.uploadCompanyNewRegistFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "history":
          tradeMark.history_list[secondIdx].file = uploadFileViewList;
          break;
        case "oa_content":
          tradeMark.oa[secondIdx].oa_content_file = uploadFileViewList;
          break;
        case "oa_opinion":
          tradeMark.oa[secondIdx].oa_opinion_file = uploadFileViewList;
          break;
        case "oa_apply":
          tradeMark.oa[secondIdx].oa_apply_file = uploadFileViewList;
          break;
        case "renew":
          tradeMark.renew_list[secondIdx].renew_file = uploadFileViewList;
          break;
        case "renew_split":
          tradeMark.renew_list[secondIdx].renew_split_file = uploadFileViewList;
          break;
        case "reject_exam_content_file":
          tradeMark.reject_exam_oa[secondIdx].reject_exam_content_file =
            uploadFileViewList;
          break;
        case "reject_exam_apply_file":
          tradeMark.reject_exam_oa[secondIdx].reject_exam_apply_file =
            uploadFileViewList;
          break;
        case "reject_exam_opinion_file":
          tradeMark.reject_exam_oa[secondIdx].reject_exam_opinion_file =
            uploadFileViewList;
          break;
        case "refusal_decision_content_file":
          tradeMark.refusal_decision_oa[
            secondIdx
          ].refusal_decision_content_file = uploadFileViewList;
          break;
        case "refusal_decision_apply_file":
          tradeMark.refusal_decision_oa[secondIdx].refusal_decision_apply_file =
            uploadFileViewList;
          break;
        case "refusal_decision_opinion_file":
          tradeMark.refusal_decision_oa[
            secondIdx
          ].refusal_decision_opinion_file = uploadFileViewList;
          break;
        case "trial_decision_patent_content_file":
          tradeMark.trial_decision_patent_oa[
            secondIdx
          ].trial_decision_patent_content_file = uploadFileViewList;
          break;
        case "trial_decision_patent_apply_file":
          tradeMark.trial_decision_patent_oa[
            secondIdx
          ].trial_decision_patent_apply_file = uploadFileViewList;
          break;
        case "trial_decision_patent_opinion_file":
          tradeMark.trial_decision_patent_oa[
            secondIdx
          ].trial_decision_patent_opinion_file = uploadFileViewList;
          break;
        case "case_reversed_content_file":
          tradeMark.case_reversed_oa[secondIdx].case_reversed_content_file =
            uploadFileViewList;
          break;
        case "case_reversed_apply_file":
          tradeMark.case_reversed_oa[secondIdx].case_reversed_apply_file =
            uploadFileViewList;
          break;
        case "case_reversed_opinion_file":
          tradeMark.case_reversed_oa[secondIdx].case_reversed_opinion_file =
            uploadFileViewList;
          break;
        case "image":
          if (uploadFileViewList.length > 1) {
            alert("상표 이미지는 하나만 등록하실 수 있습니다");
            return;
          }
          if (
            img_type.test(
              uploadFileViewList[0].file_type.toLowerCase().toString()
            )
          ) {
            encodeFileToBase64(uploadFileViewList[0].file, popupProp.type);
            if (popupProp.type == "fp_image") {
              copy.fileList.fp_image = uploadFileViewList;
            } else {
              copy.fileList.image = uploadFileViewList;
            }
          } else {
            alert("jpg, jpeg, gif, png만 업로드하실 수 있습니다");
            return;
          }
          copy.fileList[popupProp.type] = uploadFileViewList;
          setTradeMark(copy);
          break;
        case "fp_image":
          if (uploadFileViewList.length > 1) {
            alert("상표 이미지는 하나만 등록하실 수 있습니다");
            return;
          }
          if (
            img_type.test(
              uploadFileViewList[0].file_type.toLowerCase().toString()
            )
          ) {
            encodeFileToBase64(uploadFileViewList[0].file, popupProp.type);
            if (popupProp.type == "fp_image") {
              copy.fileList.fp_image = uploadFileViewList;
            } else {
              copy.fileList.image = uploadFileViewList;
            }
          } else {
            alert("jpg, jpeg, gif, png만 업로드하실 수 있습니다");
            return;
          }
          copy.fileList[popupProp.type] = uploadFileViewList;
          setTradeMark(copy);
          break;
        default:
          copy.fileList[popupProp.type] = uploadFileViewList;
          setTradeMark(copy);
      }
    } else {
      let formData = new FormData();

      uploadFileViewList.forEach((item) => {
        formData.append("file", item.file);
      });

      if (popupProp.type === "apply_file" || popupProp.type === "regist_file") {
        formData.append("type", categoryNumber);
        formData.append("trademark_idx", trademark_idx);
        formData.append("idx", trademark_idx);
      }

      switch (popupProp.type) {
        case "survey":
          await TradeMarkAPI.uploadCompanySurveyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "apply":
          await TradeMarkAPI.uploadCompanyApplyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "apply_file": {
          await TradeMarkAPI.uploadTradeMarkApplyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        }
        case "oa_content":
          await TradeMarkAPI.uploadCompanyOaContentFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_opinion":
          await TradeMarkAPI.uploadCompanyOaOpinionFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_apply":
          await TradeMarkAPI.uploadCompanyOaApplyFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_declare":
          await TradeMarkAPI.uploadUseDeclareFiles(
            trademark_idx,
            popupProp.oa_idx,
            declareIdx.declare_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_declare_extension":
          await TradeMarkAPI.uploadUseDeclareExtensionFiles(
            trademark_idx,
            popupProp.oa_idx,
            declareIdx.declare_idx,
            declareIdx.extension_idx,
            formData
          ).then((res) => res.data);
          break;
        case "objection_content":
          await TradeMarkAPI.uploadCompanyObjectionContentFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "objection_opinion":
          await TradeMarkAPI.uploadCompanyObjectionOpinionFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "regist":
          await TradeMarkAPI.uploadCompanyRegistFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "regist_file":
          await TradeMarkAPI.uploadCompanyRegistFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "reject":
          formData.append("type", secondIdx);
          await TradeMarkAPI.uploadTradeMarkRejectFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "reject_exam_oa":
          formData.append("type", secondIdx);
          await TradeMarkAPI.uploadTradeMarkReExaminationFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "refusal_decision_oa":
          formData.append("type", secondIdx);
          await TradeMarkAPI.uploadTradeMarkRefusalDecisionFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "trial_decision_patent_oa":
          formData.append("type", secondIdx);
          await TradeMarkAPI.uploadTradeMarkTrialDecisionFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "case_reversed_oa":
          formData.append("type", secondIdx);
          await TradeMarkAPI.uploadTradeMarkCaseReversedFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "expiration":
          await TradeMarkAPI.uploadCompanyRenewalExpirationFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "renewal":
          await TradeMarkAPI.uploadCompanyRenewalFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "renew":
          await TradeMarkAPI.uploadRenewalFiles(
            trademark_idx,
            secondIdx,
            formData
          ).then((res) => res.data);
          break;
        case "renew_split":
          await TradeMarkAPI.uploadRenewalSplitFiles(
            trademark_idx,
            secondIdx,
            formData
          ).then((res) => res.data);
          break;
        case "registration":
          await TradeMarkAPI.uploadRenewRegistrationFiles(
            trademark_idx,
            secondIdx,
            formData
          ).then((res) => res.data);
          break;
        case "drop":
          formData.append("type", queryType);
          await TradeMarkAPI.uploadTradeMarkDropFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "giveup":
          formData.append("type", queryType);
          await TradeMarkAPI.uploadTradeMarkGiveUpFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "split":
          await TradeMarkAPI.uploadTradeMarkSplitFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "history":
          Number.isInteger(secondIdx)
            ? (tradeMark.history_list[secondIdx].file = uploadFileViewList)
            : await TradeMarkAPI.uploadTradeMarkHistoryFiles(
                trademark_idx,
                secondIdx,
                formData
              ).then((res) => res.data);
          break;
        case "coexistence":
          await TradeMarkAPI.uploadTradeMarkCoexistenceFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "international":
          await TradeMarkAPI.uploadTradeMarkInternationalFiles(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "designated":
          await TradeMarkAPI.uploadCompanyRegistFile(
            designatedIdx,
            formData
          ).then((res) => res.data);
          break;
        case "image":
          formData.append(
            "history_list",
            JSON.stringify(tradeMark.history_list)
          );
          if (validatorTradeMarkImage(uploadFileViewList)) {
            tradeMark.image_name = uploadFileViewList[0].file_name;
            tradeMark.image_type = uploadFileViewList[0].file_type;
            setTradeMark({ ...tradeMark });
            encodeFileToBase64(uploadFileViewList[0].file, popupProp.type);

            await TradeMarkAPI.updateTradeMarkCompany(
              trademark_idx,
              formData
            ).then((res) => {});
          }
          break;
        case "fp_image":
          formData.append(
            "history_list",
            JSON.stringify(tradeMark.history_list)
          );
          if (validatorTradeMarkImage(uploadFileViewList)) {
            tradeMark.fp_image_name = uploadFileViewList[0].file_name;
            tradeMark.fp_image_type = uploadFileViewList[0].file_type;
            setTradeMark({ ...tradeMark });
            encodeFileToBase64(uploadFileViewList[0].file, popupProp.type);
            // 제품 대표도면 추가로 99를 넣음
            formData.append("type", "99");
            await TradeMarkAPI.updateTradeMarkCompany(
              trademark_idx,
              formData
            ).then((res) => {});
          }
          break;
        case "agent_survey":
          await TradeMarkAPI.uploadAgentSurveyFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_apply":
          await TradeMarkAPI.uploadAgentApplyFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_content":
          await TradeMarkAPI.uploadAgentOaContentFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_opinion":
          await TradeMarkAPI.uploadAgentOaOpinionFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_apply":
          await TradeMarkAPI.uploadAgentOaApplyFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_objection_content":
          await TradeMarkAPI.uploadAgentObjectionContentFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_objection_opinion":
          await TradeMarkAPI.uploadAgentObjectionOpinionFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_regist":
          await TradeMarkAPI.uploadAgentRegistFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_expiration":
          await TradeMarkAPI.uploadAgentRenewalExpirationFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_renewal":
          await TradeMarkAPI.uploadAgentRenewalFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_image":
          if (validatorTradeMarkImage(uploadFileViewList)) {
            await TradeMarkAPI.updateTradeMarkAgent(
              trademark_idx,
              manage_company_idx,
              formData
            ).then((res) => res.data);
          }
          break;
      }
      alert("업로드되었습니다");
    }
    setUpPopupShow(false);
    setUploadFileViewList([]);
    if (!isNewTrademark) {
      updateFileCntTradeMark();
    }
  };

  const onClickDownPopShow = async (type, oa_idx, second_idx, third_idx) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });

    let result = [];
    switch (type) {
      case "survey":
        result = await TradeMarkAPI.getCompanySurveyFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "apply":
        result = isNewTrademark
          ? await TradeMarkAPI.getCompanyNewApplyFiles({
              trademark_idx: newTrademarkIdx,
            }).then((res) => res.data)
          : await TradeMarkAPI.getCompanyApplyFiles(trademark_idx).then(
              (res) => res.data
            );
        break;
      case "apply_file":
        result = isNewTrademark
          ? await TradeMarkAPI.getTradeMarkNewApplyFile({
              type: second_idx,
              trademark_idx: newTrademarkIdx,
            }).then((res) => res.data.items)
          : await TradeMarkAPI.getTradeMarkApplyFile(trademark_idx, {
              type: second_idx,
            }).then((res) => res.data.items);
        break;
      case "oa_content":
        result = await TradeMarkAPI.getCompanyOaContentFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "oa_opinion":
        result = await TradeMarkAPI.getCompanyOaOpinionFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "oa_apply":
        result = await TradeMarkAPI.getCompanyOaApplyFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "oa_declare":
        result = await TradeMarkAPI.getUseDeclareFiles(
          trademark_idx,
          oa_idx,
          second_idx
        ).then((res) => res.data.items);
        break;
      case "oa_declare_extension":
        result = await TradeMarkAPI.getUseDeclareExtensionFiles(
          trademark_idx,
          oa_idx,
          second_idx,
          third_idx
        ).then((res) => res.data.items);
        break;
      case "objection_content":
        result = await TradeMarkAPI.getCompanyObjectionContentFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "objection_opinion":
        result = await TradeMarkAPI.getCompanyObjectionOpinionFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "regist":
        result = isNewTrademark
          ? await TradeMarkAPI.getCompanyNewRegistFile({
              trademark_idx: newTrademarkIdx,
            }).then((res) => res.data)
          : await TradeMarkAPI.getCompanyRegistFile(trademark_idx).then(
              (res) => res.data
            );
        break;
      case "regist_file":
        result = isNewTrademark
          ? await TradeMarkAPI.getCompanyNewRegistFiles({
              type: second_idx,
              trademark_idx: newTrademarkIdx,
            }).then((res) => res.data.items)
          : await TradeMarkAPI.getCompanyRegistFiles(trademark_idx, {
              type: second_idx,
            }).then((res) => res.data.items);
        break;
      case "reject":
        result = await TradeMarkAPI.getTradeMarkRejectFiles(trademark_idx, {
          type: second_idx,
        }).then((res) => res.data.items);
        break;
      case "reject_exam_oa": {
        result = await TradeMarkAPI.getTradeMarkReExaminationFile(
          trademark_idx,
          oa_idx,
          {
            type: second_idx,
          }
        ).then((res) => res.data.items);
        break;
      }
      case "refusal_decision_oa": {
        result = await TradeMarkAPI.getTradeMarkRefusalDecisionFile(
          trademark_idx,
          oa_idx,
          {
            type: second_idx,
          }
        ).then((res) => res.data.items);
        break;
      }
      case "trial_decision_patent_oa": {
        result = await TradeMarkAPI.getTradeMarkTrialDecisionFile(
          trademark_idx,
          oa_idx,
          {
            type: second_idx,
          }
        ).then((res) => res.data.items);
        break;
      }
      case "case_reversed_oa": {
        result = await TradeMarkAPI.getTradeMarkCaseReversedFile(
          trademark_idx,
          oa_idx,
          {
            type: second_idx,
          }
        ).then((res) => res.data.items);
        break;
      }
      case "expiration":
        result = await TradeMarkAPI.getCompanyRenewalExpirationFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "renewal":
        result = await TradeMarkAPI.getCompanyRenewalFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "renew":
        result = await TradeMarkAPI.getRenewalFiles(
          trademark_idx,
          second_idx
        ).then((res) => res.data.items);
        break;
      case "renew_split":
        result = await TradeMarkAPI.getRenewalSplitFiles(
          trademark_idx,
          second_idx
        ).then((res) => res.data.items);
        break;
      case "registration":
        result = await TradeMarkAPI.getRenewRegistrationFiles(
          trademark_idx,
          second_idx
        ).then((res) => res.data.items);
        break;
      case "drop":
        result = await TradeMarkAPI.getTradeMarkDropFile(trademark_idx, {
          type: second_idx,
        }).then((res) => res.data.items);
        break;
      case "giveup":
        result = await TradeMarkAPI.getTradeMarkGiveUpFile(trademark_idx, {
          type: second_idx,
        }).then((res) => res.data.items);
        break;
      case "split":
        result = await TradeMarkAPI.getTradeMarkSplitFiles(trademark_idx).then(
          (res) => res.data.items
        );
        break;
      case "history":
        result = await TradeMarkAPI.getTradeMarkHistoryFiles(
          trademark_idx,
          second_idx
        ).then((res) => res.data.fileList);
        break;
      case "coexistence":
        result = await TradeMarkAPI.getTradeMarkCoexistenceFiles(
          trademark_idx
        ).then((res) => res.data.items);
        break;
      case "international":
        result = await TradeMarkAPI.getTradeMarkInternationalFiles(
          trademark_idx
        ).then((res) => res.data.items);
        break;
      case "designated":
        result = await TradeMarkAPI.getCompanyRegistFiles(second_idx).then(
          (res) => res.data
        );
        break;

      case "image":
        if (tradeMark.image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.image_name,
              file_type: tradeMark.image_type,
              created_at: "",
            },
          ];
        }
        break;
      case "fp_image":
        if (tradeMark.fp_image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.fp_image_name,
              file_type: tradeMark.fp_image_type,
              created_at: "",
            },
          ];
        }
        break;
      case "agent_survey":
        result = await TradeMarkAPI.getAgentSurveyFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_apply":
        result = await TradeMarkAPI.getAgenApplyFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_content":
        result = await TradeMarkAPI.getAgentOaContentFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_opinion":
        result = await TradeMarkAPI.getAgentOaOpinionFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_apply":
        result = await TradeMarkAPI.getAgentOaApplyFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_objection_content":
        result = await TradeMarkAPI.getAgentObjectionContentFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_objection_opinion":
        result = await TradeMarkAPI.getAgentObjectionOpinionFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_regist":
        result = await TradeMarkAPI.getAgentRegistFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_expiration":
        result = await TradeMarkAPI.getAgentRenewalExpirationFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_renewal":
        result = await TradeMarkAPI.getAgentRenewalFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_image":
        if (tradeMark.image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.image_name,
              file_type: tradeMark.image_type,
              created_at: "",
            },
          ];
        }
        break;
    }
    setFileViewList(result);
    setDownPopupShow(true);
  };
  function findCoexistenceDeadline(deadlineList) {
    for (let i = 0; i < deadlineList.length; i++) {
      if (deadlineList[i].desc === "존속기간마감일") {
        const date =
          Date.parse(
            window.$Global.convertRegistAtPlusDate(
              tradeMark.regist_at,
              tradeMark.renew_list.length
            )
          ) || "미등록";

        return { i, date };
      }
    }
    return null; // "존속기간마감일"을 찾지 못한 경우
  }

  function findCoexistenceDeadline2(deadlineList) {
    for (let i = 0; i < 30; i++) {
      if (deadlineList[i].desc === "존속기간마감일") {
        return i; // '존속납부기한'을 찾은 경우 해당 인덱스 반환
      }
    }
    return -1; // '존속납부기한'을 찾지 못한 경우 -1 반환
  }

  const onClickUpPopShow = async (type, oa_idx, category_num) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });
    setUpPopupShow(true);
    let result = [];
    if (isNewTrademark) {
      switch (type) {
        case "survey":
          result = tradeMark.fileList.survey;
          break;
        case "apply":
          result = tradeMark.fileList.apply;
          break;
        case "apply_file":
          result = tradeMark.fileList.apply_file;
          break;
        case "oa_declare":
          result = tradeMark.fileList.oa_declare;
          break;
        case "oa_declare_extension":
          result = tradeMark.fileList.oa_declare_extension;
          break;
        case "objection_content":
          result = tradeMark.fileList.objection_content;
          break;
        case "objection_opinion":
          result = tradeMark.fileList.objection_opinion;
          break;
        case "regist":
          result = tradeMark.fileList.regist;
          break;
        case "regist_file":
          result = tradeMark.fileList.regist_file;
          break;
        case "reject":
          result = tradeMark.fileList.reject;
          break;
        case "reject_deadline":
          result = tradeMark.fileList.reject_deadline;
          break;
        case "expiration":
          result = tradeMark.fileList.expiration;
          break;
        case "renewal":
          result = tradeMark.fileList.renewal;
          break;
        case "renew":
          result = tradeMark.renew_list[category_num].renew_file;
          break;
        case "renew_split":
          result = tradeMark.renew_list[category_num].renew_split_file;
          break;
        case "drop1":
          result = tradeMark.fileList.drop1;
          break;
        case "giveup1":
          result = tradeMark.fileList.giveup1;
          break;
        case "drop2":
          result = tradeMark.fileList.drop2;
          break;
        case "giveup2":
          result = tradeMark.fileList.giveup2;
          break;
        case "split":
          result = tradeMark.fileList.split;
          break;
        case "coexistence":
          result = tradeMark.fileList.coexistence;
          break;
        case "registration":
          result = tradeMark.fileList.registration;
          break;
        case "international":
          result = tradeMark.fileList.international;
          break;
        case "designated":
          result = tradeMark.fileList.designated;
          break;
        case "image":
          result = tradeMark.fileList.image;
          break;
        case "fp_image":
          result = tradeMark.fileList.fp_image;
          break;
        case "history":
          result = tradeMark.history_list[category_num].file;
          break;
        // OA 발행일 파일
        case "oa_content":
          result = tradeMark.oa[category_num].oa_content_file;
          break;
        // OA 제출 대응기한
        case "oa_opinion":
          result = tradeMark.oa[category_num].oa_opinion_file;
          break;
        //OA 제출 대응일
        case "oa_apply":
          result = tradeMark.oa[category_num].oa_apply_file;
          break;
        // 거절결정
        case "reject_exam_file1":
          result = tradeMark.fileList.reject_exam_file1;
          break;
        case "reject_exam_file2":
          result = tradeMark.fileList.reject_exam_file2;
          break;
        case "reject_exam_file3":
          result = tradeMark.fileList.reject_exam_file3;
          break;
        case "refusal_decision_file1":
          result = tradeMark.fileList.refusal_decision_file1;
          break;
        case "refusal_decision_file2":
          result = tradeMark.fileList.refusal_decision_file2;
          break;
        case "refusal_decision_file3":
          result = tradeMark.fileList.refusal_decision_file3;
          break;
        case "trial_decision_patent_file1":
          result = tradeMark.fileList.trial_decision_patent_file1;
          break;
        case "trial_decision_patent_file2":
          result = tradeMark.fileList.trial_decision_patent_file2;
          break;
        case "trial_decision_patent_file3":
          result = tradeMark.fileList.trial_decision_patent_file3;
          break;
        case "trial_decision_supreme_file1":
          result = tradeMark.fileList.trial_decision_supreme_file1;
          break;
        case "trial_decision_supreme_file2":
          result = tradeMark.fileList.trial_decision_supreme_file2;
          break;
        case "is_case_file1":
          result = tradeMark.fileList.is_case_file1;
          break;
        case "case_reversed_file1":
          result = tradeMark.fileList.case_reversed_file1;
          break;
        // 거절결정 > 재심사 oa
        case "reject_exam_content_file":
          result =
            tradeMark.reject_exam_oa[category_num].reject_exam_content_file;
          break;
        case "reject_exam_apply_file":
          result =
            tradeMark.reject_exam_oa[category_num].reject_exam_apply_file;
          break;
        case "reject_exam_opinion_file":
          result =
            tradeMark.reject_exam_oa[category_num].reject_exam_opinion_file;
          break;

        case "refusal_decision_content_file":
          result =
            tradeMark.refusal_decision_oa[category_num]
              .refusal_decision_content_file;
          break;
        case "refusal_decision_apply_file":
          result =
            tradeMark.refusal_decision_oa[category_num]
              .refusal_decision_apply_file;
          break;
        case "refusal_decision_opinion_file":
          result =
            tradeMark.refusal_decision_oa[category_num]
              .refusal_decision_opinion_file;
          break;

        case "trial_decision_patent_content_file":
          result =
            tradeMark.trial_decision_patent_oa[category_num]
              .trial_decision_patent_content_file;
          break;
        case "trial_decision_patent_apply_file":
          result =
            tradeMark.trial_decision_patent_oa[category_num]
              .trial_decision_patent_apply_file;
          break;
        case "trial_decision_patent_opinion_file":
          result =
            tradeMark.trial_decision_patent_oa[category_num]
              .trial_decision_patent_opinion_file;
          break;

        case "case_reversed_content_file":
          result =
            tradeMark.case_reversed_oa[category_num].case_reversed_content_file;
          break;
        case "case_reversed_apply_file":
          result =
            tradeMark.case_reversed_oa[category_num].case_reversed_apply_file;
          break;
        case "case_reversed_opinion_file":
          result =
            tradeMark.case_reversed_oa[category_num].case_reversed_opinion_file;
          break;
      }
      setUploadFileViewList(result);
    } else {
      if (Number.isInteger(category_num)) {
        switch (type) {
          case "history":
            result = tradeMark.history_list[category_num].file;
            break;
        }
      }
      setUploadFileViewList(result);
    }
  };

  const updateFileCntTradeMark = () => {
    if (isCompany) {
      TradeMarkAPI.getTradeMarkInfo(trademark_idx).then(async (res) => {
        let data = res.data;
        let trade = {
          ...tradeMark,
          survey_file_cnt: data.survey_file_cnt,
          apply_file_cnt: data.apply_file_cnt,
          apply_retroactive_file_cnt: data.apply_retroactive_file_cnt,
          apply_priority_screen_file_cnt: data.apply_priority_screen_file_cnt,
          apply_priority_screen_decision_file_cnt:
            data.apply_priority_screen_decision_file_cnt,
          apply_decision_file_cnt: data.apply_decision_file_cnt,
          oa_content_file_cnt: data.oa_content_file_cnt,
          oa_opinion_file_cnt: data.oa_opinion_file_cnt,
          oa_apply_file_cnt: data.oa_apply_file_cnt,
          oa_declare_file_cnt: data.oa_declare_file_cnt,
          oa_declare_extension_file_cnt: data.oa_declare_extension_file_cnt,
          objection_content_file_cnt: data.objection_content_file_cnt,
          objection_opinion_file_cnt: data.objection_opinion_file_cnt,
          regist_file_cnt: data.regist_file_cnt,
          register_decision_file_cnt: data.register_decision_file_cnt,
          register_fee_deadline_file_cnt: data.register_fee_deadline_file_cnt,
          split_fee_deadline_file_cnt: data.split_fee_deadline_file_cnt,
          expiration_file_cnt: data.expiration_file_cnt,
          renewal_file_cnt: data.renewal_file_cnt,
          renew_file_cnt: data.renew_file_cnt,
          renew_split_file_cnt: data.renew_split_file_cnt,
          image_file_cnt: data.image_file_cnt,
          drop_receipt_file_cnt: data.drop_receipt_file_cnt,
          drop_decision_file_cnt: data.drop_decision_file_cnt,
          give_up_receipt_file_cnt: data.give_up_receipt_file_cnt,
          give_up_decision_file_cnt: data.give_up_decision_file_cnt,
          split_file_cnt: data.split_file_cnt,
          history_file_cnt: data.history_file_cnt,
          coexistence_file_cnt: data.coexistence_file_cnt,
          registration_file_cnt: data.registration_file_cnt,
          international_file_cnt: data.international_file_cnt,
          designated_file_cnt: data.designated_file_cnt,
          correction_file_cnt: data.correction_file_cnt,
          judge_file_cnt: data.judge_file_cnt,
          objection_file_cnt: data.objection_file_cnt,
          dispute_giveup_file_cnt: data.dispute_giveup_file_cnt,
          dispute_history_file_cnt: data.dispute_history_file_cnt,
          reject_file_cnt: data.reject_file_cnt,
          reject_decision_file_cnt: data.reject_decision_file_cnt, // 거절 결정 파일 개수
          reject_objection_file_cnt: data.reject_objection_file_cnt, // 거절불복기한파일 개수
          reject_re_examination_file_cnt: data.reject_re_examination_file_cnt, // 재심사청구일 파일 개수
          reject_re_examination_registration_decision_file_cnt:
            data.reject_re_examination_registration_decision_file_cnt, // 재심사등록여부결정일 파일 개수
          reject_re_examination_registration_decision_objection_file_cnt:
            data.reject_re_examination_registration_decision_objection_file_cnt, // 재심사등록여부불복기한파일 개수
          reject_refusal_decision_objection_file_cnt:
            data.reject_refusal_decision_objection_file_cnt, // 거절결정불복심판청구일 파일 개수
          reject_refusal_decision_objection_final_trial_file_cnt:
            data.reject_refusal_decision_objection_final_trial_file_cnt, // 거절결정불복심판최종심결일 파일 개수
          reject_refusal_decision_objection_final_trial_objection_file_cnt:
            data.reject_refusal_decision_objection_final_trial_objection_file_cnt, // 거절결정불복심판최종심결불복기한파일 개수
          trial_decision_revocation_suit_patent_court_file_cnt:
            data.trial_decision_revocation_suit_patent_court_file_cnt, // 심결취소 소송(특허법원) 청구일 파일 개수
          trial_decision_revocation_suit_patent_court_judgement_file_cnt:
            data.trial_decision_revocation_suit_patent_court_judgement_file_cnt, // 심결취소소송(특허법원)판결선고일파일 개수
          trial_decision_revocation_suit_patent_court_judgement_objection_file_cnt:
            data.trial_decision_revocation_suit_patent_court_judgement_objection_file_cnt, // 심결취소소송(특허법원)판결불복기한파일 개수
          trial_decision_revocation_suit_supreme_court_file_cnt:
            data.trial_decision_revocation_suit_supreme_court_file_cnt, // 심결취소소송(대법원)청구일파일 개수
          trial_decision_revocation_suit_supreme_court_judgement_file_cnt:
            data.trial_decision_revocation_suit_supreme_court_judgement_file_cnt, // 심결취소소송(대법원)판결선고일 파일 개수
          case_reversed_request_at_file_cnt:
            data.case_reversed_request_at_file_cnt, // 파기환송심(심판원) 청구일 파일 개수
          case_reversed_and_remanded_final_trial_file_cnt:
            data.case_reversed_and_remanded_final_trial_file_cnt, // 파기환송심(특허심판원)최종심결파일 개수
          image_key: data.image_key,
          fp_image_key: data.fp_image_key,
        };
        trade.oa = [...tradeMark.oa];
        setTradeMark(trade);
        setHistoryTradeMark(trade);
      });
    } else if (isAgent) {
      TradeMarkAPI.getTradeMarkInfoAgent(
        manage_company_idx,
        trademark_idx
      ).then(async (res) => {
        let data = res.data;
        setTradeMark(data);
      });
    }
  };
  const encodeFileToBase64 = (fileBlob, type) => {
    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    return new Promise((resolve) => {
      reader.onload = () => {
        if (type === "fp_image") {
          setFpFileImage(reader.result);
        } else {
          setFileImage(reader.result);
        }
        resolve();
      };
    });
  };

  const validatorTradeMarkImage = (list) => {
    if (list.length > 1) {
      alert("상표 이미지는 하나만 등록하실 수 있습니다");
      return false;
    }
    if (!img_type.test(list[0].file_type)) {
      alert("jpg, jpeg, gif, png 업로드하실 수 있습니다");
      return false;
    }
    return true;
  };

  const onChangeOA = (idx, key, value) => {
    let copy_oa = window.$Global.cloneObject(tradeMark.oa);
    let oa = Object.keys(copy_oa).map((key) => copy_oa[key]);
    if (key === "declareInfo") {
      return;
    } else {
      oa[idx][key] = value;
    }
    setTradeMark({ ...tradeMark, oa: oa });
  };

  const handleCopy = () => {
    const textToCopy = copyText.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    alert("복사되었습니다.");
  };

  const onClickSelectClass = (td_class) => {
    setProductClassNo(td_class);
    setSelectPopupShow(true);
  };

  const onClickConfirm = (arr) => {
    let product_class = arr.map((item) => {
      return {
        class: productClassNo,
        similar_group: "",
        product: item.text,
        product_en: "",
        product_local: "",
        correction: 0,
        core: 0,
        cancel: 0,
        cancel_event_idx: "",
        cancel_event_number: "",
        parent_idx: item.idx,
        depth: 0,
        calibration_list: [],
      };
    });
    setTradeMark({
      ...tradeMark,
      product_class: tradeMark.product_class.concat(product_class),
    });
    setClassificationPopupShow(false);
    setSelectPopupShow(false);
  };

  const createOA = () => {
    if (isNewTrademark) {
      tradeMark.oa.push({
        published_at: 0, // 발행일
        opinion_upload_at: 0, // 의견서 접수일
        deadline: 0, // oa 기한
        apply_decision_at: 0, // 출원공고 결정일
        apply_at: 0, // 출원 공고일
        objection_deadline: 0, // 제출,대응 기한
        submission_at: 0, // 제출,대응 일
        extension_cnt: 0, // 연장 횟수
        is_give_up: 0, // 대응포기여부
        procedural_refusal_reason: 0, //절차적 거절이유
        absolute_refusal_reason: 0, // 절대적 거절이유
        relative_refusal_reason: 0, // 상대적 거절이유
        oa_apply_file: [],
        oa_content_file: [],
        oa_opinion_file: [],
      });
      setTradeMark({ ...tradeMark });
      setShowAddList(false);
    } else {
      TradeMarkAPI.createOa(trademark_idx).then((res) => {
        tradeMark.oa.push({
          idx: res.data.oa_idx,
          published_at: 0, // 발행일
          opinion_upload_at: 0, // 의견서 접수일
          deadline: 0, // oa 기한
          apply_decision_at: 0, // 출원공고 결정일
          apply_at: 0, // 출원 공고일
          objection_deadline: 0, // 제출,대응 기한
          submission_at: 0, // 제출,대응 일
          extension_cnt: 0, // 연장 횟수
          is_give_up: 0, // 대응포기여부
          procedural_refusal_reason: 0, //절차적 거절이유
          absolute_refusal_reason: 0, // 절대적 거절이유
          relative_refusal_reason: 0, // 상대적 거절이유
        });
        setTradeMark({ ...tradeMark });
      });
      setShowAddList(false);
    }
  };

  const deleteOA = (idx) => {
    if (isNewTrademark) {
      tradeMark.oa.splice(idx, 1);
      setTradeMark({ ...tradeMark });
    } else {
      TradeMarkAPI.deleteOA(trademark_idx, idx).then(() => {
        alert("삭제되었습니다.");
        let arr = [];
        arr = tradeMark.oa.filter((item) => item.idx !== idx);
        setTradeMark({ ...tradeMark, oa: arr });
      });
    }
  };

  const deleteCategory = (type) => {
    tradeMark[`show_${type}`] = 0;
    setTradeMark({ ...tradeMark });
  };

  const addHistoryList = () => {
    const history = {
      title: "",
      work_at: 0,
      content: "",
      instruction_deadline: 0,
      legal_deadline: 0,
      etc: "",
      isEdit: true,
      file: [],
    };
    tradeMark.history_list = [...tradeMark.history_list, history];

    setTradeMark({ ...tradeMark });
  };

  const handleHistoryInput = (e, idx) => {
    tradeMark.history_list[idx][e.target.name] = e.target.value;

    setTradeMark({ ...tradeMark });
  };

  const handleHistoryDelete = (idx) => {
    tradeMark.history_list.splice(idx, 1);
    alert("삭제되었습니다.");
    setTradeMark({ ...tradeMark });
  };

  const handleRenewDelete = (idx) => {
    tradeMark.renew_list.splice(idx, 1);
    alert("삭제되었습니다.");
    setTradeMark({ ...tradeMark });
  };
  const handleProductClass = (idx) => {
    tradeMark.product_class.splice(idx, 1);
    alert("삭제되었습니다.");

    setTradeMark({ ...tradeMark });
  };

  // 지정상품 텍스트 추출 API 호출하는 함수
  const productClassSummary = () => {
    TradeMarkAPI.getProductClassSummary({
      ...summaryType,
      product_class: tradeMark.product_class,
    }).then((res) => {
      const originalSummary = res.data;
      const modifiedSummary = originalSummary.replace(
        /◈(\d+)◈\s([^◈]+)\s/g,
        "[$1] $2 "
      );

      // 들여쓰기를 추가한 문자열을 생성
      const indentedSummary = modifiedSummary
        .split("\n")
        .map((line) => "    " + line)
        .join("\n");

      setProductSummary(indentedSummary);
    });
  };

  // 지정상품 보정여부 클릭시 calibration 컨트롤하는 함수
  const handleCalibrationList = (item, type, child_item) => {
    if (type === "push") {
      item.calibration_list.push({
        idx: "",
        class: item.class,
        similar_group: "",
        product: item.product,
        product_en: "",
        product_local: "",
        correction: 0,
        core: 0,
        cancel: 0,
        cancel_event_idx: "",
        cancel_event_number: "",
        cancel_decision_at: 0,
        parent_idx: item.idx,
        depth: child_item ? child_item.idx + 1 : 0,
      });
    } else if (type === "parent_delete") {
      item.calibration_list = [];
    } else if (type === "child_delete") {
      item.calibration_list.splice(child_item.depth + 1, 1);
    }
  };

  // 등록 및 갱신에서 갱신 추가를 누를때 실행되는 함수
  const addRenewalList = () => {
    if (isNewTrademark) {
      tradeMark.renew_list.push({
        number: tradeMark.renew_list.length,
        renew_at: 0,
        terminated_at: 0,
        payment_available_at: 0,
        payment_deadline: 0,
        is_split_payment: 0,
        split_payment_deadline: 0,
        is_secondary_registration: 0,
        is_paid_renew_fee: 0,
        is_paid_renew_split_fee: 0,
        renew_file: [],
        renew_split_file: [],
      });
      setTradeMark({ ...tradeMark });
    } else {
      TradeMarkAPI.addTradeMarkRenewal(trademark_idx).then(() => {
        TradeMarkAPI.getTradeMarkRenewal(trademark_idx).then((res) => {
          tradeMark.renew_list = res.data.items;
          setTradeMark({ ...tradeMark });
        });
      });
    }
  };

  const addMiddleEventItem = (type) => {
    let obj = {
      published_at: 0,
      submission_deadline: 0,
      response_at: 0,
      is_give_up: 0,
      type: type,
      is_submission_deadline_done: 0,
    };

    alert("추가되었습니다.");

    if (isNewTrademark) {
      switch (type) {
        case 0:
          tradeMark.reject_exam_oa.push({
            ...obj,
            reject_exam_content_file: [],
            reject_exam_apply_file: [],
            reject_exam_opinion_file: [],
          });
          break;
        case 1:
          tradeMark.refusal_decision_oa.push({
            ...obj,
            refusal_decision_content_file: [],
            refusal_decision_apply_file: [],
            refusal_decision_opinion_file: [],
          });
          break;
        case 2:
          tradeMark.trial_decision_patent_oa.push({
            ...obj,
            trial_decision_patent_content_file: [],
            trial_decision_patent_apply_file: [],
            trial_decision_patent_opinion_file: [],
          });
          break;
        case 3:
          tradeMark.case_reversed_oa.push({
            ...obj,
            case_reversed_content_file: [],
            case_reversed_apply_file: [],
            case_reversed_opinion_file: [],
          });
          break;
      }
    } else {
      switch (type) {
        case 0:
          TradeMarkAPI.uploadTradeMarkReExamination(trademark_idx, {
            ...obj,
          }).then(async () => {
            tradeMark.reject_exam_oa =
              await TradeMarkAPI.getTradeMarkReExamination(trademark_idx).then(
                (res) => res.data
              );
            setTradeMark({ ...tradeMark });
          });
          break;
        case 1:
          TradeMarkAPI.uploadTradeMarkRefusalDecision(trademark_idx, {
            ...obj,
          }).then(async () => {
            tradeMark.refusal_decision_oa =
              await TradeMarkAPI.getTradeMarkRefusalDecision(
                trademark_idx
              ).then((res) => res.data);
            setTradeMark({ ...tradeMark });
          });
          break;
        case 2:
          TradeMarkAPI.uploadTradeMarkTrialDecision(trademark_idx, {
            ...obj,
          }).then(async () => {
            tradeMark.trial_decision_patent_oa =
              await TradeMarkAPI.getTradeMarkTrialDecision(trademark_idx).then(
                (res) => res.data
              );
            setTradeMark({ ...tradeMark });
          });
          break;
        case 3:
          TradeMarkAPI.uploadTradeMarkCaseReversed(trademark_idx, {
            ...obj,
          }).then(async () => {
            tradeMark.case_reversed_oa =
              await TradeMarkAPI.getTradeMarkCaseReversed(trademark_idx).then(
                (res) => res.data
              );
            setTradeMark({ ...tradeMark });
          });
          break;
      }
    }
    setTradeMark({ ...tradeMark });
  };

  const deleteMiddleEventItem = (item, idx, type) => {
    if (item.idx) {
      let arr = [];
      switch (type) {
        case 0:
          TradeMarkAPI.deleteTradeMarkReExamination(
            trademark_idx,
            item.idx
          ).then(() => {
            arr = tradeMark.reject_exam_oa.filter((el) => el.idx !== item.idx);
            setTradeMark({ ...tradeMark, reject_exam_oa: arr });
          });
          break;
        case 1:
          TradeMarkAPI.deleteTradeMarkRefusalDecision(
            trademark_idx,
            item.idx
          ).then(() => {
            arr = tradeMark.refusal_decision_oa.filter(
              (el) => el.idx !== item.idx
            );
            setTradeMark({ ...tradeMark, refusal_decision_oa: arr });
          });
          break;
        case 2:
          TradeMarkAPI.deleteTradeMarkTrialDecision(
            trademark_idx,
            item.idx
          ).then(() => {
            arr = tradeMark.trial_decision_patent_oa.filter(
              (el) => el.idx !== item.idx
            );
            setTradeMark({ ...tradeMark, trial_decision_patent_oa: arr });
          });
          break;
        case 3:
          TradeMarkAPI.deleteTradeMarkCaseReversed(
            trademark_idx,
            item.idx
          ).then(() => {
            arr = tradeMark.case_reversed_oa.filter(
              (el) => el.idx !== item.idx
            );
            setTradeMark({ ...tradeMark, case_reversed_oa: arr });
          });
          break;
      }
    } else {
      switch (type) {
        case 0:
          tradeMark.reject_exam_oa.splice(idx, 1);
          break;
        case 1:
          tradeMark.refusal_decision_oa.splice(idx, 1);
          break;
        case 2:
          tradeMark.trial_decision_patent_oa.splice(idx, 1);
          break;
        case 3:
          tradeMark.case_reversed_oa.splice(idx, 1);
          break;
      }
      setTradeMark({ ...tradeMark });
    }
  };
  const handleToggleExpand = () => {
    setfolding(!folding);
  };
  const handleMoveTimeLine = () => {
    const timelineField = document.getElementById("timelineField");
    if (timelineField) {
      timelineField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveInfo = () => {
    const infoField = document.getElementById("infoField");
    if (infoField) {
      infoField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveMemo = () => {
    const memoField = document.getElementById("memoField");
    if (memoField) {
      memoField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveApply = () => {
    const applyField = document.getElementById("applyField");
    if (applyField) {
      applyField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  const handleMoveProduct = () => {
    const productField = document.getElementById("productField");
    if (productField) {
      productField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  const handleMoveOa = (idx) => {
    const oaField = document.getElementById(`oaField_${idx}`);
    if (oaField) {
      oaField.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMoveObject = () => {
    const objectField = document.getElementById("objectField");
    if (objectField) {
      objectField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveWithdraw = () => {
    const withdrawField = document.getElementById("withdrawField");
    if (withdrawField) {
      withdrawField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveGiveup = () => {
    const giveupField = document.getElementById("giveupField");
    if (giveupField) {
      giveupField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  const handleMoveReject = () => {
    const rejectField = document.getElementById("rejectField");
    if (rejectField) {
      rejectField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  const handleMoveRegist = () => {
    const registField = document.getElementById("registField");
    if (registField) {
      registField.scrollIntoView({ behaivor: "smooth" });
    }
  };
  const handleMoveErase = () => {
    const eraseField = document.getElementById("eraseField");
    if (eraseField) {
      eraseField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  const handleMoveHistory = () => {
    const historyField = document.getElementById("historyField");
    if (historyField) {
      historyField.scrollIntoView({ behaivor: "smooth" });
    }
  };

  // Modal Logic
  const historyModalRef = useRef();
  const openHistoryModalHandler = () => {
    historyModalRef.current.open();
    document.body.style.overflow = "hidden";
  };
  const closeHistoryModalHandler = () => {
    historyModalRef.current.close();
    document.body.style.overflow = "initial";
  };

  // Replies Logic

  const [openRepliesParentIds, setOpenRepliesParentIds] = useState([]);
  const [toEditReply, setToEditReply] = useState(null);

  const updateOpenReplies = (idx) => {
    setOpenRepliesParentIds((preOpened) =>
      preOpened.includes(idx)
        ? preOpened.filter((id) => id !== idx)
        : [...preOpened, idx]
    );
  };

  const onHistoryModalSubmit = (formData, type) => {
    const commonFields = {
      title: formData.job_title,
      work_at: new Date(formData.work_at).getTime(),
      content: formData.comments,
      etc: "",
      is_important: formData.important ? 1 : 0,
    };

    let newHistoryList = [];

    if (type === "HISTORY") {
      // History Submit
      const newHistoryRecord = {
        ...commonFields,
        isEdit: true,
        ...(typeof historyPopupIdx !== "number" && {
          child: [],
          sub_history: [],
          file: formData.attachments,
        }),
        legal_deadline: new Date(formData.legal_deadline).getTime(),
        instruction_deadline: new Date(formData.instruction_deadline).getTime(),
      };

      if (typeof historyPopupIdx === "number") {
        // Edit Operation
        tradeMark.history_list[historyPopupIdx] = {
          ...tradeMark.history_list[historyPopupIdx],
          ...newHistoryRecord,
        };
      }

      newHistoryList =
        typeof historyPopupIdx === "number"
          ? tradeMark.history_list
          : [...tradeMark.history_list, newHistoryRecord];
    } else {
      // Reply Submit
      const newReplyRecord = {
        ...commonFields,
        ...(!toEditReply && { file: formData.attachments }),
        is_instruction_deadline_done:
          formData.instruction_type === "agreedUpon" ? true : false,
        is_legal_deadline_done:
          formData.instruction_type === "legal" ? true : false,
        parent_idx: formData.parent_idx,
      };

      if (toEditReply) {
        // Edit Reply Operation
        const childrenCopy = [...tradeMark.history_list[historyPopupIdx].child];
        childrenCopy.splice(toEditReply.replyIndex, 1, {
          ...toEditReply,
          ...newReplyRecord,
        });

        newHistoryList = tradeMark.history_list.map((historyRecord, index) =>
          index === historyPopupIdx
            ? { ...historyRecord, child: childrenCopy }
            : historyRecord
        );
      } else {
        newHistoryList = tradeMark.history_list.map((historyRecord, index) =>
          index === historyPopupIdx
            ? {
                ...historyRecord,
                child: [...historyRecord.child, newReplyRecord],
              }
            : historyRecord
        );
      }
    }

    setTradeMark({ ...tradeMark, history_list: newHistoryList });
    closeHistoryModalHandler();
  };

  let props_info = {
    tradeMark,
    setTradeMark,
    isNewTrademark,
    isCompany,
    managerSelectTag,
    stateDepth,
    setStateDepth,
    stateList,
    secondStateOption,
    agentListSelectTag,
    setEventPopupType,
    setEventPopupShow,
    productSelect,
    selectTagName,
    applicantListTag,
    rightHolderListTag,
    onClickDownPopShow,
    onClickUpPopShow,
    setIsProductionPopupClick,
    setIsNationPopupClick,
    fileImage,
    fpFileImage,
    firstDeadline,
    madridBasicManage,
    setNationPopupShow,
    setNationPopupType,
    initTradeMarkInfo,
    onClickSave,
    handleEditing,
    setSearchPopupClick,
  };

  let props_apply = {
    tradeMark,
    setTradeMark,
    deleteCategory,
    onClickDownPopShow,
    onClickUpPopShow,
    setCategoryNumber,
    setEventPopupType,
    setEventPopupShow,
    onClickSave,
    handleEditing,
    isNewTrademark,
    buttonName,
  };

  let props_reject = {
    tradeMark,
    setTradeMark,
    isNewTrademark,
    onClickDownPopShow,
    onClickUpPopShow,
    addMiddleEventItem,
    deleteMiddleEventItem,
    setSecondIdx,
    selectTagName,
    judgmentStateListTag,
    deleteCategory,
    onClickSave,
    initTradeMarkInfo,
    handleEditing,
    buttonName,
  };

  return (
    <TradeMarkContext.Provider value={tradeMark}>
      <TradeMarkFileTypeContext.Provider value={fileType}>
        <div id="TradeMarkDetail">
          <div className="header">
            <h2 className="title">
              {window.$Global.convertTradeMarkNationCodeToStr(tradeMark.nation)}
              {isNewTrademark
                ? " 상표 출원 등록 페이지"
                : " 상표 출원 상세 페이지"}
            </h2>
            {!isNewTrademark && (
              <div className="option">
                <ul className="link_tab">
                  {isCompany && (
                    <li>
                      <Link
                        className={`payment ${
                          tradeMark.regist_at ? "" : "no_click"
                        }`}
                        to={`/annual/manage/${tradeMark.idx}?nation=${
                          tradeMark.nation_string || ""
                        }&manage_number=${
                          tradeMark.manage_number || ""
                        }&register_number=${
                          tradeMark.register_number || ""
                        }&deadline_at=${
                          tradeMark.apply_at
                            ? new Date(tradeMark.apply_at).getFullYear() + 1000
                            : ""
                        }&type=tradeMark`}
                      >
                        <h2 className="title">연차료 관리</h2>
                        <i className="icon_patent_payment" />
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to={`/tradeMark/family/${trademark_idx}`}>
                      <h2 className="title">Family</h2>
                      <i className="icon_home" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/tradeMark/etc/${trademark_idx}`}>
                      <h2 className="title">기타 관련 서류</h2>
                      <i className="icon_attached_file" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: `/tradeMark/dispute/${trademark_idx}`,
                        state: {
                          tradeMarkData: { ...tradeMark, setTradeMark: null },
                          tradeMarkFileType: { ...fileType },
                        },
                      }}
                    >
                      <h2 className="title">분쟁(심판/소송)</h2>
                      <i className="icon_pencil" />
                    </Link>
                  </li>
                  <li>
                    <Link className="no_click">
                      <h2 className="title">기타사건(계약)</h2>
                      <i className="icon_attached_file" />
                    </Link>
                  </li>
                  <li>
                    <Link className="no_click">
                      <h2 className="title">조사(선행상표)</h2>
                      <i className="icon_attached_file" />
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {!isNewTrademark && (
              <div className={`sideBar ${folding ? "folding" : "unfodling"}`}>
                <div className="header_info">
                  <div className="title">
                    <div className="content">
                      {folding && !foldingtitle && (
                        <>
                          {window.$Global.convertTradeMarkNationCodeToStr(
                            tradeMark.nation
                          )}
                          {isNewTrademark
                            ? " 상표 출원 등록 페이지"
                            : " 상표 출원 상세 페이지"}
                        </>
                      )}
                    </div>
                    <div className="icon" onClick={handleToggleExpand}>
                      <i
                        className={`icon ${
                          folding
                            ? "icon_more_arrow_left_small_black"
                            : "icon_double_arrow_right"
                        }`}
                      />
                    </div>
                  </div>

                  {folding && !foldingtitle && (
                    <div
                      className={`header ${foldingMain ? "foldingicon" : ""}`}
                    >
                      <img
                        src={
                          require("../../assets/images/common/bookmark.png")
                            .default
                        }
                        alt="이미지"
                      />
                      Outline
                      <i
                        className={`icons ${
                          foldingMain
                            ? "icon_more_arrow_left_small_black"
                            : "icon_arrow_down_gray"
                        }`}
                        onClick={() => setFoldingMain(!foldingMain)}
                      />
                    </div>
                  )}
                  {folding && !foldingMain && (
                    <div className="header_sub">
                      <button onClick={handleMoveTimeLine}>TimeLine</button>
                      <button onClick={handleMoveInfo}>서지사항</button>
                      {tradeMark.show_memo !== 0 && (
                        <button onClick={handleMoveMemo}>메모</button>
                      )}
                      {tradeMark.show_apply !== 0 && (
                        <button onClick={handleMoveApply}>출원</button>
                      )}
                      {tradeMark.show_product_list !== 0 && (
                        <button onClick={handleMoveProduct}>지정 상품</button>
                      )}
                      <div id="oaField">
                        {tradeMark.oa.length !== 0 &&
                          tradeMark.oa.map((item, index) => (
                            <button
                              key={index}
                              onClick={() => handleMoveOa(index)}
                            >
                              중간사건 {index + 1}
                            </button>
                          ))}
                      </div>
                      {tradeMark.show_objection !== 0 && (
                        <button onClick={handleMoveObject}>이의신청</button>
                      )}
                      {tradeMark.show_drop !== 0 && (
                        <button onClick={handleMoveWithdraw}>취하</button>
                      )}
                      {tradeMark.show_give_up !== 0 && (
                        <button onClick={handleMoveGiveup}>포기</button>
                      )}
                      {tradeMark.show_reject !== 0 && (
                        <button onClick={handleMoveReject}>거절 결정</button>
                      )}
                      {tradeMark.show_regist !== 0 && (
                        <button onClick={handleMoveRegist}>등록</button>
                      )}
                      {tradeMark.show_erase !== 0 && (
                        <button onClick={handleMoveErase}>말소</button>
                      )}
                      <button onClick={handleMoveHistory}>history</button>
                    </div>
                  )}
                  {folding && !foldingtitle && (
                    <div
                      className={`middle ${foldingMiddle ? "foldingicon" : ""}`}
                    >
                      <img
                        src={
                          require("../../assets/images/common/ipnowimportant.png")
                            .default
                        }
                        alt="이미지"
                      />
                      Information
                      <i
                        className={
                          foldingMiddle
                            ? "icon_more_arrow_left_small_black "
                            : "icon_arrow_down_gray"
                        }
                        onClick={() => setFoldingMiddle(!foldingMiddle)}
                      />
                    </div>
                  )}
                  {folding && !foldingMiddle && (
                    <div className="middle_sub">
                      <p>
                        <span>지역</span>{" "}
                        <span>
                          {" "}
                          {window.$Global.convertNationName(tradeMark.nation)}
                        </span>
                      </p>
                      <p>
                        <span>현 상태</span>
                        <span>
                          {window.$Global.convertTradeMarkStateCodeToStr(
                            tradeMark.state
                          )}
                        </span>
                      </p>
                      <p>
                        <span>상품분류</span>
                        {tradeMark.product_class.length >= 0 ? (
                          [
                            ...new Set(
                              tradeMark.product_class.map((item) => item.class)
                            ),
                          ].map((itemClass, index) => (
                            <span key={index}>{`제 ${itemClass}류`}</span>
                          ))
                        ) : (
                          <span>미선택</span>
                        )}
                      </p>
                      <p>
                        <span>상표명칭</span>
                        <span>{tradeMark.name}</span>
                      </p>
                      <p>
                        <span>출원번호</span>
                        <span>{tradeMark.apply_number}</span>
                      </p>
                      <p>
                        <span>출원일자</span>
                        <span>
                          {window.$Global.convertDate(tradeMark.apply_at)}{" "}
                        </span>
                      </p>

                      <p>
                        <span>등록번호</span>
                        <span>{tradeMark.regist_number}</span>
                      </p>
                      <p>
                        <span>등록일자</span>
                        <span>
                          {window.$Global.convertDate(tradeMark.regist_at)}
                        </span>
                      </p>
                      <p>
                        <span>출원인</span>
                        <span>{tradeMark.applicant_name}</span>
                      </p>
                    </div>
                  )}
                  {tradeMark.image_key !== null && (
                    <div>
                      {folding && !foldingtitle && (
                        <div
                          className={`end ${foldingEnd ? "foldingicon" : ""}`}
                        >
                          <img
                            src={
                              require("../../assets/images/common/ipnowgallery.png")
                                .default
                            }
                            alt="이미지"
                          />
                          <span>image</span>

                          <i
                            className={
                              foldingEnd
                                ? "icon_more_arrow_left_small_black "
                                : "icon_arrow_down_gray"
                            }
                            onClick={() => setFoldingEnd(!foldingEnd)}
                          />
                        </div>
                      )}

                      {folding && !foldingEnd && (
                        <div className="end_sub">
                          {Boolean(tradeMark.image_key) ? (
                            tradeMark.image_type == "svg" ? (
                              <span>미리보기를 지원하지 않는 파일형식</span>
                            ) : (
                              <img
                                src={window.$Global.getCDN(tradeMark.image_key)}
                                alt="이미지"
                                onError={(error) => console.log(error)}
                                onClick={() =>
                                  window.$Global.handleCopyImage(
                                    window.$Global.getCDN(tradeMark.image_key)
                                  )
                                }
                              />
                            )
                          ) : (
                            fileImage && <img src={fileImage} alt="sample" />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* 사이드 바 버튼들 */}
            <div className="side_btns">
              {isNewTrademark ? (
                <>
                  <button className="btn_save" onClick={onClickAdd}>
                    등록
                  </button>
                  <div>
                    <button
                      className="btn_add"
                      onClick={() => setShowSideAddList(!showSideAddList)}
                    >
                      항목 추가하기
                    </button>
                    {showSideAddList && (
                      <ul className="side_category_list">
                        {categoryList.map((item, index) => {
                          if (item.name.includes("지정상품")) {
                            return (
                              <>
                                <li
                                  key={index}
                                  onClick={() => addCategory(item.type)}
                                  style={{
                                    backgroundColor:
                                      tradeMark[item.type] === 1 && "skyblue",
                                  }}
                                >
                                  {item.name}
                                </li>
                                <li onClick={createOA}>중간사건</li>
                              </>
                            );
                          } else {
                            return (
                              <li
                                key={index}
                                onClick={() => addCategory(item.type)}
                                style={{
                                  backgroundColor:
                                    tradeMark[item.type] === 1 && "skyblue",
                                }}
                              >
                                {item.name}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                </>
              ) : (
                !isNewTrademark && (
                  <>
                    <div>
                      <button
                        className="btn_add"
                        onClick={() => setShowSideAddList(!showSideAddList)}
                      >
                        항목 추가하기
                      </button>
                      {showSideAddList && (
                        <ul className="category_list">
                          {categoryList.map((item, index) => {
                            if (item.name.includes("지정상품")) {
                              return (
                                <>
                                  <li
                                    key={index}
                                    onClick={() => addCategory(item.type)}
                                    style={{
                                      backgroundColor:
                                        tradeMark[item.type] === 1 && "skyblue",
                                    }}
                                  >
                                    {item.name}
                                  </li>
                                  <li onClick={createOA}>중간사건</li>
                                </>
                              );
                            } else {
                              return (
                                <li
                                  key={index}
                                  onClick={() => addCategory(item.type)}
                                  style={{
                                    backgroundColor:
                                      tradeMark[item.type] === 1 && "skyblue",
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      )}
                    </div>
                    <button className="btn_edit" onClick={() => handledelete()}>
                      삭제
                    </button>
                    <button
                      className="btn_save"
                      onClick={() => {
                        // 처음 클릭되었을 때는 onClickSave 실행하지 않음
                        if (buttonName === "항목 삭제") {
                          fieldCancel();
                        } else {
                          // 버튼의 이름이 "삭제"로 변경된 이후에만 onClickSave 실행
                          onClickSave();
                          setButtonName("항목 삭제");
                        }
                      }}
                    >
                      {buttonName}
                    </button>
                  </>
                )
              )}
            </div>
            {/* 상단 버튼들 */}
            <div className="btns">
              {isNewTrademark ? (
                <>
                  <button className="btn_save" onClick={onClickAdd}>
                    등록
                  </button>
                  <div>
                    <button
                      className="btn_add"
                      onClick={() => setShowAddList(!showAddList)}
                    >
                      항목 추가하기
                    </button>
                    {showAddList && (
                      <ul className="category_list">
                        {categoryList.map((item, index) => {
                          if (item.name.includes("지정상품")) {
                            return (
                              <>
                                <li
                                  key={index}
                                  onClick={() => addCategory(item.type)}
                                  style={{
                                    backgroundColor:
                                      tradeMark[item.type] === 1 && "skyblue",
                                  }}
                                >
                                  {item.name}
                                </li>
                                <li onClick={createOA}>중간사건</li>
                              </>
                            );
                          } else {
                            return (
                              <li
                                key={index}
                                onClick={() => addCategory(item.type)}
                                style={{
                                  backgroundColor:
                                    tradeMark[item.type] === 1 && "skyblue",
                                }}
                              >
                                {item.name}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                </>
              ) : !tradeMark.editing ? (
                <>
                  <div>
                    <button
                      className="btn_add"
                      onClick={() => setShowAddList(!showAddList)}
                    >
                      항목 추가하기
                    </button>
                    {showAddList && (
                      <ul className="category_list">
                        {categoryList.map((item, index) => {
                          if (item.name.includes("지정상품")) {
                            return (
                              <>
                                <li
                                  key={index}
                                  onClick={() => addCategory(item.type)}
                                  style={{
                                    backgroundColor:
                                      tradeMark[item.type] === 1 && "skyblue",
                                  }}
                                >
                                  {item.name}
                                </li>
                                <li onClick={createOA}>중간사건</li>
                              </>
                            );
                          } else {
                            return (
                              <li
                                key={index}
                                onClick={() => addCategory(item.type)}
                                style={{
                                  backgroundColor:
                                    tradeMark[item.type] === 1 && "skyblue",
                                }}
                              >
                                {item.name}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                </>
              ) : (
                <button
                  className="btn_edit"
                  onClick={() => handleEditing("edit")}
                ></button>
              )}

              {!isNewTrademark && (
                <button className="btn_edit" onClick={() => handledelete()}>
                  삭제
                </button>
              )}
              {!isNewTrademark && (
                <button
                  className="btn_save"
                  onClick={() => {
                    // 처음 클릭되었을 때는 onClickSave 실행하지 않음
                    if (buttonName === "항목 삭제") {
                      fieldCancel();
                    } else {
                      // 버튼의 이름이 "삭제"로 변경된 이후에만 onClickSave 실행
                      onClickSave();
                      setButtonName("항목 삭제");
                    }
                  }}
                >
                  {buttonName}
                </button>
              )}
            </div>
          </div>

          <div className={`form ${downloadable ? "" : "not_download"}`}>
            {/* TIMELINE 완료 */}
            {!isNewTrademark && (
              <div className="field" id="timelineField">
                <div className="field_header">
                  <h5 className="title">TimeLine</h5>
                </div>
                <div className="timeline">
                  <ul>
                    <li>
                      <div className="dot" />
                      <p>출원일</p>
                      <span>
                        {window.$Global.convertDate(
                          tradeMark?.time_line?.apply_at
                        ) || "미등록"}
                      </span>
                    </li>
                    <li>
                      <div className="dot" />
                      <p>공고일</p>
                      <span>
                        {window.$Global.convertDate(
                          tradeMark?.time_line?.open_at
                        ) || "미등록"}
                      </span>
                    </li>
                    <li>
                      <div className="dot" />
                      <p>이의신청마감일</p>
                      <span>
                        {window.$Global.convertDate(
                          tradeMark?.time_line?.objection_deadline
                        ) || "미등록"}
                      </span>
                    </li>
                    <li>
                      <div className="dot" />
                      <p>등록일</p>
                      <span>
                        {window.$Global.convertDate(
                          tradeMark?.time_line?.regist_at
                        ) || "미등록"}
                      </span>
                    </li>
                    <li>
                      <div className="dot" />
                      <p>존속기간만료일</p>
                      <span>
                        {window.$Global.convertDate(
                          tradeMark.depend_deadline
                        ) || "미등록"}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="due_wrapper">
                  <div>
                    <p>Earliest Due</p>
                    <p>
                      {firstDeadline?.date > 0
                        ? window.$Global.convertDate(firstDeadline.date) +
                          " " +
                          window.$Global.getFullDday(firstDeadline.date)
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <p>Due Type</p>
                    <p>{firstDeadline?.desc || "N/A"}</p>
                  </div>
                </div>
              </div>
            )}

            {/* 서지사항 컴포넌트 */}
            {tradeMark.nation === 0 ? (
              <FormInfoKR {...props_info} />
            ) : (
              <FormInfoOverseas {...props_info} />
            )}

            {tradeMark.show_memo ? (
              // 메모 컴포넌트
              <div
                id="memoField"
                className={`field ${
                  !isNewTrademark && tradeMark.memoEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingMemo
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingMemo(!foldingMemo)}
                    />
                    메모
                  </h5>

                  {!foldingMemo && (
                    <>
                      {!isNewTrademark && tradeMark.memoEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                console.log(tradeMark.memo);
                                handleEditing("memoCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.memoEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("memoEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.memoEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("memo")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>

                {!foldingMemo && (
                  <textarea
                    placeholder="해당 특허의 중요내용에 대해 기록해 놓으시고, 추후 활용하세요."
                    value={tradeMark.memo?.replace(
                      /(<br>|<br\/>|<br \/>)/g,
                      "\r\n"
                    )}
                    onChange={(e) =>
                      setTradeMark({ ...tradeMark, memo: e.target.value })
                    }
                    autoFocus
                    readOnly={!tradeMark.memoEditing}
                  />
                )}
              </div>
            ) : null}
            {tradeMark.show_apply ? (
              tradeMark.nation === 0 ? (
                <FormApplyKR {...props_apply} />
              ) : (
                <FormApplyOverseas {...props_apply} />
              )
            ) : null}
            {tradeMark.show_product_list ? (
              <div
                id="productField"
                className={`field ${
                  !isNewTrademark && tradeMark.productEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingProductList
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingProductList(!foldingProductList)}
                    />
                    지정상품
                    <span style={{ marginLeft: "5px", color: "#4593f5" }}>
                      ({`총 ${tradeMark.product_class.length || 0}개 `})
                    </span>
                  </h5>
                  <div>
                    {!foldingProductList && (
                      <>
                        {!isNewTrademark && tradeMark.productEditing ? (
                          <>
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("productCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </>
                        ) : (
                          !isNewTrademark &&
                          !tradeMark.productEditing &&
                          buttonName === "항목 삭제" && (
                            <button
                              className="btn_edit"
                              onClick={() => handleEditing("productEditing")}
                            >
                              수정
                            </button>
                          )
                        )}
                        {!isNewTrademark &&
                          !tradeMark.productEditing &&
                          buttonName === "항목 저장" && (
                            <button
                              className="btn_delete"
                              onClick={() => deleteCategory("product_list")}
                            >
                              삭제
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div>
                {!foldingProductList && (
                  <table className="product_table">
                    <colgroup>
                      <col width={30} />
                      <col width={30} />
                      <col width={30} />
                      <col width={100} />
                      <col width={30} />
                      <col width={30} />
                      <col width={30} />
                      <col width={30} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>분류</th>
                        <th style={{ textAlign: "left" }}>유사군</th>
                        <th style={{ textAlign: "left" }}>
                          명칭 (언어:
                          <select
                            onChange={(e) =>
                              setSummaryType({
                                split_type: null,
                                language_type: Number(e.target.value),
                              })
                            }
                          >
                            <option value={0}>한국어</option>
                            <option value={1}>영어</option>
                            <option value={2}>현지어</option>
                          </select>
                          )
                        </th>
                        <th>보정</th>
                        <th>삭제</th>
                        <th>불사용취소</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tradeMark.product_class.map((item, index) => {
                        return (
                          <>
                            <tr
                              className={
                                item.correction || item.cancel || item.core
                                  ? "is_correction"
                                  : ""
                              }
                            >
                              <td>{index + 1}</td>
                              <td>{item.class}</td>
                              <td style={{ textAlign: "left" }}>
                                {tradeMark.productEditing ? (
                                  <input
                                    type="text"
                                    value={item.similar_group}
                                    onChange={(e) => {
                                      item.similar_group = e.target.value;
                                      setTradeMark({ ...tradeMark });
                                    }}
                                    style={{ width: "70px", textAlign: "left" }}
                                  />
                                ) : (
                                  item.similar_group
                                )}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {tradeMark.productEditing ? (
                                  <input
                                    style={{ width: "300px" }}
                                    type="text"
                                    value={
                                      summaryType.language_type === 0
                                        ? item.product
                                        : summaryType.language_type === 1
                                        ? item.product_en
                                        : item.product_local
                                    }
                                    onChange={(e) => {
                                      if (summaryType.language_type === 0) {
                                        item.product = e.target.value;
                                      } else if (
                                        summaryType.language_type === 1
                                      ) {
                                        item.product_en = e.target.value;
                                      } else {
                                        item.product_local = e.target.value;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                ) : (
                                  <p>
                                    {summaryType.language_type === 0
                                      ? item.product
                                      : summaryType.language_type === 1
                                      ? item.product_en
                                      : item.product_local}
                                  </p>
                                )}
                              </td>
                              <td>
                                <ToggleButtonWithSpan
                                  condition={{ on: "", off: "" }}
                                  selected={item.correction}
                                  toggleSelected={() => {
                                    if (tradeMark.productEditing) {
                                      item.correction = Number(
                                        !item.correction
                                      );
                                      if (item.correction) {
                                        handleCalibrationList(item, "push");
                                      } else {
                                        handleCalibrationList(
                                          item,
                                          "parent_delete"
                                        );
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <ToggleButtonWithSpan
                                  condition={{ on: "", off: "" }}
                                  selected={item.core}
                                  toggleSelected={() => {
                                    if (tradeMark.productEditing) {
                                      item.core = Number(!item.core);
                                      setTradeMark({ ...tradeMark });
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <ToggleButtonWithSpan
                                  condition={{ on: "", off: "" }}
                                  selected={item.cancel}
                                  toggleSelected={() => {
                                    if (tradeMark.productEditing) {
                                      item.cancel = Number(!item.cancel);
                                      setTradeMark({ ...tradeMark });
                                    }
                                  }}
                                />
                              </td>
                              {tradeMark.productEditing && (
                                <td>
                                  <i
                                    className="icon_badge_del"
                                    onClick={() => handleProductClass(index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              )}
                            </tr>
                            {item.calibration_list?.length > 0
                              ? item.calibration_list.map(
                                  (child_item, child_index) => {
                                    if (item.correction) {
                                      return (
                                        <tr
                                          className={
                                            child_item.correction ||
                                            child_item.cancel
                                              ? "is_correction"
                                              : ""
                                          }
                                        >
                                          <td>
                                            {index + 1} - {child_index + 1}
                                          </td>
                                          <td>{item.class}</td>
                                          <td style={{ textAlign: "left" }}>
                                            {tradeMark.productEditing ? (
                                              <input
                                                style={{
                                                  width: "70px",
                                                  textAlign: "left",
                                                }}
                                                type="text"
                                                value={child_item.similar_group}
                                                onChange={(e) => {
                                                  child_item.similar_group =
                                                    e.target.value;
                                                  setTradeMark({
                                                    ...tradeMark,
                                                  });
                                                }}
                                              />
                                            ) : (
                                              child_item.similar_group
                                            )}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {tradeMark.productEditing ? (
                                              <input
                                                style={{
                                                  width: "300px",
                                                  textAlign: "left",
                                                }}
                                                type="text"
                                                value={child_item.product}
                                                onChange={(e) => {
                                                  child_item.product =
                                                    e.target.value;
                                                  setTradeMark({
                                                    ...tradeMark,
                                                  });
                                                }}
                                              />
                                            ) : (
                                              child_item.product
                                            )}
                                          </td>
                                          <td>
                                            <ToggleButtonWithSpan
                                              condition={{
                                                on: "",
                                                off: "",
                                              }}
                                              selected={child_item.correction}
                                              toggleSelected={() => {
                                                if (tradeMark.productEditing) {
                                                  child_item.correction =
                                                    Number(
                                                      !child_item.correction
                                                    );
                                                  if (child_item.correction) {
                                                    handleCalibrationList(
                                                      item,
                                                      "push",
                                                      child_item
                                                    );
                                                  } else {
                                                    handleCalibrationList(
                                                      item,
                                                      "child_delete",
                                                      child_item
                                                    );
                                                  }
                                                  setTradeMark({
                                                    ...tradeMark,
                                                  });
                                                }
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <ToggleButtonWithSpan
                                              condition={{
                                                on: "",
                                                off: "",
                                              }}
                                              selected={child_item.core}
                                              toggleSelected={() => {
                                                if (tradeMark.productEditing) {
                                                  child_item.core = Number(
                                                    !child_item.core
                                                  );
                                                  setTradeMark({
                                                    ...tradeMark,
                                                  });
                                                }
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <ToggleButtonWithSpan
                                              condition={{ on: "", off: "" }}
                                              selected={child_item.cancel}
                                              toggleSelected={() => {
                                                if (tradeMark.productEditing) {
                                                  child_item.cancel = Number(
                                                    !child_item.cancel
                                                  );
                                                  setTradeMark({
                                                    ...tradeMark,
                                                  });
                                                }
                                              }}
                                            />
                                          </td>
                                          {tradeMark.productEditing && (
                                            <td>
                                              <i
                                                className="icon_badge_del"
                                                onClick={() =>
                                                  handleProductClass(index)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    }
                                  }
                                )
                              : null}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {!foldingProductList && (
                  <div
                    className="rows"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 style={{ color: "#4593f5" }}>
                      최종 지정상품리스트(전체)
                    </h5>
                    <div className="radio_wrapper">
                      <p>구분자 선택</p>
                      <label>
                        콤마
                        <input
                          type="radio"
                          name="split_type"
                          value={0}
                          checked={summaryType.split_type == 0}
                          onChange={(e) => {
                            setSummaryType({
                              ...summaryType,
                              split_type: e.target.value,
                            });
                          }}
                        />
                      </label>
                      <label>
                        세미콜론
                        <input
                          type="radio"
                          name="split_type"
                          value={1}
                          checked={summaryType.split_type == 1}
                          onChange={(e) => {
                            setSummaryType({
                              ...summaryType,
                              split_type: e.target.value,
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="edit" style={{ marginLeft: "auto" }}>
                      {tradeMark.productEditing && (
                        <i
                          className="icon_plus"
                          onClick={() => {
                            setClassificationPopupShow(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}

                {summaryType.split_type !== null && (
                  <pre
                    onClick={handleCopy}
                    ref={copyText}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {indentedText}
                  </pre>
                )}
              </div>
            ) : null}
            {tradeMark.oa.length
              ? tradeMark.oa?.map((item, idx) => {
                  return (
                    <div
                      id={`oaField_${idx}`}
                      className={`field ${
                        tradeMark.oaEditing && oaEditingStates[idx]
                          ? "edit-mode-field"
                          : ""
                      }`}
                    >
                      <div className="field_header">
                        <h5 className="title" style={{ fontSize: "20px" }}>
                          <i
                            className={
                              oaFoldingStates[idx]
                                ? "icon_arrow_up_gray"
                                : "icon_arrow_down_gray"
                            }
                            onClick={() => {
                              setFoldingOa(!foldingOa);
                              handleOaFolding(idx);
                            }}
                          />
                          중간사건 OA {idx + 1}
                        </h5>
                        {!oaFoldingStates[idx] && (
                          <>
                            {!isNewTrademark &&
                            tradeMark.oaEditing &&
                            oaEditingStates[idx] ? (
                              <>
                                <div className="btn-group">
                                  <button
                                    className="btn_save"
                                    onClick={onClickSave}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn_cancel"
                                    onClick={() => {
                                      handleEditing("oaCancelEditing");
                                    }}
                                  >
                                    취소
                                  </button>

                                  <button
                                    className="add_btn"
                                    onClick={() => createOA()}
                                  >
                                    추가하기
                                  </button>
                                </div>
                              </>
                            ) : isNewTrademark && tradeMark.oaEditing ? (
                              <div className="btn-group">
                                <button
                                  className="add_btn"
                                  onClick={() => createOA()}
                                >
                                  추가하기
                                </button>
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteOA(isNewTrademark ? idx : item.idx)
                                  }
                                >
                                  삭제
                                </button>
                              </div>
                            ) : (
                              !isNewTrademark &&
                              !tradeMark.oaEditing &&
                              buttonName === "항목 삭제" && (
                                <button
                                  className="btn_edit"
                                  onClick={() => {
                                    const newEditingStates = new Array(
                                      tradeMark.oa.length
                                    ).fill(false);
                                    newEditingStates[idx] = true;
                                    setOaEditingStates(newEditingStates);
                                    handleEditing("oaEditing");
                                  }}
                                >
                                  수정
                                </button>
                              )
                            )}
                            {!isNewTrademark &&
                              !tradeMark.oaEditing &&
                              buttonName === "항목 저장" && (
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteOA(isNewTrademark ? idx : item.idx)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                          </>
                        )}
                      </div>
                      {!oaFoldingStates[idx] && (
                        <>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="발행일"
                              titleClassName="color_blue"
                              conditions={
                                tradeMark.editing ||
                                (tradeMark.oaEditing && oaEditingStates[idx])
                              }
                              data={item.published_at}
                              haveFile={item.oa_content_file_cnt}
                              onChangeDatePicker={(e) => {
                                item.published_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  fileType.oa_content,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  fileType.oa_content,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                            />
                            <DatePickerAndFileRow
                              title="제출·대응기한"
                              conditions={
                                tradeMark.editing ||
                                (tradeMark.oaEditing && oaEditingStates[idx])
                              }
                              data={item.objection_deadline}
                              haveFile={item.oa_opinion_file_cnt}
                              onChangeDatePicker={(e) => {
                                item.objection_deadline = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  fileType.oa_opinion,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  fileType.oa_opinion,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                            />
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="제출·대응일"
                              conditions={
                                tradeMark.editing ||
                                (tradeMark.oaEditing && oaEditingStates[idx])
                              }
                              data={item.submission_at}
                              haveFile={item.oa_apply_file_cnt}
                              onChangeDatePicker={(e) => {
                                item.submission_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  fileType.oa_apply,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  fileType.oa_apply,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(idx);
                              }}
                            />
                            {(item.is_give_up ||
                              (tradeMark.oaEditing && oaEditingStates[idx]) ||
                              tradeMark.editing) && (
                              <div className="row">
                                <ToggleButtonRow
                                  title="대응포기 여부"
                                  conditions={
                                    tradeMark.editing ||
                                    (tradeMark.oaEditing &&
                                      oaEditingStates[idx])
                                  }
                                  selected={item.is_give_up}
                                  toggleSelected={() => {
                                    item.is_give_up = Number(!item.is_give_up);
                                    setTradeMark({ ...tradeMark });
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          {(item.absolute_refusal_reason & 15 ||
                            (tradeMark.oaEditing && oaEditingStates[idx]) ||
                            tradeMark.editing) && (
                            <div className="rows">
                              <div className="declare_row">
                                <h5 className="title">거절이유</h5>

                                {(item.absolute_refusal_reason & 1 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{
                                      on: "지정상품 불명확",
                                      off: "지정상품 불명확",
                                    }}
                                    selected={item.absolute_refusal_reason & 1}
                                    toggleSelected={() => {
                                      if (!(item.absolute_refusal_reason & 1)) {
                                        item.absolute_refusal_reason += 1;
                                      } else {
                                        item.absolute_refusal_reason -= 1;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                                {(item.absolute_refusal_reason & 2 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{
                                      on: "식별력 부족",
                                      off: "식별력 부족",
                                    }}
                                    selected={item.absolute_refusal_reason & 2}
                                    toggleSelected={() => {
                                      if (!(item.absolute_refusal_reason & 2)) {
                                        item.absolute_refusal_reason += 2;
                                      } else {
                                        item.absolute_refusal_reason -= 2;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                                {(item.absolute_refusal_reason & 4 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{
                                      on: "품질 오인",
                                      off: "품질 오인",
                                    }}
                                    selected={item.absolute_refusal_reason & 4}
                                    toggleSelected={() => {
                                      if (!(item.absolute_refusal_reason & 4)) {
                                        item.absolute_refusal_reason += 4;
                                      } else {
                                        item.absolute_refusal_reason -= 4;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                                {(item.absolute_refusal_reason & 8 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{ on: "기타", off: "기타" }}
                                    selected={item.absolute_refusal_reason & 8}
                                    toggleSelected={() => {
                                      if (!(item.absolute_refusal_reason & 8)) {
                                        item.absolute_refusal_reason += 8;
                                      } else {
                                        item.absolute_refusal_reason -= 8;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {(item.relative_refusal_reason & 3 ||
                            (tradeMark.oaEditing && oaEditingStates[idx]) ||
                            tradeMark.editing) && (
                            <div className="rows">
                              <div
                                className="declare_row"
                                style={{ justifyContent: "inherit" }}
                              >
                                <h5 className="title">상대적 거절이유</h5>
                                {(item.relative_refusal_reason & 1 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{
                                      on: "선등록상표 저촉",
                                      off: "선등록상표 저촉",
                                    }}
                                    selected={item.relative_refusal_reason & 1}
                                    toggleSelected={() => {
                                      if (!(item.relative_refusal_reason & 1)) {
                                        item.relative_refusal_reason += 1;
                                      } else {
                                        item.relative_refusal_reason -= 1;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                                {(item.relative_refusal_reason & 2 ||
                                  (tradeMark.oaEditing &&
                                    oaEditingStates[idx]) ||
                                  tradeMark.editing) && (
                                  <ToggleButtonWithSpan
                                    condition={{
                                      on: "선사용상표 저촉",
                                      off: "선사용상표 저촉",
                                    }}
                                    selected={item.relative_refusal_reason & 2}
                                    toggleSelected={() => {
                                      if (!(item.relative_refusal_reason & 2)) {
                                        item.relative_refusal_reason += 2;
                                      } else {
                                        item.relative_refusal_reason -= 2;
                                      }
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })
              : null}

            {tradeMark.show_objection ? (
              <div
                id="objectField"
                className={`field ${
                  !isNewTrademark && tradeMark.objectionEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingObjection
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingObjection(!foldingObjection)}
                    />
                    이의신청
                  </h5>
                  {!foldingObjection && (
                    <>
                      {!isNewTrademark && tradeMark.objectionEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("objectionCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.objectionEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("objectionEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.objectionEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("objection")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>

                {!foldingObjection && (
                  <div className="rows">
                    <DatePickerRow
                      title="이의신청 접수일"
                      conditions={tradeMark.objectionEditing}
                      data={tradeMark.objection_at}
                      onChangeDatePicker={(e) =>
                        setTradeMark({
                          ...tradeMark,
                          objection_at: new Date(e).getTime(),
                        })
                      }
                    />
                    {/*<SelectRow*/}
                    {/*  title="이의신청인"*/}
                    {/*  content={tradeMark.objector_name}*/}
                    {/*  onChange={(e) =>*/}
                    {/*    setTradeMark({*/}
                    {/*      ...tradeMark,*/}
                    {/*      objector_idx: e.target.value,*/}
                    {/*      objector_name:*/}
                    {/*        e.target.options[e.target.selectedIndex].text,*/}
                    {/*    })*/}
                    {/*  }*/}
                    {/*  condition={isCompany && tradeMark.editing}*/}
                    {/*  contentIdx={tradeMark.objector_idx}*/}
                    {/*  selectTag={managerSelectTag}*/}
                    {/*/>*/}
                    {(tradeMark.objection_state ||
                      tradeMark.objectionEditing) && (
                      <div className="row">
                        <h2 className="title">현 상태</h2>
                        <ToggleButtonWithSpan
                          condition={{
                            on: "종결(ended)",
                            off: "계속중(pending)",
                          }}
                          selected={tradeMark.objection_state}
                          toggleSelected={() => {
                            if (tradeMark.objectionEditing) {
                              tradeMark.objection_state = Number(
                                !tradeMark.objection_state
                              );
                              setTradeMark({ ...tradeMark });
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
            {tradeMark.show_drop ? (
              <div
                id="withdrawField"
                className={`field ${
                  !isNewTrademark && tradeMark.withdrawEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingWithdraw
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingWithdraw(!foldingWithdraw)}
                    />
                    취하
                  </h5>
                  {!foldingWithdraw && (
                    <>
                      {!isNewTrademark && tradeMark.withdrawEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("withdrawCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.withdrawEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("withdrawEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.withdrawEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("drop")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>

                {!foldingWithdraw &&
                  (tradeMark.is_drop || tradeMark.withdrawEditing) && (
                    <div className="rows">
                      <ToggleButtonRow
                        title="취하"
                        conditions={tradeMark.withdrawEditing}
                        selected={tradeMark.is_drop}
                        toggleSelected={() =>
                          setTradeMark({
                            ...tradeMark,
                            is_drop: Number(!tradeMark.is_drop),
                          })
                        }
                      />
                      {Boolean(tradeMark.is_drop) && (
                        <>
                          <DatePickerAndFileRow
                            title="취하 접수일"
                            haveFile={tradeMark.drop_receipt_file_cnt}
                            conditions={tradeMark.withdrawEditing}
                            data={tradeMark.drop_receipt_at}
                            onChangeDatePicker={(e) =>
                              setTradeMark({
                                ...tradeMark,
                                drop_receipt_at: new Date(e).getTime(),
                              })
                            }
                            onClickDownPopShow={() => {
                              onClickDownPopShow(fileType.drop, null, 1);
                            }}
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                isNewTrademark ? fileType.drop1 : fileType.drop,
                                null,
                                1
                              );
                              setQueryType(1);
                            }}
                          />
                          <DatePickerAndFileRow
                            title="취하 결정일"
                            haveFile={tradeMark.drop_decision_file_cnt}
                            conditions={tradeMark.withdrawEditing}
                            data={tradeMark.drop_decision_at}
                            onChangeDatePicker={(e) =>
                              setTradeMark({
                                ...tradeMark,
                                drop_decision_at: new Date(e).getTime(),
                              })
                            }
                            onClickDownPopShow={() => {
                              onClickDownPopShow(fileType.drop, null, 2);
                            }}
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                isNewTrademark ? fileType.drop2 : fileType.drop,
                                null,
                                2
                              );
                              setQueryType(2);
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
              </div>
            ) : null}
            {tradeMark.show_give_up ? (
              <div
                id="giveupField"
                className={`field ${
                  !isNewTrademark && tradeMark.giveupEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingGiveup
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingGiveup(!foldingGiveup)}
                    />
                    포기
                  </h5>

                  {!foldingGiveup && (
                    <>
                      {!isNewTrademark && tradeMark.giveupEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("giveupCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.giveupEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("giveupEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.giveupEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("give_up")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>
                {!foldingGiveup &&
                  (tradeMark.is_give_up || tradeMark.giveupEditing) && (
                    <div className="rows">
                      <ToggleButtonRow
                        title="포기"
                        conditions={tradeMark.giveupEditing}
                        selected={tradeMark.is_give_up}
                        toggleSelected={() =>
                          setTradeMark({
                            ...tradeMark,
                            is_give_up: Number(!tradeMark.is_give_up),
                          })
                        }
                      />
                      {Boolean(tradeMark.is_give_up) && (
                        <>
                          <DatePickerAndFileRow
                            title="포기 접수일"
                            haveFile={tradeMark.give_up_receipt_file_cnt}
                            conditions={tradeMark.giveupEditing}
                            data={tradeMark.give_up_receipt_at}
                            onChangeDatePicker={(e) =>
                              setTradeMark({
                                ...tradeMark,
                                give_up_receipt_at: new Date(e).getTime(),
                              })
                            }
                            onClickDownPopShow={() =>
                              onClickDownPopShow(fileType.giveup, null, 3)
                            }
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                isNewTrademark
                                  ? fileType.giveup1
                                  : fileType.giveup,
                                null,
                                3
                              );
                              setQueryType(3);
                            }}
                          />
                          <DatePickerAndFileRow
                            title="포기 결정일"
                            haveFile={tradeMark.give_up_decision_file_cnt}
                            conditions={tradeMark.giveupEditing}
                            data={tradeMark.give_up_decision_at}
                            onChangeDatePicker={(e) =>
                              setTradeMark({
                                ...tradeMark,
                                give_up_decision_at: new Date(e).getTime(),
                              })
                            }
                            onClickDownPopShow={() =>
                              onClickDownPopShow(fileType.giveup, null, 4)
                            }
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                isNewTrademark
                                  ? fileType.giveup2
                                  : fileType.giveup,
                                null,
                                4
                              );
                              setQueryType(4);
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
              </div>
            ) : null}
            {/* 이의신청 필드 */}

            {tradeMark.show_reject ? (
              tradeMark.nation === 0 ? (
                <FormRejectKR {...props_reject} />
              ) : (
                <FormRejectOverseas {...props_reject} />
              )
            ) : null}

            {tradeMark.show_regist ? (
              <div
                id="registField"
                className={`field ${
                  !isNewTrademark && tradeMark.registEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingRegist
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingRegist(!foldingRegist)}
                    />
                    등록 및 갱신
                  </h5>
                  {!foldingRegist && (
                    <>
                      {!isNewTrademark && tradeMark.registEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("registCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.registEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("registEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.registEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("regist")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>
                {!foldingRegist && (
                  <>
                    <div className="rows">
                      <p className="title_no_items">최종 등록정보</p>
                    </div>
                    <div className="rows">
                      <InputRow
                        title="등록번호"
                        conditions={tradeMark.registEditing}
                        content={tradeMark.regist_number}
                        onChange={(e) =>
                          setTradeMark({
                            ...tradeMark,
                            regist_number: e.target.value,
                          })
                        }
                      />
                      <DatePickerAndFileRow
                        title="등록일"
                        haveFile={tradeMark.regist_file_cnt}
                        conditions={tradeMark.registEditing}
                        data={tradeMark.regist_at}
                        onChangeDatePicker={(e) =>
                          setTradeMark({
                            ...tradeMark,
                            regist_at: new Date(e).getTime(),
                          })
                        }
                        onClickDownPopShow={() => {
                          onClickDownPopShow(fileType.regist);
                        }}
                        onClickUpPopShow={() => {
                          onClickUpPopShow(fileType.regist);
                          setCategoryNumber(null);
                        }}
                      />
                      <DatePickerRow
                        title="존속기간만료일"
                        conditions={tradeMark.registEditing}
                        data={tradeMark.depend_deadline}
                        onChangeDatePicker={(e) => {
                          tradeMark.depend_deadline = new Date(e).getTime();
                          setTradeMark({ ...tradeMark });
                        }}
                      />
                    </div>
                    <div className="rows">
                      <p className="title_no_items">등록결정</p>
                    </div>
                    <div className="rows">
                      <DatePickerAndFileRow
                        title="등록결정일"
                        haveFile={tradeMark.register_decision_file_cnt}
                        conditions={tradeMark.registEditing}
                        data={tradeMark.regist_decision_at}
                        onChangeDatePicker={(e) => {
                          tradeMark.setTradeMark({
                            ...tradeMark,
                            regist_decision_at: new Date(e).getTime(),
                          });
                        }}
                        onClickDownPopShow={() => {
                          onClickDownPopShow(
                            fileType.regist_file,
                            null,
                            26,
                            null
                          );
                        }}
                        onClickUpPopShow={() => {
                          onClickUpPopShow(fileType.regist_file, null, 26);
                          setCategoryNumber(26);
                        }}
                      />
                      {(tradeMark.is_regist_give_up ||
                        tradeMark.editing ||
                        tradeMark.registEditing) && (
                        <ToggleButtonRow
                          title="등록포기 여부"
                          conditions={
                            tradeMark.editing || tradeMark.registEditing
                          }
                          selected={tradeMark.is_regist_give_up}
                          toggleSelected={() => {
                            tradeMark.is_regist_give_up = Number(
                              !tradeMark.is_regist_give_up
                            );
                            setTradeMark({ ...tradeMark });
                          }}
                        />
                      )}
                      {(tradeMark.is_divied_fee_paid ||
                        tradeMark.editing ||
                        tradeMark.registEditing) && (
                        <ToggleButtonRow
                          title="분할납부 여부"
                          conditions={
                            tradeMark.editing || tradeMark.registEditing
                          }
                          selected={tradeMark.is_divied_fee_paid}
                          toggleSelected={() => {
                            tradeMark.is_divied_fee_paid = Number(
                              !tradeMark.is_divied_fee_paid
                            );
                            setTradeMark({ ...tradeMark });
                          }}
                        />
                      )}
                    </div>
                    <div className="rows">
                      <DatePickerAndFileRow
                        title="등록료납부기한"
                        haveFile={tradeMark.register_fee_deadline_file_cnt}
                        conditions={tradeMark.registEditing}
                        data={tradeMark.regist_payment_deadline}
                        onChangeDatePicker={(e) => {
                          setTradeMark({
                            ...tradeMark,
                            regist_payment_deadline: new Date(e).getTime(),
                          });
                        }}
                        onClickDownPopShow={() => {
                          onClickDownPopShow(
                            fileType.regist_file,
                            null,
                            27,
                            null
                          );
                        }}
                        onClickUpPopShow={() => {
                          onClickUpPopShow(fileType.regist_file, null, 27);
                          setCategoryNumber(27);
                        }}
                      />
                      {tradeMark.is_divied_fee_paid ? (
                        <DatePickerAndFileRow
                          title="분할납부 기한"
                          haveFile={tradeMark.split_fee_deadline_file_cnt}
                          conditions={tradeMark.registEditing}
                          data={tradeMark.regist_divide_pay_deadline}
                          onChangeDatePicker={(e) => {
                            setTradeMark({
                              ...tradeMark,
                              regist_divide_pay_deadline: new Date(e).getTime(),
                            });
                          }}
                          onClickDownPopShow={() => {
                            onClickDownPopShow(
                              fileType.regist_file,
                              null,
                              28,
                              null
                            );
                          }}
                          onClickUpPopShow={() => {
                            onClickUpPopShow(fileType.regist_file, null, 28);
                            setCategoryNumber(28);
                          }}
                        />
                      ) : null}
                      {(tradeMark.is_regist_fee_paid ||
                        tradeMark.registEditing) && (
                        <ToggleButtonRow
                          title="등록료납부여부"
                          conditions={tradeMark.registEditing}
                          selected={tradeMark.is_regist_fee_paid}
                          toggleSelected={() => {
                            tradeMark.is_regist_fee_paid = Number(
                              !tradeMark.is_regist_fee_paid
                            );
                            setTradeMark({ ...tradeMark });
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
                {!foldingRegist && (
                  <div className="rows">
                    <div
                      className="declare_row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p className="title_no_items">갱신</p>
                      {tradeMark.registEditing && (
                        <button
                          className="add_btn"
                          onClick={() => addRenewalList()}
                        >
                          갱신추가
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {!foldingRegist && (
                  <>
                    {tradeMark.renew_list?.map((item, index) => {
                      return (
                        <div className="rows">
                          <DatePickerAndFileRow
                            title={`갱신등록일(${index + 1}차)`}
                            haveFile={item.renew_file_cnt}
                            conditions={tradeMark.registEditing}
                            data={item.renew_at}
                            onChangeDatePicker={(e) => {
                              item.renew_at = new Date(e).getTime();
                              setTradeMark({ ...tradeMark });
                            }}
                            onClickDownPopShow={() => {
                              onClickDownPopShow(
                                fileType.renew,
                                null,
                                isNewTrademark ? index : item.idx
                              );
                              setSecondIdx(isNewTrademark ? index : item.idx);
                            }}
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                fileType.renew,
                                null,
                                isNewTrademark ? index : item.idx
                              );
                              setSecondIdx(isNewTrademark ? index : item.idx);
                            }}
                          />
                          {(!!item.is_split_payment ||
                            tradeMark.registEditing) && (
                            <ToggleButtonRow
                              title="분할납부 여부"
                              conditions={tradeMark.registEditing}
                              selected={item.is_split_payment}
                              toggleSelected={() => {
                                item.is_split_payment = Number(
                                  !item.is_split_payment
                                );
                                setTradeMark({ ...tradeMark });
                              }}
                            />
                          )}
                          <DatePickerAndFileRow
                            title="분할납부기한"
                            haveFile={item.renew_split_file_cnt}
                            conditions={tradeMark.registEditing}
                            data={item.split_payment_deadline}
                            onChangeDatePicker={(e) => {
                              item.split_payment_deadline = new Date(
                                e
                              ).getTime();
                              setTradeMark({ ...tradeMark });
                            }}
                            onClickDownPopShow={() => {
                              onClickDownPopShow(
                                fileType.renew_split,
                                null,
                                isNewTrademark ? index : item.idx
                              );
                              setSecondIdx(isNewTrademark ? index : item.idx);
                            }}
                            onClickUpPopShow={() => {
                              onClickUpPopShow(
                                fileType.renew_split,
                                null,
                                isNewTrademark ? index : item.idx
                              );
                              setSecondIdx(isNewTrademark ? index : item.idx);
                            }}
                          />
                          {tradeMark.registEditing && (
                            <>
                              <td>
                                <i
                                  className="icon_badge_del"
                                  onClick={() => handleRenewDelete(index)}
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            ) : null}
            {tradeMark.show_erase ? (
              <div
                id="eraseField"
                className={`field ${
                  !isNewTrademark && tradeMark.eraseEditing
                    ? "edit-mode-field"
                    : ""
                }`}
              >
                <div className="field_header">
                  <h5 className="title" style={{ fontSize: "20px" }}>
                    <i
                      className={
                        foldingErase
                          ? "icon_arrow_up_gray"
                          : "icon_arrow_down_gray"
                      }
                      onClick={() => setFoldingErase(!foldingErase)}
                    />
                    말소
                  </h5>

                  {!foldingErase && (
                    <>
                      {!isNewTrademark && tradeMark.eraseEditing ? (
                        <>
                          <div className="btn-group">
                            <button className="btn_save" onClick={onClickSave}>
                              저장
                            </button>
                            <button
                              className="btn_cancel"
                              onClick={() => {
                                handleEditing("eraseCancelEditing");
                              }}
                            >
                              취소
                            </button>
                          </div>
                        </>
                      ) : (
                        !isNewTrademark &&
                        !tradeMark.eraseEditing &&
                        buttonName === "항목 삭제" && (
                          <button
                            className="btn_edit"
                            onClick={() => handleEditing("eraseEditing")}
                          >
                            수정
                          </button>
                        )
                      )}
                      {!isNewTrademark &&
                        !tradeMark.eraseEditing &&
                        buttonName === "항목 저장" && (
                          <button
                            className="btn_delete"
                            onClick={() => deleteCategory("erase")}
                          >
                            삭제
                          </button>
                        )}
                    </>
                  )}
                </div>
                {!foldingErase &&
                  (tradeMark.is_erase || tradeMark.eraseEditing) && (
                    <div className="rows">
                      <ToggleButtonRow
                        title="말소"
                        conditions={tradeMark.eraseEditing}
                        selected={tradeMark.is_erase}
                        toggleSelected={() =>
                          tradeMark.setTradeMark({
                            ...tradeMark,
                            is_erase: Number(!tradeMark.is_erase),
                          })
                        }
                      />
                    </div>
                  )}
              </div>
            ) : null}
            {/*<div className="field">*/}
            {/*  <div className="field_header">*/}
            {/*    <h5 className="title">*/}
            {/*      출원中분쟁{" "}*/}
            {/*      <ToggleButtonWithSpan*/}
            {/*        condition={{ on: "", off: "" }}*/}
            {/*        selected={tradeMark.is_dispute_in_apply}*/}
            {/*        toggleSelected={() => {*/}
            {/*          if (tradeMark.editing) {*/}
            {/*            tradeMark.is_dispute_in_apply = Number(*/}
            {/*              !tradeMark.is_dispute_in_apply*/}
            {/*            );*/}
            {/*            setTradeMark({ ...tradeMark });*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </h5>*/}
            {/*    {tradeMark.editing ? (*/}
            {/*      <button className="add_btn">추가하기</button>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*  <div className="rows">*/}
            {/*    <SelectRow title="사건종류" />*/}
            {/*    <SelectRow title="관련 사건번호" />*/}
            {/*  </div>*/}
            {/*  <div className="field_header" style={{ marginTop: "20px" }}>*/}
            {/*    <h5 className="title">*/}
            {/*      등록後분쟁{" "}*/}
            {/*      <ToggleButtonWithSpan*/}
            {/*        condition={{ on: "", off: "" }}*/}
            {/*        selected={tradeMark.is_dispute_after_regist}*/}
            {/*        toggleSelected={() => {*/}
            {/*          if (tradeMark.editing) {*/}
            {/*            tradeMark.is_dispute_after_regist = Number(*/}
            {/*              !tradeMark.is_dispute_after_regist*/}
            {/*            );*/}
            {/*            setTradeMark({ ...tradeMark });*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </h5>*/}
            {/*    {tradeMark.editing ? (*/}
            {/*      <button className="add_btn">추가하기</button>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*  <div className="rows">*/}
            {/*    <SelectRow title="사건종류" />*/}
            {/*    <SelectRow title="관련 사건번호" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {tradeMark.show_cancel_judge ? (
              <div className="field">
                <div className="field_header">
                  <h5 className="title">취소심판</h5>
                  {tradeMark.editing && (
                    <button
                      className="btn_delete"
                      onClick={() => deleteCategory("cancel_judge")}
                    >
                      삭제하기
                    </button>
                  )}
                </div>
                <div className="rows">
                  <ToggleButtonRow
                    title="취소심판"
                    conditions={tradeMark.editingsdss}
                    selected={tradeMark.is_cancel_judge}
                    toggleSelected={() =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        is_cancel_judge: Number(!tradeMark.is_cancel_judge),
                      })
                    }
                  />
                </div>
              </div>
            ) : null}
            <div
              id="historyField"
              className={`field ${
                !isNewTrademark && tradeMark.historyEditing
                  ? "edit-mode-field"
                  : ""
              }`}
            >
              <div className="field_header">
                <h5 className="title" style={{ fontSize: "20px" }}>
                  <i
                    className={
                      foldingHistory
                        ? "icon_arrow_up_gray"
                        : "icon_arrow_down_gray"
                    }
                    onClick={() => setFoldingHistory(!foldingHistory)}
                  />
                  History
                </h5>

                {!foldingHistory && (
                  <>
                    {!isNewTrademark && tradeMark.historyEditing ? (
                      <>
                        <div className="btn-group">
                          <button className="btn_save" onClick={onClickSave}>
                            저장
                          </button>
                          <button
                            className="btn_cancel"
                            onClick={() => {
                              handleEditing("historyCancelEditing");
                            }}
                          >
                            취소
                          </button>
                          <button className="add_btn" onClick={addHistoryList}>
                            간편 추가
                          </button>
                          <button
                            className="add_btn "
                            onClick={() => {
                              setHistoryPopupIdx(null);
                              setHistoryPopupShow("EDIT_HISTORY");
                              openHistoryModalHandler();
                            }}
                          >
                            추가하기
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {!isAgent &&
                          (tradeMark.historyEditing ? (
                            <button
                              className="add_btn"
                              onClick={addHistoryList}
                            >
                              추가하기
                            </button>
                          ) : (
                            <button
                              className="btn_edit"
                              onClick={() => handleEditing("historyEditing")}
                            >
                              수정
                            </button>
                          ))}
                      </>
                    )}
                  </>
                )}
              </div>

              {!foldingHistory && (
                <>
                  <table className="history_table">
                    <colgroup>
                      <col width={140} />
                      <col width={140} />
                      <col width={130} />
                      <col width={130} />
                      <col width={130} />
                      <col width={100} />
                      <col width={50} />
                      <col width={100} />
                      {tradeMark.editing && (
                        <>
                          <col width={50} />
                        </>
                      )}
                    </colgroup>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", paddingLeft: "24px" }}>
                          업무제목
                        </th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                          코멘트
                        </th>
                        <th style={{ textAlign: "right" }}>업무일자</th>
                        <th style={{ textAlign: "right" }}>지시요청</th>
                        <th style={{ textAlign: "right" }}>법정기한</th>
                        <th>파일</th>
                        <th>상세보기</th>
                        <th>회신하기</th>
                        {tradeMark.historyEditing && (
                          <>
                            <th>삭제</th>
                          </>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {tradeMark.history_list.map((item, idx) => {
                        return item.isEdit ? (
                          <>
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                <span className="reply-toggle">
                                  {!!item?.child?.length && (
                                    <i
                                      style={{
                                        rotate: openRepliesParentIds.includes(
                                          item.idx
                                        )
                                          ? "90deg"
                                          : "",
                                      }}
                                      onClick={() =>
                                        updateOpenReplies(item.idx)
                                      }
                                      className="icon_arrow_right_s"
                                    />
                                  )}
                                  <input
                                    type="text"
                                    name="title"
                                    value={item.title}
                                    style={{
                                      color: item.is_important ? "red" : "",
                                    }}
                                    onChange={(e) => handleHistoryInput(e, idx)}
                                  />
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="content"
                                  value={window.$Global.extractTextFromHTML(
                                    item.content
                                  )}
                                  onChange={(e) => handleHistoryInput(e, idx)}
                                />
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <div>
                                  <DatePickerCustom
                                    data={item.work_at}
                                    onChangeDatePicker={(e) => {
                                      item.work_at = new Date(e).getTime();
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                </div>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <div>
                                  <DatePickerCustom
                                    data={item.instruction_deadline}
                                    onChangeDatePicker={(e) => {
                                      item.instruction_deadline = new Date(
                                        e
                                      ).getTime();
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                </div>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <div>
                                  <DatePickerCustom
                                    data={item.legal_deadline}
                                    onChangeDatePicker={(e) => {
                                      item.legal_deadline = new Date(
                                        e
                                      ).getTime();
                                      setTradeMark({ ...tradeMark });
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                {Boolean(item.idx) && (
                                  <i
                                    className={
                                      item.history_file_cnt
                                        ? "icon_download_attached"
                                        : "icon_download"
                                    }
                                    onClick={() => {
                                      onClickDownPopShow(
                                        fileType.history,
                                        null,
                                        item.idx
                                      );
                                      setSecondIdx(item.idx);
                                      setHistoryPopupShow(null);
                                    }}
                                  />
                                )}
                                <i
                                  className="icon_upload"
                                  onClick={() => {
                                    console.log(fileType.history);
                                    onClickUpPopShow(
                                      fileType.history,
                                      null,
                                      idx
                                    );
                                    setSecondIdx(idx);
                                    setHistoryPopupShow(null);
                                  }}
                                />
                              </td>
                              <td>
                                <i
                                  className="icon_arrow_right_s"
                                  onClick={() => {
                                    setHistoryPopupIdx(idx);
                                    tradeMark.historyEditing
                                      ? setHistoryPopupShow("EDIT_HISTORY")
                                      : setHistoryPopupShow("VIEW");
                                    openHistoryModalHandler();
                                  }}
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  disabled={!item.idx}
                                  style={{
                                    opacity: !item.idx ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    setHistoryPopupIdx(idx);
                                    setHistoryPopupShow("EDIT_REPLY");
                                    openHistoryModalHandler();
                                    setToEditReply(null);
                                    setTradeMark((prevState) => ({
                                      ...prevState,
                                      historyEditing: true,
                                    }));
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "30px",
                                      cursor: !item.idx
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    src={replyIcon}
                                    alt="reply"
                                  />
                                </button>
                              </td>
                              {tradeMark.historyEditing && (
                                <>
                                  <td>
                                    <i
                                      className="icon_badge_del"
                                      onClick={() => handleHistoryDelete(idx)}
                                    />
                                  </td>
                                </>
                              )}
                            </tr>
                            {openRepliesParentIds.includes(item.idx) && (
                              <ReplyRows
                                parent={item}
                                updateTradeMarkHistory={setTradeMark}
                                isEditMode={tradeMark.historyEditing}
                                onPreviewFiles={(replyIdx) => {
                                  onClickDownPopShow(
                                    fileType.history,
                                    null,
                                    replyIdx
                                  );
                                  setSecondIdx(replyIdx);
                                }}
                                onEditReply={(reply) => {
                                  setHistoryPopupIdx(idx);
                                  setHistoryPopupShow("EDIT_REPLY");
                                  setToEditReply(reply);
                                  openHistoryModalHandler();
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingRight: "10px",
                                  color: item.is_important ? "red" : "",
                                }}
                              >
                                <span
                                  className={`reply-toggle ${
                                    item?.child?.length ? "" : "no-replies"
                                  }`}
                                >
                                  {!!item?.child?.length && (
                                    <i
                                      style={{
                                        rotate: openRepliesParentIds.includes(
                                          item.idx
                                        )
                                          ? "90deg"
                                          : "",
                                      }}
                                      onClick={() =>
                                        updateOpenReplies(item.idx)
                                      }
                                      className="icon_arrow_right_s"
                                    />
                                  )}
                                  {item.title}
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                {window.$Global.extractTextFromHTML(
                                  item.content
                                )}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {item.work_at > 0 &&
                                  window.$Global.convertDate(item.work_at)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {item.instruction_deadline > 0 &&
                                  window.$Global.convertDate(
                                    item.instruction_deadline
                                  )}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {item.legal_deadline > 0 &&
                                  window.$Global.convertDate(
                                    item.legal_deadline
                                  )}
                              </td>
                              <td>
                                {Boolean(item.idx) ? (
                                  <i
                                    className={
                                      item.history_file_cnt
                                        ? "icon_download_attached"
                                        : "icon_download"
                                    }
                                    onClick={() => {
                                      onClickDownPopShow(
                                        fileType.history,
                                        null,
                                        item.idx ? item.idx : idx
                                      );
                                      setSecondIdx(item.idx ? item.idx : idx);
                                      setHistoryPopupShow(null);
                                    }}
                                  />
                                ) : null}
                                <i
                                  className="icon_upload"
                                  onClick={() => {
                                    onClickUpPopShow(
                                      fileType.history,
                                      null,
                                      item.idx ? item.idx : idx
                                    );
                                    setSecondIdx(item.idx ? item.idx : idx);
                                    setHistoryPopupShow(null);
                                  }}
                                />
                              </td>
                              <td>
                                <i
                                  className="icon_arrow_right_s"
                                  onClick={() => {
                                    setHistoryPopupIdx(idx);
                                    tradeMark.historyEditing
                                      ? setHistoryPopupShow("EDIT_HISTORY")
                                      : setHistoryPopupShow("VIEW_HISTORY");
                                    openHistoryModalHandler();
                                  }}
                                />
                              </td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setHistoryPopupIdx(idx);
                                  //   setHistoryPopupShow("EDIT_REPLY");
                                  //   openHistoryModalHandler();
                                  //   setToEditReply(null);
                                  //   setTradeMark((prevState) => ({
                                  //     ...prevState,
                                  //     historyEditing: true,
                                  //   }));
                                  // }}
                                  // 임시로 만듦. 위에 onClick이 진짜임.
                                  onClick={() => {
                                    onClickDownPopShow(
                                      fileType.history,
                                      null,
                                      item.idx
                                    );
                                    setSecondIdx(item.idx);
                                    setHistoryPopupShow(null);
                                  }}
                                  style={{ width: "30px", cursor: "pointer" }}
                                  src={replyIcon}
                                  alt="reply"
                                />
                              </td>
                              {tradeMark.historyEditing && (
                                <>
                                  <td>
                                    <i
                                      className="icon_badge_del"
                                      onClick={() => handleHistoryDelete(idx)}
                                    />
                                  </td>
                                </>
                              )}
                            </tr>
                            {openRepliesParentIds.includes(item.idx) && (
                              <ReplyRows
                                parent={item}
                                updateTradeMarkHistory={setTradeMark}
                                isEditMode={tradeMark.historyEditing}
                                onPreviewFiles={(replyIdx) => {
                                  onClickDownPopShow(
                                    fileType.history,
                                    null,
                                    replyIdx
                                  );
                                  setSecondIdx(replyIdx);
                                }}
                                onReplyPreview={(reply) => {
                                  console.log(reply);
                                  setHistoryPopupShow("VIEW_REPLY");
                                  openHistoryModalHandler();
                                  setToEditReply(reply);
                                }}
                                onEditReply={(reply) => {
                                  setHistoryPopupIdx(idx);
                                  setHistoryPopupShow("EDIT_REPLY");
                                  setToEditReply(reply);
                                  openHistoryModalHandler();
                                }}
                              />
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
          <DetailPopupDownload
            show={downPopupShow}
            setShow={setDownPopupShow}
            fileViewList={fileViewList}
            setFileList={setFileViewList}
            onClickDownload={onClickDownload}
            onClickDelete={onClickDeleteFileInDownload}
            setFileImage={setFileImage}
          />
          <Modal ref={historyModalRef}>
            {/edit/i.test(historyPopupShow) ? (
              <FormHistory
                onModalClose={closeHistoryModalHandler}
                onSubmit={onHistoryModalSubmit}
                record={
                  historyPopupShow === "EDIT_HISTORY"
                    ? { ...tradeMark.history_list[historyPopupIdx] }
                    : {
                        ...toEditReply,
                        parent: tradeMark.history_list[historyPopupIdx],
                      }
                }
                trademark_idx={tradeMark.idx}
                type={historyPopupShow === "EDIT_HISTORY" ? "HISTORY" : "REPLY"}
              />
            ) : /view/i.test(historyPopupShow) ? (
              <HistoryPopup
                setHistoryPopupShow={closeHistoryModalHandler}
                type={historyPopupShow === "VIEW_HISTORY" ? "HISTORY" : "REPLY"}
                record={
                  historyPopupShow === "VIEW_HISTORY"
                    ? tradeMark.history_list[historyPopupIdx]
                    : { ...toEditReply }
                }
              />
            ) : null}
          </Modal>
          <DetailPopupUpload
            show={upPopupShow}
            setShow={setUpPopupShow}
            uploadFileViewList={uploadFileViewList}
            setFileList={setUploadFileViewList}
            onClickUpload={onClickUpload}
            setFileImage={setFileImage}
            setFpFileImage={setFpFileImage}
            popupPropType={popupProp.type}
          />
          {isProductionPopupClick && (
            <ProductPopUp
              setIsProductionPopupClick={setIsProductionPopupClick}
            />
          )}
          {classificationPopupShow && (
            <ProductClassificationPopup
              classificationList={classificationList}
              onClickSelect={onClickSelectClass}
              onClickClose={() => setClassificationPopupShow(false)}
              setDatas={setTradeMark}
              datas={tradeMark}
            />
          )}
          {selectPopupShow && (
            <ProductSelectPopup
              setData={setTradeMark}
              datas={tradeMark}
              productClassNo={productClassNo}
              onClickClose={() => setSelectPopupShow(false)}
              onClickConfirm={onClickConfirm}
            />
          )}
          {isNationPopupClick && (
            <NationListPopUp
              nationListArr={nationList}
              setNationListArr={setNationList}
              setIsNationPopupClick={setIsNationPopupClick}
            />
          )}
          {searhPopupClick && (
            <NameSearchPopup
              setData={setTradeMark}
              data={tradeMark}
              setSearchPopupClick={setSearchPopupClick}
            />
          )}
          {eventPopupShow && (
            <TradeMarkEventPopup
              setEventPopupShow={setEventPopupShow}
              setData={setTradeMark}
              data={tradeMark}
              setSaveData={setMadridBasicManage}
              eventPopupType={eventPopupType}
            />
          )}
          <TradeMarkNationAddPopup
            show={nationPopupShow}
            setShow={setNationPopupShow}
            nationType={nationPopupType}
          />
        </div>
      </TradeMarkFileTypeContext.Provider>
    </TradeMarkContext.Provider>
  );
};

export default RenewalTradeMarkDetail;
