import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.scss";
import Loading from "./components/loading/Loading";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import MenuTab from "./components/menu/MenuTab";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import HomeTaskManage from "./components/home/HomeTaskManage";
import CompanyChoice from "./components/choice/CompanyChoice";
import UnEventView from "./components/unEvent/UnEventView";
import SignupInvite from "./components/signup/SignupInvite";
import UserChat from "./components/chatbot/UserChat";
import UserChatAdmin from "./components/chatbot/UserChatAdmin";
import ItlCompany from "./components/itl/ItlCompany";

import MailRefuse from "./components/mailRefuse/MailRefuse";

import MemberContext from "./store/Member";
import UnEventContext from "./store/UnEvent";
import CommonContext from "./store/Common";
import MobilePopupContext from "./store/MobilePopup";

import API from "./util/api";
import UnEventAPI from "./API/unevent";
import AdminAPI from "./API/admin";
import MemberAPI from "./API/member";
import CommonAPI from "./API/common";

import { CacheRoute } from "react-router-cache-route";
import { ToastContainer } from "react-toastify";

import RouteIf from "./routes/common/RouteIf";
import UserAuthRoute from "./routes/common/UserAuthRoute";
import ScrollToTop from "./util/ScrollToTop";

import GradeCompany from "./components/grade/GradeCompany";
import AdminTaskPosting from "./components/admin/AdminTaskPosting";
import AdminTaskContentList from "./components/admin/AdminTaskContentList";
import MobilePCServiceAlert from "./components/popup/MobilePCServiceAlert";
import People from "./components/people/People";
import CyberMenuBar from "./components/cyber/CyberMenuBar";
import CyberHeader from "./components/cyber/CyberHeader";
import BusinessDescriptionMain from "./components/businessDescription/BusinessDescriptionMain";
import LawfirmMain from "./components/lawfirm/LawfirmMain";
import FindbizGraph from "./components/graph/FindbizGraph";
import Calculator from "./components/calc/Calculator";
import AlbaMailing from "./components/admin/albal/AlbaMailing";
import AdminMarketSurvey from "./components/admin/manage/AdminMarketSurvey";
import AdminMarketList from "./components/admin/manage/AdminMarketList";
import SideBanner from "./components/common/SideBanner";
import ApprovalRoute from "./routes/ApprovalRoute";
import AdminRoute from "./routes/AdminRoute";
import SignupRoute from "./routes/SignupRoute";
import PatentRoute from "./routes/PatentRoute";
import ManageRoute from "./routes/ManageRoute";
import IntroRoute from "./routes/IntroRoute";
import MarketInfoRoute from "./routes/MarketInfoRoute";
import DesignRoute from "./routes/DesignRoute";
import AnnualRoute from "./routes/AnnualRoute";
import AnnualCountRoute from "./routes/AnnualCountRoute";
import TradeMarkRoute from "./routes/TradeMarkRoute";
import InventionRoute from "./routes/InventionRoute";
import DeadlineRoute from "./routes/DeadlineRoute";
import CostRoute from "./routes/CostRoute";
import ApplicationRoute from "./routes/ApplicationRoute";
import PatentIntroRoute from "./routes/PatentIntroRoute";
import MyPageRoute from "./routes/MyPageRoute";
import DocTradeRoute from "./routes/DocTradeRoute";
import ConsortiumRoute from "./routes/ConsortiumRoute";
import ExpositionRoute from "./routes/ExpositionRoute";
import GovSupportRoute from "./routes/GovSupportRoute";
import MatchingBizRoute from "./routes/MatchingBizRoute";
import ConsultComRoute from "./routes/ConsultComRoute";
import CyberRoute from "./routes/CyberRoute";
import TradeRoute from "./routes/TradeRoute";
import TaskManageRoute from "./routes/TaskManageRoute";
import FreeApplyRoute from "./routes/FreeApplyRoute";
import EducationRoute from "./routes/EducationRoute";
import TutorialRoute from "./routes/TutorialRoute";
import TermsRoute from "./routes/TermsRoute";
import FindRoute from "./routes/FindRoute";
import NotifyRoute from "./routes/NotifyRoute";
import CommunityRoute from "./routes/CommunityRoute";
import CustomSwitch from "./routes/common/CustomSwitch";
import config from "./const/config";
import MobileBottomGNB from "./components/common/MobileBottomGNB";
import MobileRoute from "./routes/MobileRoute";
import links from "./const/links";
import ResearchTechRoute from "./routes/ResearchTechRoute";
import qs from "query-string";
import ChannelTalk from "./components/chatbot/ChannelTalk";
import ChannelTalkPage from "./components/chatbot/ChannelTalkPage";
import RnDPlanFirst from "./components/businessPlan/R&D/planOne/RnDPlanFirst";
import PlanInfo from "./components/businessPlan/PlanInfo";
import BusinessPlanRoute from "./routes/BusinessPlanRoute";
import PaymentHistory from "./components/statistic/PaymentHistory";
import TaskDownload from "./components/dataVoucher/TaskDownload";
import AllDownload from "./components/dataVoucher/AllDownload";
import ChartTest from "./components/chart/ChartTest";
import PatentDownload from "./components/dataVoucher/PatentDownload";
import SideBar from "./components/businessPlan/SideBar";
import IncriselPopupLog from "./components/popup/IncriselPopupLog";
import DummyLogin from "./components/login/DummyLogin";
import AnnualFeeLogin from "./components/login/AnnualFeeLogin";
import RenewalIntro from "./components/intro/findbiz/RenewalIntro";
import TestTaskFina from "./components/test/task/fina";
import TestResearchDownload from "./components/test/research/download";
import TrademarkSearch from "./components/test/company/trademark";
import TestEsgMediaNewsList from "./components/test/esg/news";
import MailingTest from "./components/test/MailingTest";
import MailingRoute from "./routes/MailingRoute";
import MailingIsPaid from "./components/mailing/MailingIsPaid";
import IpStatistic from "./components/statistic/IpStatistic";
import IpStatisticPage from "./components/statistic/IpStatisticPage";
import DDashboard from "./components/dueDiligence/DDashboard";

function App() {
  const token = window.$Global.getToken();
  const auth = token != null;
  const [unEvent, setUnEvent] = useState({});
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(
    useContext(MobilePopupContext)
  );
  const { isMobile } = useContext(CommonContext);
  const [common, setCommon] = useState(useContext(CommonContext));
  const errorMsg = "Error가 발생하였습니다.\n잠시 후 이용해주세요";
  const isIE = /*@cc_on!@*/ !!document.documentMode;
  const isCyberPath = window.location.href.indexOf("/cyber") > -1;
  const queryData = qs.parse(window.location.search);
  const isTestPath =
    window.location.href.indexOf("/test") > -1 ||
    window.location.href.indexOf("/sendMail") > -1;

  if (queryData.token !== undefined) {
    MemberAPI.loginToken({ token: queryData.token }).then((res) => {
      const data = res.data;

      if (data.token) {
        sessionStorage.setItem(config.keyToken, data.token);

        window.location.href = "/";
      }
    });
  }

  useEffect(() => {
    if (isIE) {
      alert(
        "본 서비스는 익스플로러 환경을 지원하지 않습니다\n원활한 사용을 위해 크롬, 엣지로 이용해주세요"
      );
    }

    let timer;
    let count = 0;

    API.interceptors.request.use(
      (config) => {
        if (window.location.pathname === "/dueDi/dashboard") {
          setLoading(false);
        } else {
          setLoading(true);
        }

        count += 1;

        return config;
      },
      (error) => Promise.reject(error)
    );

    API.interceptors.response.use(
      (response) => {
        count -= 1;

        if (count == 0) {
          setLoading(false);
        }

        return response;
      },
      (error) => {
        count -= 1;
        setLoading(false);

        if (error.response) {
          if (!timer) {
            timer = setTimeout(() => {
              timer = null;
              // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
              alert(error.response.data.message || errorMsg);

              let code = error.response.data.status;
              if (code == 401 || code == 603 || code == 604) {
                window.$Global.saveHistory();
                sessionStorage.removeItem("idx");
                sessionStorage.removeItem(config.keyToken);
                window.location.href = "/login";
              }

              if (code == 607 || code == 608) {
                window.location.href = "/mypage/info";
              }

              if (error.response.data.code == "not_exist_company") {
                sessionStorage.removeItem("manage_company_idx");
                window.location.href = "/choice";
              }
              if (code == 600) {
                window.location.href = "/";
              }

              if (code == 601) {
                window.location.href = "/manage/price";
              }
            }, 0);
          }
        } else if (error.request) {
          // 요청이 이루어 졌으나 응답을 받지 못했습니다.
          console.log(error.request);
          return Promise.reject(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          alert("Error " + error.message);
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("idx")) {
      let initData = {};

      for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i);

        if (key == "manage_company_idx") {
          initData[key] = sessionStorage.getItem(key) || null;
        } else if (key == "admin") {
          initData[key] = Boolean(
            sessionStorage.getItem(key) == "true" ? 1 : 0
          );
        } else {
          initData[key] = sessionStorage.getItem(key);
        }
      }
      setMember(initData);
    } else if (token) {
      MemberAPI.getInfo().then(async (res) => {
        let data = res.data;
        data.auth_name = window.$Global.convertAuthCodeToStr(res.data.rank);
        const isAdmin = await AdminAPI.checkAuth().then((res) => res.data);
        const initData = {
          ...data,
          auth: data.rank,
          manage_company_idx:
            sessionStorage.getItem("manage_company_idx") || null,
          admin: isAdmin,
        };

        sessionStorage.setItem("idx", data.idx);
        sessionStorage.setItem("id", data.id);
        sessionStorage.setItem("name", data.name);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("auth", data.rank);
        sessionStorage.setItem("company_name", data.company_name);
        sessionStorage.setItem("company_idx", data.company_idx);
        sessionStorage.setItem("auth_name", data.auth_name);
        sessionStorage.setItem("admin", isAdmin);
        sessionStorage.setItem("ipnow_permission", data.ipnow_permission);
        sessionStorage.setItem("company_type", data.company_type);

        let keys = Object.keys(initData);

        keys.forEach((item) => {
          if (item == "admin") {
            sessionStorage.setItem("admin", isAdmin);
          } else if (initData[item] != null) {
            sessionStorage.setItem(item, initData[item]);
          }
        });
        setMember(initData);
      });
    }
  }, []);

  useEffect(() => {
    if (member.idx) {
      UnEventAPI.getNotification(member.manage_company_idx).then((res) => {
        let obj = {};
        obj.unConfirmEventCnt = res.data.filter(
          (item) => item.state == 0
        ).length;
        obj.setUnEvent = (no) => {
          obj.unConfirmEventCnt = no;
          setUnEvent(obj);
        };
        setUnEvent(obj);
      });
      CommonAPI.getAnnualPayUsableCheck().then(async (res) => {
        const companyIdx = window.sessionStorage.getItem("company_idx");
        const statisticShow = res.data.statistics_service === 1
        const simpleStatisticShow = res.data.simple_statistics_service === 1
        sessionStorage.setItem("statistics_service", statisticShow); // 자산실사 여부
        sessionStorage.setItem(
          "simple_statistics_service",
          simpleStatisticShow
        ); // 심플통계 여부
      });
    }
  }, [member]);

  useEffect(() => {
    handleResize(window.$Global.getBrowserSize().width < 850);

    window.addEventListener("resize", () =>
      handleResize(window.$Global.getBrowserSize().width < 850)
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = (result) => {
    setCommon({ isMobile: result });
  };

  const path = {
    intro: "/intro",
    terms: "/terms",
    find: "/find",
    qna: "/qna",
    notify: "/notify",
    nowLove: "/nowLove",
    signup: "/signup",
    admin: "/admin",
    annual: "/annual",
    annualCount: "/annualCount",
    tradeMark: "/tradeMark",
    invention: "/invention",
    deadline: "/deadline",
    cost: "/cost",
    application: "/application",
    patent: "/patent",
    patentIntro: "/patentIntro",
    myPage: "/myPage",
    grade: "/grade",
    design: "/design",
    tutorial: "/tutorial",
    education: "/education",
    freeApply: "/freeApply",
    trade: "/trade",
    taskManage: "/taskManage",
    manage: "/manage",
    people: "/people",
    community: "/community",
    cyber: "/cyber",
    docTrade: "/docTrade",
    userChat: "/userChat",
    consortium: "/consortium",
    matchingBiz: "/matchingBiz",
    govSupport: "/govSupport",
    exposition: "/exposition",
    businessDescription: "/businessDescription",
    taskDetail: "/taskDetail",
    lawFirm: "/lawfirm",
    graph: "/graph",
    consultCom: "/consultCom",
    calc: "/calc",
    marketInfo: "/marketInfo",
    researchTech: "/researchTech",
    approval: "/approval",
    mobile: "/mobile",
    mailing: "/sendMail",
    mailRefuse: "/mailRefuse",
    plan: "/plan",
    dueDi: "/dueDi",
  };

  if (window.location.href.indexOf("/login") == -1) {
    sessionStorage.removeItem("historyURL");
  }

  if (auth && window.$Global.isEmptyObject(member)) return null;

  return (
    <MobilePopupContext.Provider value={{ mobilePopup, setMobilePopup }}>
      <CommonContext.Provider value={common}>
        <UnEventContext.Provider value={unEvent}>
          <MemberContext.Provider value={member}>
            <Loading show={loading} />
            <ToastContainer />
            <BrowserRouter forceRefresh={false}>
              <ScrollToTop />
              <div
                id="App"
                className="App"
                style={{ pointerEvents: loading ? "none" : "" }}
              >
                <CustomSwitch>
                  <Route
                    path="/lti_admin/company"
                    component={ItlCompany}
                    exact
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/posting/task`}
                    auth={true}
                    component={AdminTaskPosting}
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMailing`}
                    auth={true}
                    component={AlbaMailing}
                  />

                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMarket/list`}
                    auth={true}
                    component={AdminMarketList}
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMarket/posting`}
                    auth={true}
                    component={AdminMarketSurvey}
                  />

                  <CacheRoute
                    exact
                    path={`${path.admin}/posting/task/list`}
                    component={AdminTaskContentList}
                    when="always"
                  />

                  <Route
                    exact
                    path="/statistic/paymentHistory"
                    component={PaymentHistory}
                  />

                  <Route exact path="/chart/test" component={ChartTest} />

                  <Route
                    exact
                    path="/test/task/fina"
                    component={TestTaskFina}
                  />

                  <Route
                    exact
                    path="/test/research/download"
                    component={TestResearchDownload}
                  />

                  <Route
                    exact
                    path="/test/company/trademark"
                    component={TrademarkSearch}
                  />

                  <Route
                    exact
                    path="/test/esg/news"
                    component={TestEsgMediaNewsList}
                  />

                  <Route
                    exact
                    path="/dataVoucher/task"
                    component={TaskDownload}
                  />
                  <Route
                    exact
                    path="/dataVoucher/download"
                    component={AllDownload}
                  />
                  <Route
                    exact
                    path="/dataVoucher/patent"
                    component={PatentDownload}
                  />
                  <Route
                    exact
                    path="/visitCount/incrisel"
                    component={IncriselPopupLog}
                  />
                  <React.Fragment>
                    {!isCyberPath && (
                      <>
                        {!isTestPath && <Route path="*" component={Header} />}
                        {!isTestPath && <Route path="*" component={MenuTab} />}
                        <Route
                          exact
                          path={["/intro/main", "/"]}
                          component={SideBanner}
                        />
                        <Route exact path="/plan/*" component={PlanInfo} />
                      </>
                    )}
                    <Route path={`${path.cyber}`} component={CyberHeader} />

                    <div id="container" className="container">
                      {/*<Route exact path={`${path.intro}/*`} component={DemoView}/>*/}
                      <CustomSwitch>
                        <RouteIf
                          exact
                          path="/"
                          condition={auth}
                          redirectPath="/intro/main"
                          component={Home}
                        />
                        <RouteIf
                          exact
                          path="/"
                          condition={auth}
                          redirectPath="/intro/main"
                          component={HomeTaskManage}
                        />
                        <RouteIf
                          exact
                          path="/login"
                          condition={!auth}
                          redirectPath="/"
                          component={Login}
                        />
                        <RouteIf
                          exact
                          path="/dummylogin"
                          condition={!auth}
                          redirectPath="/"
                          component={DummyLogin}
                        />
                        <RouteIf
                          exact
                          path="/annual/login"
                          condition={!auth}
                          redirectPath="/"
                          component={AnnualFeeLogin}
                        />
                        <UserAuthRoute
                          exact
                          path="/choice"
                          auth={auth}
                          component={CompanyChoice}
                        />
                        <UserAuthRoute
                          exact
                          path="/unEvent"
                          auth={auth}
                          component={UnEventView}
                        />
                        <UserAuthRoute
                          exact
                          path="/renewal/intro"
                          auth={auth}
                          component={RenewalIntro}
                        />
                        <Route exact path="/invite" component={SignupInvite} />
                        <Route exact path="/people" component={People} />
                        <Route exact path={path.calc} component={Calculator} />
                        <Route
                          exact
                          path={`${path.grade}/company`}
                          component={GradeCompany}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.graph}/biznavi`}
                          requireAuth={false}
                          component={FindbizGraph}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.userChat}/admin`}
                          requireAuth={true}
                          component={UserChatAdmin}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.businessDescription}/list`}
                          requireAuth={false}
                          component={BusinessDescriptionMain}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.lawFirm}/list`}
                          requireAuth={false}
                          component={LawfirmMain}
                        />

                        <Route path={path.cyber} component={CyberRoute} />
                        <Route path={path.trade} component={TradeRoute} />
                        <Route
                          path={path.taskManage}
                          component={TaskManageRoute}
                        />
                        <Route
                          path={path.freeApply}
                          component={FreeApplyRoute}
                        />
                        <Route
                          path={path.education}
                          component={EducationRoute}
                        />
                        <Route path={path.tutorial} component={TutorialRoute} />
                        <Route path={path.manage} component={ManageRoute} />
                        <Route path={path.intro} component={IntroRoute} />
                        <Route path={path.terms} component={TermsRoute} />
                        <Route path={path.find} component={FindRoute} />
                        <Route path={path.notify} component={NotifyRoute} />
                        <Route
                          path={path.community}
                          component={CommunityRoute}
                        />
                        <Route path={path.signup} component={SignupRoute} />
                        <Route path={path.admin} component={AdminRoute} />
                        <Route path={path.patent} component={PatentRoute} />
                        <Route path={path.design} component={DesignRoute} />
                        <Route path={path.annual} component={AnnualRoute} />
                        <Route
                          path={path.annualCount}
                          component={AnnualCountRoute}
                        />
                        <Route
                          path={path.tradeMark}
                          component={TradeMarkRoute}
                        />
                        <Route
                          path={path.invention}
                          component={InventionRoute}
                        />
                        <Route path={path.deadline} component={DeadlineRoute} />
                        <Route path={path.cost} component={CostRoute} />
                        <Route path={path.approval} component={ApprovalRoute} />
                        <Route
                          path={path.application}
                          component={ApplicationRoute}
                        />
                        <Route
                          path={path.patentIntro}
                          component={PatentIntroRoute}
                        />
                        <Route path={path.myPage} component={MyPageRoute} />
                        <Route path={path.docTrade} component={DocTradeRoute} />
                        <Route
                          path={path.consortium}
                          component={ConsortiumRoute}
                        />
                        <Route
                          path={path.exposition}
                          component={ExpositionRoute}
                        />
                        <Route
                          path={path.govSupport}
                          component={GovSupportRoute}
                        />
                        <Route
                          path={path.matchingBiz}
                          component={MatchingBizRoute}
                        />
                        <Route
                          path={path.consultCom}
                          component={ConsultComRoute}
                        />
                        <Route
                          path={path.marketInfo}
                          component={MarketInfoRoute}
                        />
                        <Route
                          path={path.researchTech}
                          component={ResearchTechRoute}
                        />
                        <Route
                          path={links.mobile.path}
                          component={MobileRoute}
                        />
                        <Route path={path.mailing} component={MailingRoute} />
                        <Route path={path.plan} component={BusinessPlanRoute} />
                        <RouteIf
                          exact
                          path={path.mailRefuse}
                          condition={!auth}
                          redirectPath="/"
                          component={MailRefuse}
                        />
                        <UserAuthRoute
                          exact
                          path={`/kautum/statistic`}
                          requireAuth={true}
                          component={IpStatistic}
                        />
                        <UserAuthRoute
                          exact
                          path={`/statistic/ip`}
                          requireAuth={true}
                          component={IpStatisticPage}
                        />
                        <UserAuthRoute
                          path={path.dueDi}
                          requireAuth={true}
                          component={DDashboard}
                        />
                      </CustomSwitch>
                    </div>
                    {!isCyberPath && (
                      <>
                        <Route path="*" component={MobileBottomGNB} />
                        {!isTestPath && <Route path="*" component={Footer} />}
                        <Route path="*" component={ChannelTalk} />
                        <Route path="*" component={MobilePCServiceAlert} />
                      </>
                    )}
                    <Route path={`${path.cyber}`} component={CyberMenuBar} />
                  </React.Fragment>
                </CustomSwitch>
              </div>
            </BrowserRouter>
          </MemberContext.Provider>
        </UnEventContext.Provider>
      </CommonContext.Provider>
    </MobilePopupContext.Provider>
  );
}

export default App;
